import React, { useState, useEffect, useContext } from 'react'
import '../../styles/Admin/lotperformance.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp, faCircleDot, faGear, faGlobe, faRefresh, faTags } from '@fortawesome/free-solid-svg-icons'
// import { ShareContext } from '../../context/ShareContext'
import * as Constants from '../../constants';
import { ShareContext } from '../../context/ShareContext';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading'
import Expired from '../../components/Expired'
import PerfWidget from '../../components/Admin/perfWidget'
import { LineChart, PieChart } from '@mui/x-charts'

function LotPerformance() {
    const {  setIsAdminLoggedIn, setAdminData, setAdminToken, adminToken, isSidebarClosed} = useContext(ShareContext);
    const [openSet, setOpenSet] = useState(false)
    const [period, setperiod] = useState('Monthly') ;
    const [selectedmonth, setselectedmonth ] = useState( 0);
    const [selectedyear,setselectedyear ] = useState( 2016);
    const [morrischat,setmorrischat ] = useState( []);
    const [totaltickets, settotaltickets] = useState({});
    const [Scomplaint,setScomplaint ] = useState( { tcount: 1, tsum: 0, tack: 0, tuseful: 0, tcountVStsum: 0, tcountVStack: 0 });
    const [Ssuggestion, setSsuggestion ] = useState( { tcount: 0, tsum: 0, tack: 0, tuseful: 0, tcountVStuseful: 0 });
    const [Scommendation,setScommendation] = useState({ tcount: 0, tsum: 0, tack: 0, tuseful: 0, tcountVStuseful: 0 });
    const [Senquiry,setSenquiry ] = useState( { tcount: 0, tsum: 0, tack: 0, tuseful: 0, tcountVStuseful: 0 });
    const [today,settoday ] = useState( {});
    const [paidper,setpaidper ] = useState( 0);
    const [refundper,setrefundper ] = useState( 0);
    const [dueper,setdueper ] = useState( 0);
    const [totalcsper,settotalcsper ] = useState( 0);
    const [mdaperformance, setmdaperformance ] = useState( []);
    const [metric,setmetric] = useState( {year:0,airportcode:'all', parkinglotid:'all', month:0});
    const [mdas,setmdas ] = useState( []);
    const [activemda, setactivemda ] = useState( 0);
    const [isloading,setisloading ] = useState( false);
    const [values,setvalues ] = useState( {});
    const [params,setparams ] = useState( { airportcode:"All Airport"});
    const [params_d, setparams_d] = useState( {});
    const [airports, setairports]=useState([]);
    const [months,setmonths ] = useState( [{ month: '1', name: 'January' }, { month: '2', name: 'February' }, { month: '3', name: 'March' },
        { month: '4', name: 'April' }, { month: '5', name: 'May' }, { month: '6', name: 'June' }, { month: '7', name: 'July' },
        { month: '8', name: 'August' }, { month: '9', name: 'September' }, { month: '10', name: 'October' }, { month: '11', name: 'November' },
        { month: '12', name: 'December' }]);
     const [totalamount,settotalamount ] = useState( { mainValue: 0, prevValue: 0, lastyear:0});
     const [payatlot, setpayatlot ] = useState( {}); 
     const [totalpaid,settotalpaid ] = useState({});
     const [refund,setrefund ] = useState({});
     const [cancelled,setcancelled ] = useState( {});
   
     const [count,setcount ] = useState( {});
     const [totalamount_,settotalamount_] = useState( {});
     const [payatlot_,setpayatlot_ ] = useState( {});
     const [totalpaid_,settotalpaid_ ] = useState( {});
     const [refund_,setrefund_ ] = useState( {});
     const [cancelled_,setcancelled_ ] = useState( {});
     const [count_ ,setcount_] = useState( {});
     const [itotalcsper,setitotalcsper]=useState(0);
    const [iopenper,setiopenper]=useState(0);
    const [icloseper,seticloseper]=useState(0);
    const [icancelper,seticancelper]=useState(0);

    const [cancelper,setcancelper] =useState(0);
    const [enquiryper,setenquiryper] =useState(0);
    const [isExpired, setIsExpired] = useState(false);

    const [parkinglots, setparkinglots]= useState([]);
    const [parkingfilter,setparkingsfilter]=useState([]);
    const [pieAmount, setPieAmount]=useState([]);
    const [pieStatus, setPieStatus]=useState([]);
    const [mchat, setMChat]=useState([]);
    const [statuschat, setstatuschat]=useState([]);
    const [morisdays, setmorisdays] = useState([])

    const [xFdata, setxfdata]=useState([]);
    const [xFdata_s, setxfdata_s]=useState([]); //to hold for status
    const [xFdata_d, setxfdata_d]=useState([]);

     useEffect(() => {
        init();
      }, []);
   
     const monthname =  (id)=> {
        var cmda = months.filter(function (item, index) { return item.month == id; });
        return cmda[0] == null ? "No Month" : cmda[0].name;
    };
    const arrowdirect = (v1, v2,type) => {
        if (v1 > v2)
            if(type=='arrow')
                return faAngleUp;
            else
                return 'success';
        else
            if (type == 'arrow')
                return faAngleDown;
            else 
                return 'success';
    };
    const getperc =  (v1, v2) => {
        if (v2 == 0)
            return 0;
        else
            return ((v1 / v2) * 100).toFixed(0);
    };
    const init =  () => {
      

            setisloading(true);
            fetch(`${Constants.apiUrl}/parkingadmin/analytics/init`, {
              method: 'GET',
              headers: {
                'Content-type': 'application/json',
                'Authorization': adminToken,
              },
            })
              .then((resp) => resp.json())
              .then((data) => {
                
                console.log(data);
                if(data.code==0){
                     var request={airportcode: 'all', month : data.currentmonth, year:data.currentyear, parkinglotid:'all'}
                  //  setmetric ({airportcode: 'All Airport', month : data.currentmonth, year:data.currentyear, parkinglotid:'all'});
                  setmetric (request);
                    setairports (data.airports);
                    setparkinglots(data.parkinglots)
                    setparkingsfilter(data.parkinglots);
                //  $scope.metric.month = data.currentmonth;
               
                    loadtopstatistics(request);
                }
                else if(data.code==-10){
                    //logOut(); //possible the token is not available via context
                    setIsExpired(true);
                }
                    setisloading(false);
              })
              .catch((error) => {
                console.log(error);
                
              });
    }
    const loadtopstatistics =  (request) => {
       
        //reset values
       // settotalamount () ;
        setpayatlot ({ mainValue: 0, prevValue: 0, lastyear: 0 });
        settotalpaid ({ mainValue: 0, prevValue: 0, lastyear: 0 }) ;
        setrefund ({ mainValue: 0, prevValue: 0, lastyear: 0 }) ;
        setcancelled ({ mainValue: 0, prevValue: 0, lastyear: 0 }) ;
        setcount ({ mainValue: 0, prevValue: 0, lastyear: 0 }) ;
   
        setpayatlot_ ({ mainValue: 0, prevValue: 0, lastyear: 0 }) ;
        settotalpaid_ ({ mainValue: 0, prevValue: 0, lastyear: 0 }) ;
        setrefund_ ({ mainValue: 0, prevValue: 0, lastyear: 0 }) ;
        setcancelled_ ({ mainValue: 0, prevValue: 0, lastyear: 0 }) ;
        setcount_ ({ mainValue: 0, prevValue: 0, lastyear: 0 }) ;

        
        console.log(JSON.stringify(request));
        setisloading(true);
        fetch(`${Constants.apiUrl}/parkingadmin/analytics/statis/lot`, {
          method: 'POST',
            body: JSON.stringify(request),
          headers: {
            'Content-type': 'application/json',
            'Authorization': adminToken,
          },
        })
          .then((resp) => resp.json())
          .then((data) => {
            setisloading  (false);
          //  console.log('statistic datas ********************************');
            //console.log(data);
            if(data.code==0){
                
                console.log('stat write all the values');
                setvalues (data.values);
                setparams(data.pmonth);
                console.log('param value specifically: ');
                console.log(data.pmonth);
                setMChat(data.mchat);
                

                settotalamount (data.values.totalamount[0]) ;
                setpayatlot (data.values.payatlot[0]);
                settotalpaid (data.values.totalpaid[0]) ;
                setrefund (data.values.refund[0]) ;
                setcancelled (data.values.cancelled[0]) ;
                setcount (data.values.count[0]) ;
                //day*****************
                setparams_d({month : data.pday.month_,year : data.pday.year_,lastyear : data.pday.lastyear_ ,
                    prevmonth : data.pday.prevmonth_ ,prevday : data.pday.prevday_ ,day :data.pday.day_,prevyear : data.pmonth.prevyear  })
               

                settotalamount_  (data.values_d.totalamount_d[0]);
                setpayatlot_  (data.values_d.payatlot_d[0]);
                settotalpaid_  (data.values_d.totalpaid_d[0]);
                setrefund_  (data.values_d.refund_d[0]);
                setcancelled_  (data.values_d.cancelled_d[0]);
                setcount_  (data.values_d.count_d[0]);
                //end day*************
                setmorrischat  (data.mchat);
                setstatuschat(data.statuschat);
                setmorisdays(data.morrisdays);
                
   
               // $window.handleVisitorsLineChart(data.mchat);
               
               // $window.handleStatusLineChart(data.statuschat);
                
               // $window.handleDayLineChart(data.morrisdays);
                var sumy = 0;
                var sumz = 0;
                var sumc = 0;
                var sumd = 0;

                var dh=[];
                for (var i = 0; i < data.mchat.length; i++) {
                    sumy += data.mchat[i].y;
                    sumz += data.mchat[i].z;
                    sumc += data.mchat[i].c;
                    sumd += data.mchat[i].d;
                    dh.push(i);
                }
                setxfdata(dh); //this is only for mui plot
                settotalcsper  (sumy + sumz + sumc + sumd);
                var ddata = [{id:0, label: "Total Paid", value: sumy }, {id:1, label: "Payatlot", value: sumz }, {id:2, label: "Cancelled", value: sumc }, {id:3, label: "Refund", value: sumd }];
                console.log("ddata"); console.log(ddata)
                setPieAmount(ddata);

                setpaidper  ((sumy / (sumy + sumz + sumc + sumd)) * 100);
                setdueper  ((sumz / (sumy + sumz + sumc + sumd)) * 100);
                setcancelper  ((sumc / (sumy + sumz + sumc + sumd)) * 100);
                setrefundper  ((sumd / (sumy + sumz + sumc + sumd)) * 100);
                //$window.handleVisitorsDonutChart(ddata);
                
                
             
                //for status donot
                var isumy = 0;
                var isumz = 0;
                var isumc = 0;
               
                var dhs=[];
                for (var i = 0; i < data.statuschat.length; i++) {
                    isumy += data.statuschat[i].y;
                    isumz += data.statuschat[i].z;
                    isumc += data.statuschat[i].c;
                   dhs.push(i);
                }
                setxfdata_s(dhs);
                var _itotalcsper =isumy + isumz + isumc
                setitotalcsper  (_itotalcsper) ;
                //console.log('total.......' + itotalcsper);
                var ddata2 = [{id:0, label: "Open", value: isumy }, {id:1, label: "Closed", value: isumz }, {id:2, label: "Cancelled", value: isumc }];
                console.log("ddata2"); console.log(ddata2)
                setPieStatus(ddata2);
                setiopenper  ((isumy / _itotalcsper) * 100);
                seticloseper ((isumz / _itotalcsper) * 100);
                seticancelper ((isumc / _itotalcsper) * 100);

                
                var dhd=[];
                for (var i = 0; i < data.morrisdays.length; i++) {

                   dhd.push(i);
                }
                setxfdata_d(dhd);
                

              //  $window.handleStatusDonutChart(ddata2);
              //  $window.handleDailyDonutChart(ddata2);
              setOpenSet(false);
            }
            else if(data.code==-10){
                //logOut(); //possible the token is not available via context
            }
                setisloading(false);
          })
          .catch((error) => {
            console.log(error);
            setisloading(false);
          });

        //end of value reset
      
   
   
    }
    const onLoadPerformance=(e)=>{
        e.preventDefault();
        var request={...metric};
        loadtopstatistics(request);
    }
   
   
   
    const getcolor = (va) => {
        if (va > 50) {
            return "badge-success";
        }
        else if (va > 40) {
            return "badge-primary";
        }
        else if (va > 30) {
            return "badge-inverse";
        }
        else if (va > 20) {
            return "badge-warning";
        }
        else {
            return "badge-danger";
        }
    }
    // const { isAdminLoggedIn} = useContext(ShareContext);

    //test part
  const  mchat_= [
        {
            "x": "Atlanta (ATL)",
            "y": 26.2500,
            "z": 0.0000,
            "c": 0.0000,
            "d": 5.2500
        },
        {
            "x": "Boston Logan (BOS)",
            "y": 112.7230,
            "z": 0.0000,
            "c": 0.0000,
            "d": 0.0000
        }
    ];
      
      const keyToLabel = {
        y: 'Total Paid',
        z: 'Pay at lot',
        c: 'Cancel',
        d: 'Refund',
       
      };
      
      const colors = {
        y: 'lightgray',
        z: 'lightgreen',
        c: 'yellow',
        d: 'lightblue',
        
      };
      
      const stackStrategy = {
        stack: 'total',
        area: true,
        stackOffset: 'none', // To stack 0 on top of others
      };
      
      const customize = {
        height: 300,
        legend: { hidden: true },
        margin: { top: 5 },
        stackingOrder: 'descending',
      };

      const xLabel=(n)=>{

        if(n % 1 === 0){
            var v=parseInt(n);
            return morrischat[v]?.x;
        }else{
            return '-';
        }
      }
      const xLabel_s=(n)=>{

        if(n % 1 === 0){
            var v=parseInt(n);
            return statuschat[v]?.x;
        }else{
            return '-';
        }
      }
      const xLabel_d=(n)=>{

        if(n % 1 === 0){
            var v=parseInt(n);
            return morisdays[v]?.x;
        }else{
            return '-';
        }
      }
      
    //end chat part
  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
          { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}
            {isloading && <div className={isloading ? 'congrats show' : 'congrats'}>
                    <Loading message={'Loading Lot Performance'} />    
                </div>}
            <div className="lot-performance">
                <div className="head">
                    <h1>Performance</h1>
                    <p>Analytical details on {metric.airportcode}. [Click the Icon <FontAwesomeIcon icon={faGear} />  on the right to change parkinglot]</p>
                </div>
                <div className="body-wrapper">
                    <div className="rows">
                        <div className="months-row">
                            <PerfWidget 
                             prevYear={params.lastyear} prevMonth={monthname(params.prevmonth)} 
                             year={params.year} month={monthname(params.month)} 
                             amount={totalamount.mainValue} 
                             prevAmount={totalamount.prevValue} 
                             preYearAmount={totalamount.prevYearValue}
                             title="Total Amount"
                             />
                            <PerfWidget 
                             prevYear={params.lastyear} prevMonth={monthname(params.prevmonth)} 
                             year={params.year} month={monthname(params.month)} 
                             amount={totalpaid.mainValue} 
                             prevAmount={totalpaid.prevValue} 
                             preYearAmount={totalpaid.prevYearValue}
                             title="Total Paid"
                             />
                           <PerfWidget 
                             prevYear={params.lastyear} prevMonth={monthname(params.prevmonth)} 
                             year={params.year} month={monthname(params.month)} 
                             amount={payatlot.mainValue} 
                             prevAmount={payatlot.prevValue} 
                             preYearAmount={payatlot.prevYearValue}
                             title="Due at Lot"
                             />

                            <PerfWidget 
                             prevYear={params.lastyear} prevMonth={monthname(params.prevmonth)} 
                             year={params.year} month={monthname(params.month)} 
                             amount={refund.mainValue} 
                             prevAmount={refund.prevValue} 
                             preYearAmount={refund.prevYearValue}
                             title="Refund"
                             />
                              <PerfWidget 
                             prevYear={params.lastyear} prevMonth={monthname(params.prevmonth)} 
                             year={params.year} month={monthname(params.month)} 
                             amount={cancelled.mainValue} 
                             prevAmount={cancelled.prevValue} 
                             preYearAmount={cancelled.prevYearValue}
                             title="Cancellation"
                             />
                              <div className="item">
                                <div className="up-side">
                                    <p>{monthname(params.month)} {params.year}   Total Reservations </p>
                                    <div className="amount-percent">
                                        <h2>{count.mainValue}</h2>
                                        <div className="percent-icon">
                                            <FontAwesomeIcon icon={faGlobe} className='float-icon'/>
                                            <div className="percent">
                                                <h3>  {getperc(count.mainValue,count.prevValue) }%</h3>
                                                <FontAwesomeIcon icon={arrowdirect(count.mainValue,count.prevValue,'arrow')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="progress-bar" style={{width:`${getperc(count.mainValue,count.prevValue) }%`}}></div>
                                </div>
                                
                                <div className="down-place">
                                    <div className="versus">
                                        <p className='vs'>vs</p>
                                        <p className="month"> {monthname(params.prevmonth)} {params.lastyear}: {count.prevValue}</p>
                                    </div>
                                <div className="month-icon">
                                        <p> {monthname(params.month)} {params.lastyear}: {count.prevYearValue}</p>
                                        <FontAwesomeIcon icon={arrowdirect(count.prevValue, count.mainValue,'arrow')} />
                                    </div>
                                </div>
                              </div>

                           
                          
                        </div>
                        <div className="down-row">
                        {/* <PerfWidget 
                             prevYear={params_d.prevyear} prevMonth={monthname(params_d.prevmonth)} 
                             year={params_d.year} month={monthname(params_d.month)} day={params_d.day} prevDay={params_d.prevday}
                             amount={totalamount_.mainValue} 
                             prevAmount={totalamount_.prevValue} 
                             preYearAmount={totalamount_.prevYearValue}
                             title="Today Amount" mode="day"
                             /> */}
                            <PerfWidget 
                              prevYear={params_d.lastyear} prevMonth={monthname(params_d.prevmonth)} 
                              year={params_d.year} month={monthname(params_d.month)} day={params_d.day} prevDay={params_d.prevday}
                             amount={totalpaid_.mainValue} 
                             prevAmount={totalpaid_.prevValue} 
                             preYearAmount={totalpaid_.prevYearValue}
                             title="Today Paid" mode="day"
                             />
                           <PerfWidget 
                              prevYear={params_d.lastyear} prevMonth={monthname(params_d.prevmonth)} 
                              year={params_d.year} month={monthname(params_d.month)} day={params_d.day} prevDay={params_d.prevday}
                             amount={payatlot_.mainValue} 
                             prevAmount={payatlot_.prevValue} 
                             preYearAmount={payatlot_.prevYearValue}
                             title="Today Due at Lot" mode="day"
                             />

                            <PerfWidget 
                              prevYear={params_d.lastyear} prevMonth={monthname(params_d.prevmonth)} 
                              year={params_d.year} month={monthname(params_d.month)} day={params_d.day} prevDay={params_d.prevday}
                             amount={refund_.mainValue} 
                             prevAmount={refund_.prevValue} 
                             preYearAmount={refund_.prevYearValue}
                             title="Today Refund" mode="day"
                             />
                              <PerfWidget 
                              prevYear={params_d.lastyear} prevMonth={monthname(params_d.prevmonth)} 
                              year={params_d.year} month={monthname(params_d.month)} day={params_d.day} prevDay={params_d.prevday}
                             amount={cancelled_.mainValue} 
                             prevAmount={cancelled_.prevValue} 
                             preYearAmount={cancelled_.prevYearValue}
                             title="Today Cancellation" mode="day"
                             />

                           
                        </div>
                    </div>
                    <div className="parking-analytics">
                        <div className="dark-side">
                            <div className="left">
                                <div className="head">
                                    <h3>Analytics by {metric.parkinglotid} parkinglot</h3>
                                    <p>Origin of Reservation</p>
                                </div>
                                <div className="space">
                             
                           {/*  {morrischat.length>0 &&  <LineChart
                            xAxis={[
                                {
                                dataKey: 'x',
                                },
                            ]}
                            series={Object.keys(keyToLabel).map((key) => ({
                                dataKey: key,
                                label: keyToLabel[key],
                                color: colors[key],
                                showMark: false,
                                ...stackStrategy,
                            }))}
                            dataset={mchat_}
                            {...customize}
                            />
                            }    */}

                            {morrischat.length>0 &&  <LineChart
                               // xAxis={[{ data: [1, 2, 3, 5, 8,5] }]}
                               xAxis={[
                                {
                                  
                                  data: xFdata,
                                 
                                  valueFormatter: (e) => xLabel(e) , 
                                },
                              ]}
                                series={[
                                    {  data: morrischat.map(a => a.y),label:'Total Paid' },
                                    {  data: morrischat.map(a => a.z),label:'Pay at Lot' },
                                    {  data: morrischat.map(a => a.c),label:'Cancelled' },
                                    {  data: morrischat.map(a => a.d),label:'Refund' }
                                ]}
                                width={800}
                                height={350}
                                slotProps={{ legend: { hidden: false, labelStyle: {
                                    fontSize: 13,
                                    fill: 'white',
                                  }, } }}
                                />
                            }   

                                </div>
                            </div>
                            <div className="right">

                                <div className="head">
                                    <h3>{Constants.formatCurrency(totalamount.mainValue)}</h3>
                                    <p>Total Amount</p>
                                </div>
                                <div className="body" style={{color:'white'}}>
                                <PieChart
                                series={[
                                    {
                                    data:pieAmount,
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    
                                    },
                                ]}
                                width={350}
                                height={200}
                                slotProps={{ legend: { hidden: false, labelStyle: {
                                    fontSize: 13,
                                    fill: 'white',
                                  }, } }}

                                />
                                </div>
                                <div className="down">
                                    <ul>
                                        <li><FontAwesomeIcon icon={faCircleDot} /> {paidper.toFixed(2)} % <span>Paid Online</span></li>
                                        <li><FontAwesomeIcon icon={faCircleDot} /> {dueper.toFixed(2)}  % <span>Due At Lot</span></li>
                                        <li><FontAwesomeIcon icon={faCircleDot} /> {refundper.toFixed(2)}  % <span>Refund</span></li>
                                        <li><FontAwesomeIcon icon={faCircleDot} />{cancelper.toFixed(2)}  % <span>Cancellation</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="reserve-origin">
                            <div className="heading">
                                <h3>Reservation Origin</h3>
                            </div>
                            <div className="empty">
                            {
                                morrischat.slice(0, 8).map((n, idx) => {
                                    return (
                                        <div key={idx} className='list-group-item'><span style={{flex:1}}>{idx+1}. {n.x}</span> <span class="badge">{(((n.y+n.z+n.c+n.d)/totalcsper)*100).toFixed(2)}</span> </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    </div>
                    <div className="dark-side">
                        <div className="left">
                            <div className="head">
                                <h3>Analytics by All Parking Lots</h3>
                                <p>Reservation by day</p>
                            </div>
                            <div className="space">
                            {statuschat.length>0 &&  <LineChart
                               // xAxis={[{ data: [1, 2, 3, 5, 8,5] }]}
                               xAxis={[
                                {
                                  
                                  data: xFdata_s,
                                 
                                  valueFormatter: (e) => xLabel_s(e) , 
                                },
                              ]}
                                series={[
                                    {  data: statuschat.map(a => a.y),label:'Open' },
                                    {  data: statuschat.map(a => a.z),label:'Closed' },
                                    {  data: statuschat.map(a => a.c),label:'Cancelled' },
                                   
                                ]}
                                width={800}
                                height={350}
                                slotProps={{ legend: { hidden: false, labelStyle: {
                                    fontSize: 13,
                                    fill: 'white',
                                  }, } }}
                                />
                            }   
                            </div>
                        </div>
                        <div className="right">
                            <div className="head">
                                <h3>{Constants.formatCurrency(totalamount.mainValue)}</h3>
                                <p>Total Amount</p>
                            </div>
                            <div className="body">
                            <PieChart
                                series={[
                                    {
                                    data:pieStatus,
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    
                                    },
                                ]}
                                width={350}
                                height={200}
                                slotProps={{ legend: { hidden: false, labelStyle: {
                                    fontSize: 13,
                                    fill: 'white',
                                  }, } }}

                                />
                            </div>
                            <div className="down">
                                <ul>
                                    <li><FontAwesomeIcon icon={faCircleDot} /> {iopenper.toFixed(2)} % <span>Opened</span></li>
                                    <li><FontAwesomeIcon icon={faCircleDot} /> {icloseper.toFixed(2)} % <span>Closed</span></li>
                                    <li><FontAwesomeIcon icon={faCircleDot} /> {icancelper.toFixed(2)} % <span>Canceled</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="dark-side">
                        <div className="left">
                            <div className="head">
                                <h3>Analytics by All Parking Lots</h3>
                                <p>Origin of Reservation</p>
                            </div>
                            <div className="space">
                            {morisdays?.length>0 &&  <LineChart
                               // xAxis={[{ data: [1, 2, 3, 5, 8,5] }]}
                               xAxis={[
                                {
                                  
                                  data: xFdata_d,
                                 
                                  valueFormatter: (e) => xLabel_d(e) , 
                                },
                              ]}
                                series={[
                                    {  data: morisdays.map(a => a.y),label:'Open' },
                                    {  data: morisdays.map(a => a.z),label:'Closed' },
                                    {  data: morisdays.map(a => a.c),label:'Cancelled' },
                                   
                                ]}
                                width={800}
                                height={350}
                                slotProps={{ legend: { hidden: false, labelStyle: {
                                    fontSize: 13,
                                    fill: 'white',
                                  }, } }}
                                />
                            }   
                            </div>
                        </div>
                        <div className="right">
                            <div className="head">
                                <h3>{Constants.formatCurrency(totalamount.mainValue)}</h3>
                                <p>Total Amount</p>
                            </div>
                            <div className="body">
                            <PieChart
                                series={[
                                    {
                                    data:pieStatus,
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    
                                    },
                                ]}
                                width={350}
                                height={200}
                                slotProps={{ legend: { hidden: false, labelStyle: {
                                    fontSize: 13,
                                    fill: 'white',
                                  }, } }}

                                />
                            </div>
                            <div className="down">
                                <ul>
                                    <li><FontAwesomeIcon icon={faCircleDot} />  % <span>Opened</span></li>
                                    <li><FontAwesomeIcon icon={faCircleDot} />  % <span>Closed</span></li>
                                    <li><FontAwesomeIcon icon={faCircleDot} />  % <span>Canceled</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={ openSet ? "change-lot open" : "change-lot"}>
                    <div className="set-icon" onClick={() => setOpenSet(!openSet)}>
                        <FontAwesomeIcon icon={faGear} className='settings' />
                    </div>
                    <form method='post' onSubmit={onLoadPerformance}>
                        <div className="header">
                            <h3>Change Parking Lot and Month</h3>
                        </div>
                       {/*  <div className="input">
                            <label htmlFor="airport"> Select Airport</label>
                            <select name="airport" value={metric.airportcode}  onChange={(e)=> setmetric({...metric, airportcode:e.target.value})}>
                            <option value="all">All Airport</option>
                            {
                        airports.map((airport, idx) => {
                           
                            return (
                                <option key={idx} value={airport.value}>{airport.text}</option>
                            )
                        })
                    }
                            </select>
                        </div> */}
                        <div className="input">
                            <label htmlFor="parkinglotid"> Select Parking Lot</label>
                            <select name="parkinglotid" value={metric.parkinglotid} onChange={(e)=> setmetric({...metric, parkinglotid:e.target.value})}>
                            <option value="all">All Parking Lot</option>
                            {
                                parkingfilter.map((park, idx) => {
                                    
                                    return (
                                        <option key={idx} value={park.value}>{park.text}</option>
                                    )
                                })
                            }
                            </select>
                        </div>
                       
                        <div style={{flexDirection:'row'}}>
                            <div className="input">
                                <label htmlFor="selectLotMonth">Select Month</label>
                                <select name="selectLotMonth" id="selectLotMonth" value={metric.month} onChange={(e)=> setmetric({...metric, month:e.target.value})} >
                                    <option value="" disabled></option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                            </div>
                            <div className="input">
                                <label htmlFor="selectLotMonth">Input Year</label>
                                <input type="text" value={metric.year}  onChange={(e)=> setmetric({...metric, year:e.target.value})} />
                            </div>
                        </div>
                        
                        <button type='submit'><FontAwesomeIcon icon={faRefresh} />Reload Dashboard Metrics</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LotPerformance
import React, {useEffect,  useState, useContext} from 'react'
import '../../styles/User/directions.css'
import { airportCodes } from '../../data/airportCodes'
import TopBar from '../../components/User/TopBar'
import * as Constants from '../../constants';
import ReactGA from 'react-ga';

function Directions() {

    const [lots, setLots]=useState([]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search); 
        loadLots();
    });
 
    const loadLots=()=>{
        fetch(`${Constants.apiUrl}/reservation/cms/parkinglots`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
            }
          })
          .then((resp) => resp.json())
          .then((data) =>  {
           
            setLots(data);
            
          })
          .catch((error) => {
            console.log(error);
            
          })
        }


  return (
    <div className='directions'>
        <TopBar />
        <div className="main-page">
            <div className="head">
                <h1 className='heading'>Directions</h1>
                <p className="info">Click airport location to map directions</p>
                <p className="note"><span>Note</span>: In order to get directions to the parking facilities, you would have to click "Get Direction" and enter the address of the facilities in the associated Google map or Mapquest map for the respective airport.</p>
            </div>
            <table className="codes">
                <thead>
                    <tr>
                       {/*  <th>Airport Code</th> */}
                        <th>Airport</th>
                        <th>Address</th>
                        <th>Directions</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {
                        lots.map(lot => {
                            return (
                                <tr>
                                    {/* <td>{lot.code}</td> */}
                                    <td>{lot.parkinglotname}</td>
                                    <td>{lot.address1}</td>
                                    <td>
                                        <div className="links">
                                            <a href={`https://maps.google.com/?q=${lot.latitude},${lot.longitude}`} className='google-link' rel='noreferrer' target='_blank'>Google Map</a>
                                           {/*  <a href={airport.mapquestLink} className='mapquest-link' rel='noreferrer' target='_blank'>MapQuest</a> */}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default Directions
import React, { useContext } from 'react'
import '../../styles/Admin/allreports.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { ShareContext } from '../../context/ShareContext'
import Loading from '../../components/Loading'
import * as Constants from '../../constants'

function InternalReport() {
    const { adminReport, loadReport, isSidebarClosed } = useContext(ShareContext)

    // console.log(loadReport);

    //const reports = adminReport?.reservations

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            <div className="report-body">
                <div className="head">
                    <h2>RESERVATIONS - INTERNAL REPORT</h2>
                </div>
                
                
                    {adminReport.length>0 && adminReport?.map((rep, id) => {return (
                        <div className="tables" key={id}>
                            <div className="table-wrapper">
                                <table className='full-table'>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Date Range</th>
                                            <th>Duration</th>
                                            <th>Tax</th>
                                            <th>Others</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan='7'>{rep.parkinglotname}</td>
                                        </tr>
                                        <tr className='record'>
                                            <td>{rep.reservationid}</td>
                                            <td>{Constants.formatDateTime(rep.arrivaldate)}</td>
                                            <td>{rep.personparking} <br /> {rep.numofpersons} person(s) <br /> {rep.carDetails}</td>
                                            <td>{Constants.formatDateTime(rep.arrivaldate)} <br /> to <br /> {Constants.formatDateTime(rep.returndate)}</td>
                                            <td>{rep.durationbasis}</td>
                                            <td>{rep.tax}</td>
                                            <td>{rep.othertaxes}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-wrapper">
                                <table className='total-table'>
                                    <tbody>
                                        <tr className='total'>
                                            <td colSpan='5'>Total Count: <span></span></td>
                                            <td>Total Tax: <span>{rep.tax}</span></td>
                                            <td>Total Others: <span>{rep.totalothertax}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}) }
            </div>
        </div>
    </div>
  )
}

export default InternalReport
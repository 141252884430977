import React, { useState, useContext } from 'react'
import '../../styles/Admin/head.css'
import Logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { ShareContext } from '../../context/ShareContext';
import { useNavigate } from 'react-router-dom'

function Head() {
  const {setIsAdminLoggedIn} = useContext(ShareContext);

  const navigate = useNavigate()

  const [showBtns, setShowBtns] = useState(false)

  const logOut = () => {
    // Clear token from local storage
    localStorage.removeItem('adminToken');
  
    // Clear other user-related data from local storage
    localStorage.removeItem('adminDetails');
  
    // Set user logged in state to false
    setIsAdminLoggedIn(false);
  
    // Navigate to the login page
    navigate('/admin');
  };

  return (
    <div className='admin-heading'>
        <img src={Logo} alt='Company Logo' />
        <div className={ showBtns ? 'buttons show' : 'buttons'}>
            <Link to='/admin/profile'><button className='password'>Profile</button></Link>
            <button className='logout' onClick={logOut}>Log Out</button>
        </div>
        <div className={ showBtns ?'hamburger open' :'hamburger'} onClick={() => { setShowBtns(!showBtns)}}>
            <div className='line'></div>
            <div className='line'></div>
            <div className='line'></div>
        </div>
    </div>
  )
}

export default Head
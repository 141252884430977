import React, { useContext } from 'react'
import { ShareContext } from '../../context/ShareContext';
import '../../styles/User/upcomingreserve.css'
import TopBar from '../../components/User/TopBar'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPrint } from '@fortawesome/free-solid-svg-icons'

function UpcomingReservation() {
  const { reservations } = useContext(ShareContext);

  const openReservations = reservations.filter(item => item.reservationstatus === 'Open')

  function formatDateTime(inputDateTime) {
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
  
    const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(inputDateTime));
    return formattedDateTime;
  }

  return (
    <div className='upcoming-reservations'>
      <TopBar />
      <div className="main-page">
        <h1>Upcoming Reservations</h1>
        <div className="body">
          {
            openReservations.map((reserve, idx) => { return (
              <div className="reserve" key={idx}>
                <div className="header">
                  <h3>{reserve.airport}</h3>
                  <p>{reserve.parkinglotname}</p>
                </div>
                <div className="reserve-details">
                  <div className="each-detail">
                    <p className="title">Check In</p>
                    <p className="title-info">{formatDateTime(reserve.arrivaldate)}</p>
                  </div>
                  <div className="each-detail">
                    <p className="title">Check Out</p>
                    <p className="title-info">{formatDateTime(reserve.returndate)}</p>
                  </div>
                  <div className="each-detail">
                    <p className="title">Sub Total</p>
                    <p className="title-info">${reserve.totalpaid}</p>
                  </div>
                  <div className="each-detail">
                    <p className="title">Taxes & Fees</p>
                    <p className="title-info">${reserve.tax}</p>
                  </div>
                  <div className="each-detail">
                    <p className="title">Total</p>
                    <p className="title-info total">${reserve.totalpaid}</p>
                  </div>
                  <div className="each-detail">
                    <p className="title">Paid Online</p>
                    <p className="title-info paid-online">${reserve.totalpaid}</p>
                  </div>
                  <div className="each-detail">
                    <p className="title">Due at Lot</p>
                    <p className="title-info due">${reserve.payatlot}</p>
                  </div>
                  <div className="reserve-btns">
                    <Link to={`/reservation/modify/${reserve.reservationid}`}><button>Modify <FontAwesomeIcon icon={faEdit} /></button></Link>
                    <Link to={`/reservation/full-receipt/${reserve.reservationid}`}><button>Print Receipt <FontAwesomeIcon icon={faPrint} /></button></Link>
                    
                  </div>
                </div>
              </div>
            )})
          }
        </div>
      </div>

    </div>
  )
}

export default UpcomingReservation
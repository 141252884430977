import React, { useState, useEffect , useContext} from 'react'
import '../../styles/Admin/reservations.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faRotateRight} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { ShareContext } from '../../context/ShareContext'
import LoadingImg from '../../assets/loading.gif';
import Loading from '../../components/Loading'
import * as Constants from '../../constants';
import moment from "moment";
import Expired from '../../components/Expired'
import ApiAlert from '../../components/ApiAlert'
import { blue, red } from '@mui/material/colors'

function Reservations() {
    const { adminToken, airportList, isSidebarClosed } = useContext(ShareContext);
    const [openCreate, setOpenCreate] = useState(false)
    const [popUp, setPopUp] = useState({})
    const [currPage, setCurrPage] = useState(1)
    const [searchCategory, setSearchCategory] = useState("name")
    const [searchInput, setSearchInput] = useState("")
    const [filterData, setFilterData] = useState([]);
    const [reserves, setReserves] =useState([]);
    const [clientSearch, setClientSearch] =useState({selectedlot:'', reserveid:'',paymentid:'',lastname:'',pagestart:0,pagecount:30,direction:'next'});
    const [page, setPage] = useState({start:0,end:30, count:30});
    const [client,setClient] =useState({res:[]});
    const [cust, setCustomer] =useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [openUncancel, setOpenUncancel] = useState(false);
    const [resName, setResName] = useState('')
    const [isExpired, setIsExpired]=useState(false);
    const [cancelReason, setCancelReason]=useState('');
    const [activeResId, setActiveResId] = useState(0);
    const [alert, setAlert] =useState({open:false, title:'', subtitle:''});
    

    const cancelReserve = (id) => {
        setOpenCancel(true);

        let newName = client?.res?.find(item => item.reservationid === id).parkinglotname
        setActiveResId(id);
        // console.log(newName);

        setResName(newName)

    }
    const uncancelReserve = (id) => {
        setOpenUncancel(true);

        let newName = client?.res?.find(item => item.reservationid === id).parkinglotname
        setActiveResId(id);
        // console.log(newName);

        setResName(newName)

    }

    const onNextPage=()=>{
        var page_={...page, start:page.start+page.count,end:page.end+page.count,};
        setPage(page_);
        onLoadReservations(page_);

      }
      const onPrevPage=()=>{
        var page_={...page, start:page.start-page.count,end:page.end-page.count,};
        if(page_.start<0){
            return;
        }
        setPage(page_);
        onLoadReservations(page_);

      }

  const onOk=()=>{
    setAlert({open:false});
  }

    const clickReservation = (id) => {
        setOpenCreate(true)

        onLoadClient(id);
    }

    const categoryChange = (e) => {
        setSearchCategory(e.target.value)
    }

    const searchChange = e => {
        setSearchInput(e.target.value)
        
    }

    const onSearch=()=>{
        
        if(searchInput.trim()==''){
            setFilterData(reserves);
        }else{
           onSearchReservations(); 
        }
        
    }
    useEffect(() => {
        onLoadReservations(page);
      }, [adminToken])

      const onLoadReservations=({start,end})=>{
        setIsLoading(true);
        fetch(`${Constants.apiUrl}/parkingadmin/reservations/${start}/${end}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
          
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
            setIsLoading(false);
            // console.log(jresp);
            setReserves(jresp.clients);
            setFilterData(jresp.clients);
            if (jresp.code === -10) {
                setIsExpired(true)
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          })
      }
      const onSearchReservations=()=>{
        setIsLoading(true)
        fetch(`${Constants.apiUrl}/parkingadmin/reservations/search/${searchCategory}/${searchInput}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
            console.log(jresp);
            setReserves(jresp.clients);
            setFilterData(jresp.clients);
            setIsLoading(false)
            if (jresp.code === -10) {
                setIsExpired(true)
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false)
          })
      }

      const onLoadClient=(id)=>{
        setIsLoading(true)
        fetch(`${Constants.apiUrl}/parkingadmin/clients/${id}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            }
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
            if (jresp.code === -10) {
                setIsExpired(true)
            }
            console.log('return for customer')
            // console.log(jresp);
           setClient(jresp);
           setCustomer({...jresp.c});
           setIsLoading(false)
          })
          .catch((error) => {
            console.log(error);
           setIsLoading(false)
          })
    }
    const onManualCancel= async()=>{
        try {
            setIsLoading(true);
            const resp = await fetch(`${Constants.apiUrl}/parkingadmin/reservations/${activeResId}/manualcancel?reason=${cancelReason}`, {
              method: 'POST',
             // body: JSON.stringify(loginData),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken,
              },
            });
            
            const responseData = await resp.json();
            setIsLoading(false);
            console.log('login ongoing')
            console.log(responseData);
            if (responseData.code === 0) {
                setAlert({open:true, title:'Manual Cancellation', subtitle:"Reservation cancelled.", onOk:onOk})
                setOpenCancel(false);

                var res = [...client.res];
                res= res.map(el => {
                    if (el.reservationid === activeResId) { return {...el, reservationstatus:'Canceled'}; }
                    return el;
                  });

                  setClient({...client,res});

             
            } else if(responseData.code === -10) {
               setIsExpired(true);
                setOpenCancel(false);
             
            }
            else {
              console.log(responseData.status);
            }
          } catch (error) {
            setIsLoading(false);
            console.log(error);
          }
    
    }
    const onReverseCancel= async()=>{
        try {
            setIsLoading(true);
            const resp = await fetch(`${Constants.apiUrl}/parkingadmin/reservations/${activeResId}/uncancel?reason=${cancelReason}`, {
              method: 'POST',
             // body: JSON.stringify(loginData),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken,
              },
            });
            
            const responseData = await resp.json();
            setIsLoading(false);
            console.log('login ongoing')
            console.log(responseData);
            if (responseData.code === 0) {

                var res = [...client.res];
               res= res.map(el => {
                    if (el.reservationid === activeResId) { return {...el, reservationstatus:responseData.desc}; }
                    return el;
                  });

                  setClient({...client,res});

                setAlert({open:true, title:'Reverse Cancellation', subtitle:"Reservation reversed.", onOk:onOk})
                setOpenUncancel(false);
             
            } else if(responseData.code === -10) {
               setIsExpired(true);
               setOpenUncancel(false);
             
            }
            else {
              console.log(responseData.status);
            }
          } catch (error) {
            setIsLoading(false);
            console.log(error);
          }
    
    }
    const loginForm= async()=>{
        //for account registration
        try {
            setIsLoading(true);
            const resp = await fetch(`${Constants.apiUrl}/reservation/login`, {
              method: 'POST',
           //   body: JSON.stringify(loginData),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': '',
              },
            });
            
            const responseData = await resp.json();
            setIsLoading(false);
            console.log('login ongoing')
            console.log(responseData);
            if (responseData.code === 0) {
                setAlert({open:true, title:'Manual Cancellation', subtitle:"Reservation cancelled.", onOk:onOk})

             
            }else {
              
            }
          } catch (error) {
          
          }
    }

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}
            <div className="reserves">
                <div className="head">
                    <h1>Client Reservations</h1>
                    <p>See Client Reservations</p>
                </div>
                <div className="body-wrapper">
                    <div className="head-block">
                        <h3>Reservations</h3>
                        <div className="head-icons">
                            <FontAwesomeIcon icon={faRotateRight} className='action-icon refresh'/>
                        </div>
                    </div>
                    <div className="search-reserve">
                        <div className="input">
                            <label htmlFor="search">Search by</label>
                            <select name="search" id="search" value={searchCategory} onChange={categoryChange}>
                                <option value="name">By Last Name</option>
                                <option value="reserveid">Reservation ID</option>
                                <option value="transid">Transaction ID</option>
                                <option value="paymentid">Payment ID</option>
                            </select>
                        </div>
                        <input type="text" placeholder='Search' value={searchInput} onChange={searchChange}/>
                        <button onClick={onSearch} className='search-btn'>Search</button>
                    </div>
                 {isLoading && <div className={  "congrats show" }>
                        <Loading message={''}/>
                    </div>  }   
                    <div className={ openCreate ? "table-wrapper popUp" : "table-wrapper"}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Client Name</th>
                                    <th>Client E-mail</th>
                                    <th>Parking Lot</th>
                                    <th>Last Reservation ID</th>
                                    <th>Last Reservation Date</th>
                                    <th>Last Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterData?.length>0 && filterData.map((tableRow,idx) => {return(
                                    <tr key={idx}>
                                        <td>{tableRow.firstname} {tableRow.lastname}</td>
                                        <td>{tableRow.email}</td>
                                        <td>{tableRow.parkinglotname}</td>
                                        <td>{tableRow.transactionid}</td>
                                        <td>{Constants.formatDate(tableRow.reservationdate) }</td>
                                        <td className={tableRow.reservationstatus === 'Open' ? 'open' :
                                    tableRow.status === 'Closed' ? 'close':
                                    tableRow.status === 'Canceled' ? 'canceled': 'open'}>{tableRow.reservationstatus}</td>
                                        <td className='table-reserves' onClick={() => clickReservation(tableRow.email)}>Reservations</td>
                                    </tr>
                                )})}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination">
                        
                        <button onClick={() => onPrevPage()}>Previous</button>
                        <button onClick={() => onNextPage()}>Next</button>

                    </div>
                    <div className={ openCreate ? "reserve-wrapper active" : "reserve-wrapper"}>
                        
                        <div className="create-account">
                            <div className="create-header">
                                <h3>Reservation -{cust?.firstname} ({cust?.email})</h3>
                                <div className="close-it" onClick={() => setOpenCreate(false)}>
                                    <FontAwesomeIcon icon={faClose} />  
                                </div>
                            </div>
                                    
                          {isLoading &&  <div className={ "congrats show" }>
                                <Loading message={`Loading Customer's Reservations`}/>
                            </div> }  

                            <div className="all">
                                            <h3>Upcoming Reservations </h3>
                                            <div className="reservations">
                                             {client.res.length>0 && client.res.map((res, idx) =>{return (
                                             <div className="reservation" key={idx}>
                                                <h3>{res.parkinglotname} — {res.numofdays} days of parking with {res.carCount} car(s) at ${Constants.formatCurrency(res.dailyrate)} /day/car</h3>
                                                <div className="row">
                                                    <div className="rows">
                                                        <p className="title">Reservation ID</p>
                                                        <p className="answer">{res.transactionid}</p>
                                                    </div>
                                                    <div className="rows">
                                                        <p className='title'>Payment ID</p>
                                                        <p className='answer'>{res.paymentid}</p>
                                                    </div>
                                                    <div className="rows">
                                                        <p className="title">Check In</p>
                                                        <p className="answer">{Constants.formatDateTime( res.arrivaldate) }</p>
                                                    </div>
                                                    <div className="rows">
                                                        <p className="title">Check Out</p>
                                                        <p className="answer">{Constants.formatDateTime(res.returndate)  }</p>
                                                    </div>
                                                    <div className="rows">
                                                        <p className="title">Paid Online</p>
                                                        <p className="answer paid-online">{Constants.formatCurrency (res.totalpaid)}</p>
                                                    </div>
                                                    <div className="rows">
                                                        <p className="title">Due at Lot</p>
                                                        <p className="answer due">{Constants.formatCurrency(res.payatlot)}</p>
                                                    </div>
                                                    <div className="rows">
                                                        <p className="title">Total</p>
                                                        <p className="answer total">{Constants.formatCurrency(res.totalamount)}</p>
                                                    </div>
                                                    <div className="btns">
                                                      {(res.reservationstatus=='Open' || res.reservationstatus=='Closed' )&& 
                                                      <>
                                                       <span style={{color:blue[500], fontWeight:'bold'}}>{res.reservationstatus} Reservation</span>
                                                      <Link to={`/admin/modify/${res.reservationid}`} target='_blank'>
                                                            <button>Modify</button>
                                                        </Link>
                                                        <Link to={`/reservation/full-receipt/${res.reservationid}`} target='_blank'>
                                                            <button>Print Receipt</button>
                                                        </Link>
                                                        <Link to={`/admin/tag/${res.reservationid}`} target='_blank'>
                                                            <button>Print Tag</button>
                                                        </Link>
                                                        <button onClick={() => cancelReserve(res.reservationid)}>Manual Cancel</button>
                                                        
                                                        <Link to={`/admin/refund/${res.reservationid}`} target='_blank'>
                                                            <button>Refund</button>
                                                        </Link>
                                                      </>
                                                      }  
                                                      {res.reservationstatus == 'Canceled' &&
                                                      <>
                                                      <span style={{color:red[800]}}>Cancelled Reservation</span>
                                                      <button onClick={() => uncancelReserve(res.reservationid)}>Un-Cancel Reservation</button>
                                                      </>
                                                      }

                                                        
                                                        
                                                    </div>
                                                   
                                                </div>
                                                </div>)})}   

                                            </div>   
                                          {openCancel && <div className={  "pop-up-cancel show" }>
                                                <div className="wrapper">
                                                    <div className="head">
                                                        <h3>Manual Cancelation</h3>
                                                        <div className="close-btn" onClick={() =>setOpenCancel(false)}>
                                                            <FontAwesomeIcon icon={faClose} />
                                                        </div>
                                                    </div>
                                                    <div className="body">
                                                        <h4>Cancel {resName} Reservation</h4>
                                                        <p>This is a manual cancellation. Although not reqiured, we would like the reason for the cancelation in the text box below, in order to better our customer's needs.</p>
                                                        <div className="input">
                                                            <label htmlFor="cancel-reserve">Reason for Cancelation:</label>
                                                            <textarea name="" value={cancelReason} id="cancel-reserve" onChange={(e)=> setCancelReason(e.target.value)}></textarea>
                                                        </div>
                                                        <div className="btns">
                                                            <button type='button' onClick={onManualCancel}>Proceed to Cancel Reservation</button>
                                                            <p className="goBack" onClick={() => setOpenCancel(false)}>Back</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}  

                                            {openUncancel && <div className={  "pop-up-cancel show" }>
                                                <div className="wrapper">
                                                    <div className="head">
                                                        <h3>Reverse Cancellation</h3>
                                                        <div className="close-btn" onClick={() =>setOpenCancel(false)}>
                                                            <FontAwesomeIcon icon={faClose} />
                                                        </div>
                                                    </div>
                                                    <div className="body">
                                                        <h4>Reverse {resName} Reservation</h4>
                                                        <p>This is a reverse cancellation. Although not reqiured, we would like the reason for the reverse cancelation in the text box below.</p>
                                                        <div className="input">
                                                            <label htmlFor="cancel-reserve">Reason for Reverse Cancelation:</label>
                                                            <textarea name="" value={cancelReason} id="cancel-reserve" onChange={(e)=> setCancelReason(e.target.value)}></textarea>
                                                        </div>
                                                        <div className="btns">
                                                            <button type='button' onClick={onReverseCancel}>Proceed to Reverse Reservation</button>
                                                            <p className="goBack" onClick={() => setOpenUncancel(false)}>Back</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}  
                                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {alert.open && <div className={ "congrats show" }>
                <ApiAlert {...alert} />  
            </div> } 
    </div>
  )
}

export default Reservations
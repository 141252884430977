import React, { useEffect, useState, useContext } from 'react'
import '../../styles/Admin/parkinglot.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faRotateRight, faSearch} from '@fortawesome/free-solid-svg-icons'
import { states } from '../../data/userstates'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ShareContext } from '../../context/ShareContext'
import Loading from '../../components/Loading'
import LoadingImg from '../../assets/loading.gif';
import * as Constants from '../../constants';
import moment from "moment";
import Expired from '../../components/Expired'
import ApiAlert from '../../components/ApiAlert'
import { useParams, useNavigate } from 'react-router-dom'

function ParkingLot() {
  const { adminToken, airportList, isSidebarClosed } = useContext(ShareContext);
  const navigate = useNavigate();
    const rowObject = {
        "parkinglotid":"",
        "companyid":1,
        "parkinglotname":"",
        "parkingtype":"",
        "lotsize":0,
        "lowinventory":0,
        "shortsecuritystatement":"",
        "fullsecuritystatement":" ",
        "distancestatement":" ",
        "transportation":"",
        "rate":0,
        "arrivalinfo":"",
        "otherinfo":null,
        "contactfirstname":"",
        "contactlastname":"",
        "contactmi":null,
        "address1":"",
        "address2":null,
        "city":"",
        "state":"",
        "zipcode":"",
        "phone":"",
        "email":"",
        "fax":"",
        "direction":"",
        "airportcode":"",
        "fullpayonline":"",
        "servicefee":0,
        "taxrate":0,
        "status":"",
        "accessfee":0,
        "ranking":0,
        "imagelocation1":"",
        "imageurl1":"",
        "durationbasis":"",
        "threedigitcode":0,
        "generatebarcode":"",
        "parkinglotphone":"",
        "imagealt1":"","createdate":null,
        "updatedate":"",
        "barcodetype":"",
        "imagelocation2":"",
        "imageurl2":"",
        "imagealt2":"",
        "reservationtype":null,
        "monthlyrate":null,
        "netparkCode":null,
        "emailRequired":null,
        "parkconectId":null,
        "minday":0,
        "samedaybooking": "",
        "isSpace":"",
        "spaceComId":null,
        "spaceChannelid":null,
        "isAmano":false,
        "isQrCode":null,
       
        
        
    }

    const [loading, setLoading] = useState(false)
    const [loadMsg, setLoadMsg] = useState('')
    const [openCreate, setOpenCreate] = useState(false)
    const [selectedLot, setSelectedLot] = useState('')
    const [searchLot, setSearchLot] = useState('')
    const [itemClicked, setItemClicked] = useState('create-new')
    const [isNew, setIsNew] = useState(false);
    const [editData, setEditData] = useState(rowObject);
    const [form, setForm] = useState(rowObject);
    const [whichManage, setwhichManage] = useState('normal');
    const [openBox, setopenBox] = useState(false)
    const [isInputEmpty, setIsInputEmpty] = useState(false);
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false)
    const [lots, setLots] = useState([])
    const [lotSize, setLotSize] = useState('')
    const [holidayPage, setHolidayPage] = useState('')
    const [seasonRate, setSeasonRate] = useState('')
    const [blockout, setBlockout] = useState('');
    const [otherTaxes, setOtherTaxes] = useState('')
    const [selectedReservationIndex, setSelectedReservationIndex] = useState(0);
    const [lotId, setLotId] = useState('')
    const [isExpired, setIsExpired] = useState(false);
    const [alert, setAlert]=useState({ open:false});
    const [isLoading, setIsLoading]= useState(false);
    const [activelot, setActivelot] =useState({shortsecuritystatement:'', fullsecurityStatement:'',transportation:'' ,
    arrivalinfo:'', parkingrateinfo:'', otherinfo:'', direction:'', status:'active', rate:0, fullpayonline:'yes'});
    const [plot, setPlot] =useState({shortsecuritystatement:'', fullsecurityStatement:'',transportation:'' ,
    arrivalinfo:'', parkingrateinfo:'', otherinfo:'', direction:''});
    
                                    
     
     const [activelotId, setActivelotId]=useState ('');                               

    // console.log(lotId);

    const [lotSizeTable, setLotSizeTable] = useState()
    const [lotsizePicked, setLotsizePicked] = useState();
    const [createLotSizeRate, setCreateLotSizeRate] = useState({   
        createddate: "",
        dailyate: 0,
        description: "",
        enddate: "",
        id: 0,
        lotsize: 0,
        parkinglotid: "",
        startdate: ""
    })

    const [holiday, setHoliday] = useState([]) //editData.holidayRates

    const [holidayPicked, setHolidayPicked] = useState()
    const [createHolidayRate, setCreateHolidayRate] = useState({
        adjcorprate: 0,
        dailyrate: 0,
        description: "",
        enddate: "", 
        monthlyrate: "",
        recordid: 0,
        startdate: "",
        weeklyrate: 0
    })

    const [season, setSeason] = useState([]) //editData.seasonRates

    const [seasonPicked, setSeasonPicked] = useState()
    const [createSeasonRate, setCreateSeasonRate] = useState({
        adjcorprate: 0,
        dailyrate: 0,
        description: "",
        enddate: "", 
        monthlyrate: "",
        recordid: 0,
        startdate: "",
        weeklyrate: 0
    })

    const [blockoutDates, setBlockoutDates] = useState([]); //editData.blockedOutDates
    const [blockPicked, setBlockPicked] = useState({recordid:0})
    const [createBlock, setCreateBlock] = useState({
        createdate:"",
        enddate: "",
        parkinglotid: "",
        recordid: '',
        startdate: "",
        updatedate: "",
        updatedby: ""
    })

    const [otherTaxBox, setOtherTaxBox] = useState(editData.otherTaxes);
    const [otherTaxPicked, setOtherTaxPicked] = useState()
    const [createOtherTax, setCreateOtherTax] = useState({
        createdate: "",
        lotid: "",
        taxbasis: null,
        taxfreedays: "",
        taxid: '',
        taxname: "",
        taxstatus: "",
        taxtype: "",
        taxvalue: 0,
        updatedate: ""
    })
    const [filteredData, setFilteredData] = useState([]);

    const deleteHolidayRate = (id) => {
        const data = [...holiday]

        data.splice(id, 1)


        setHoliday(data)
    }

    const formChange = (e) => {
        if (e && e.target) {
          const { name, value } = e.target;
      
          // Check if the input is the rich text editor
          if (name === 'shortSecurityStatement' || name === 'fullSecurityStatement' || name === 'transportStatement' || name === 'arrivalInfo' || name === 'parkingRateInfo' || name === 'otherInfo' || name === 'direction') {
            setForm({ ...form, [name]: value });
          } else {
            setForm({ ...form, [name]: value });
            // handle other form changes for non-rich text inputs
          }
        }
    };

    const isFormValid = () => {
        return Object.values(form).every(value => value.trim() !== '');
      };

    const submitForm = (e) => {
        e.preventDefault();

        if (!isFormValid()) {
            setIsInputEmpty(true) 
          } else {
            setIsInputEmpty(false)
        }

        // console.log(form.shortSecurityStatement);
    }

    const updateReserveDate = (newDate, rowId) => {
        setEditData((prevData) => {
          // Create a copy of the original object
          const newData = { ...prevData };
    
          // Check if there are reservations
          if (newData.reservations && newData.reservations.length > 0) {
            // Update the reserveDate for the first reservation
            newData.reservations[rowId].reserveDate = newDate;
            // newData.reservations[0].numOfReservations
          }
    
          return newData;
        });
      };
    
    const editDataChange = (e, rowId) => {
        if (e && e.target) {
            const { name, value } = e.target;
            console.log(`name ${name} value ${value}`);
        
            // Check if the input is the rich text editor
            if (name === 'shortSecurityStatement' || name === 'fullSecurityStatement' || name === 'transportStatement' || name === 'arrivalInfo' || name === 'parkingRateInfo' || name === 'otherInfo' || name === 'direction') {
              setEditData({ ...editData, [name]: value });
            } else if (name === 'reserveDate') {
                updateReserveDate(value, rowId)
            } else {
              setEditData({ ...editData, [name]: value });
              // handle other form changes for non-rich text inputs
            }
          }
    }

    const lotDataChange=(e)=>{
        const { name, value } = e.target;
        setActivelot({ ...activelot, [name]: value });
    }
    

    // console.log(holidayPicked);
    const onAlertOk=()=>{
        setAlert({...ApiAlert, open:false})
    }

    const handleEditReserve = (rowIndex) => {
        // Set the row into edit mode
        setSelectedReservationIndex(rowIndex);
        setwhichManage('edit-booked-dates')
      };

    const airports = airportList;

    const createNewForm = () => {
        setOpenCreate(true)
        setItemClicked('edit')
        setEditData(rowObject)
        setIsNew(true);
    }

    const onSearch=(value)=>{
        const filteredData_ = lots?.filter(item => {
            const searchMatch = item.parkinglotname.toLowerCase().includes(value) || item.email.toLowerCase().includes(value) || item.contactlastname.toLowerCase().includes(value)
            
            const parkLot = selectedLot === '' || selectedLot === item.parkingLot
            return searchMatch && parkLot
        })
        setFilteredData(filteredData_);
        setSearchLot(value);
    }

    const changeDate = (dateInp) => {
        const date = new Date(dateInp);
    
        const formmattedDate = new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(date);
    
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
    
        const finalFormat = `${formmattedDate} ${formattedTime}`;
    
        return finalFormat;
      };

      const dateOnly = (data) => {
        const date = new Date(data);

        const formmattedDate = date.toISOString().split('T')[0]

        return formmattedDate
      
      } 

    //   console.log(holidayPicked);

    const LoadLot = (id) => {
         if(activelotId==id){
            console.log('data already loaded for '+ id);
            console.log(plot);
            console.log('active lot by another variable');
            console.log(activelot);
            return;
        }
        setLoading(true)
        setLoadMsg('Loading Parking Lot Details')
       
        setActivelotId(id);
        fetch(`${Constants.apiUrl}/parkingadmin/parkinglots/${id}`,{
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            // console.log(data);
            setLoading(false)
            console.log(data.pl);
            if(data.code==0){
                setPlot(data.pl)
                setActivelot(data.pl);
            }else if(data.code==-10){
                setIsExpired(true);
            }
            
            
        })
        .catch((error) => {
            console.error(error);
            setLoading(false)
            setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed});
        })
    }

    const lotDetails = (id) => {
        setOpenCreate(true)
        setItemClicked('details')
        LoadLot(id)
    }

    const lotEdit = (row) => {
        setOpenCreate(true)
        setItemClicked('edit')
        LoadLot(row.parkinglotid)
        setIsNew(false);
    }

    const rowManage = (row) => {
        setOpenCreate(true)
        setItemClicked('manage')
        setActivelot(row);
        setwhichManage('normal')

    }

    const rowLotSize = (row) => {
        const id=row.parkinglotid;
        setLoadMsg('Loading Lot Sizes')
        setLoading(true)
        fetch(`${Constants.apiUrl}/parkingadmin/${id}/dailylotsizes`,{
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            setLoading(false)
            // console.log(data);
            setLotSizeTable(data)
        })

        setLotId(id)

        setOpenCreate(true)
        setopenBox(false)
        setItemClicked('lot-size')
        // reserve(id)
    }

    const lotsizeChange = (e) => {
        setLotsizePicked({...lotsizePicked, [e.target.name]: e.target.value})
    }

    const lotsizeCreateChange = (e) => {
        setCreateLotSizeRate({...createLotSizeRate, [e.target.name]: e.target.value})
    }

    const rowHolidayRate = (row) => {
        const id=row.parkinglotid;
        setActivelot(row);
        setLoading(true)
        setLoadMsg('Loading Holiday Rates')
        fetch(`${Constants.apiUrl}/parkingadmin/${id}/holidayrates`,{
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            // console.log(data);
            setHoliday(data)
            setLoading(false)
        })

        setOpenCreate(true)
        setopenBox(false)

        setItemClicked('holiday-rate')
        setLotId(id)
    }

    const editholRateChange = (e) => {
        setHolidayPicked({...holidayPicked, [e.target.name]: e.target.value})
    }

    const createholRateChange = (e) => {
        setCreateHolidayRate({...createHolidayRate, [e.target.name]: e.target.value})
    }

    const rowSeasonRate = (row) => {
        const id=row.parkinglotid;
        setActivelot(row);
        setLoading(true)

        fetch(`${Constants.apiUrl}/parkingadmin/${id}/seasonrates`,{
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            // console.log(data);
            setSeason(data)
        setLoading(false)
        })

        setOpenCreate(true)
        setopenBox(false)

        setItemClicked('season-rate')
        setLotId(id)
    }

    const editseasonRateChange = (e) => {
        setSeasonPicked({...seasonPicked, [e.target.name]: e.target.value})
    }

    const createseasonRateChange = (e) => {
        setCreateSeasonRate({...createSeasonRate, [e.target.name]: e.target.value})
    }

    const rowBlockoutDate = (row) => {
        const id=row.parkinglotid;
        setActivelot(row);

        setLoading(true)
        fetch(`${Constants.apiUrl}/parkingadmin/${id}/blockedoutdates`,{
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            // console.log(data);
            setBlockoutDates(data.bud)
        setLoading(false)

        })
        setOpenCreate(true)
        setopenBox(false)

        setItemClicked('blockout-dates')
        setLotId(id)
    }

    const editblockchange = (e) => {
        setBlockPicked({...blockPicked, [e.target.name]: e.target.value})
    }

    const createblockchange = (e) => {
        setCreateBlock({...createBlock, [e.target.name]: e.target.value})
    }

    const rowOtherTaxes = (row) => {
        const id=row.parkinglotid;
        setActivelot(row);

        setLoading(true)
        setLoadMsg('Loading Other Taxes')
        fetch(`${Constants.apiUrl}/parkingadmin/${id}/othertax`,{
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            // console.log(data);
            setOtherTaxBox(data)
            setLoading(false)
        })

        setOpenCreate(true)
        setopenBox(false)

        setItemClicked('other-taxes')
        setLotId(id)
    }

    const edittaxchange = (e) => {
        setOtherTaxPicked({...otherTaxPicked, [e.target.name]: e.target.value})
    }

    const createtaxchange = (e) => {
        setCreateOtherTax({...createOtherTax, [e.target.name]: e.target.value})
    }

    const exitForm = () => {
        setOpenCreate(false)
        setIsInputEmpty(false)
        setForm(rowObject)
    }

    const createLotSize = () => {
        setopenBox(true);
        setLotSize('create')
        setLotsizePicked({})
    }

    const editLotSize = (id) => {
        const pickedlot = lotSizeTable.find(item => item.id === id);
        setLotsizePicked(pickedlot)
        setopenBox(true);
        setLotSize('edit')
    }

    const editholRate = (id) => {
        const pickedholrate = holiday.find(item => item.recordid === id);
        setHolidayPicked(pickedholrate)
        setopenBox(true);
        setHolidayPage('edit')
    }

    const createholRate = () => {
        setopenBox(true);
        setHolidayPage('create')
        setHolidayPicked({
            adjcorprate: '',
            dailyrate: '',
            description: "",
            enddate: "",
            monthlyrate: '',
            recordid: '',
            startdate: "",
            weeklyrate: ''
        })
    }

    const closeDialogBox = () => {
        setIsDialogBoxOpen(false);
    }

    const openDeleteBox = (id) => {
        setIsDialogBoxOpen(true);
    
        //reserve(id)
    }

    const deleteIt = (id) => {
        const updatedArray = lots?.filter(obj => obj.parkinglotid !== id)
      
        setLots(updatedArray)
        setIsDialogBoxOpen(false)
    }

    const seasonRateEdit = (id) => {
        const pickedseasonrate = season.find(item => item.recordid === id);
        setSeasonPicked(pickedseasonrate)
        setopenBox(true)
        setSeasonRate('edit')
    }
    function formatDate(date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(date).toLocaleDateString(undefined, options);
    }
      
      // Function to get today's date as a string
    function getTodayDate() {
        const today = new Date();
        return formatDate(today);
    }

    const seasonRateCreate = () => {
        setopenBox(true);
        setSeasonRate('create')
        setSeasonPicked({
            adjcorprate: '',
            dailyrate: '',
            description: "",
            enddate: "",
            monthlyrate: '',
            recordid: '',
            startdate: "",
            weeklyrate: ''
        })
    }

    const createBlockout = () => {
        setopenBox(true);
        setBlockout('create')
        setBlockPicked({
            "createdate":'',
            "enddate":'',
            "parkinglotid":"",
            "recordid":0,
            "startdate":'',
            
        })
    }

    const editBlockout = (id) => {
       console.log('the entered id '+id);
  
        setLoading(true)
        fetch(`${Constants.apiUrl}/parkingadmin/blockedoutdate/${id}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            // console.log(data);
            setLoading(false)
            if(data.code==0){
                //remove from list
                var bl = [...blockoutDates];
                bl= bl.map(el => {
                     if (el!=null && el.recordid != id) { 
                        return el;
                     }
                     
                   });
                   console.log(bl);
                 setBlockoutDates(bl);
                 setAlert({open:true, title:'Blockout Date',subtitle: "Blockout date removed successfully" })
            } else if(data.code === -10){
                setIsExpired(true)
            }else{
               // alert(data.status);
                setAlert({open:true, title:'Blockout',subtitle: data.status })
            }
            
        })
        .catch((error) => {
            console.error(error);
            setLoading(false)
            setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
        })



    }

    const createTax = () => {
        setopenBox(true);
        setOtherTaxes('create')
        setOtherTaxPicked({
            "createdate":"",
            "lotid":"",
            "taxbasis":null,
            "taxfreedays":"",
            "taxid":'',
            "taxname":"",
            "taxstatus":"",
            "taxtype":"",
            "taxvalue":'',
            "updatedate":""
        })
    }

    const editTax = (id) => {
        const pickedtax = otherTaxBox.find(item => item.taxid === id);
        setOtherTaxPicked(pickedtax)

        setopenBox(true);
        setOtherTaxes('edit')
    }

    function addOrdinalSuffix(day) {
        if (day >= 11 && day <= 13) {
          return `${day}th`;
        }
      
        switch (day % 10) {
          case 1:
            return `${day}st`;
          case 2:
            return `${day}nd`;
          case 3:
            return `${day}rd`;
          default:
            return `${day}th`;
        }
      }

    function convertToHumanReadableDate(htmlDateFormat) {
        const dateObject = new Date(htmlDateFormat);
      
        const day = dateObject.getDate();
        const monthIndex = dateObject.getMonth();
        const year = dateObject.getFullYear();
      
        const months = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
      
        const monthName = months[monthIndex];
      
        // Add ordinal suffix to the day
        const dayWithSuffix = addOrdinalSuffix(day);
      
        // Construct the human-readable date format
        const humanReadableDate = `${dayWithSuffix} ${monthName}, ${year}`;
      
        return humanReadableDate;
      }

      const createMarkup = (content) => ({ __html: (content ?? '').replace(/<br\s*\/?>/g, '\n') });

    //   console.log(createBlock);

      const blockoutCreateSubmit = async(e) => {
        e.preventDefault();

        setCreateBlock({...createBlock, parkinglotid: lotId})

        console.log(JSON.stringify(createBlock));
        setLoading(true);
        fetch(`${Constants.apiUrl}/parkingadmin/blockedoutdate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken
            },
            body: JSON.stringify(createBlock)
        }).then((resp) => {
            resp.json()
            console.log(resp);
        })
        .then((data) => {
            setLoading(false);
            console.log(data);
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
            setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
        })
      }

      const createTaxSubmit = async(e) => {
        e.preventDefault();

        setCreateOtherTax({...createOtherTax, lotid: lotId})

        console.log(JSON.stringify(createOtherTax));

        const sentData = {
            "lotid": lotId,
            "taxbasis": createOtherTax.taxbasis,
            "taxfreedays": createOtherTax.taxfreedays,
            "taxname": createOtherTax.taxname,
            "taxstatus": createOtherTax.taxstatus,
            "taxtype": createOtherTax.taxtype,
            "taxvalue": createOtherTax.taxvalue
        }

        console.log(JSON.stringify(sentData));
        setLoading(true);
        fetch(`${Constants.apiUrl}/parkingadmin/othertax`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken
            },
            body: JSON.stringify(sentData)
        }).then((resp) => {
            resp.json()
            console.log(resp);
        })
        .then((data) => {
            setLoading(false);
            console.log(data);
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
            setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
        })
      }

      const updateTaxSubmit =  async(e) => {

        // console.log(otherTaxPicked);

        const sentData = {
            "lotid": lotId,
            "taxbasis": otherTaxPicked.taxbasis,
            "taxfreedays": otherTaxPicked.taxfreedays,
            "taxid": otherTaxPicked.taxid,
            "taxname": otherTaxPicked.taxname,
            "taxstatus": otherTaxPicked.taxstatus,
            "taxtype": otherTaxPicked.taxtype,
            "taxvalue": otherTaxPicked.taxvalue
        }

        // console.log(sentData);
        e.preventDefault();
        setLoading(true)
        fetch(`${Constants.apiUrl}/api/parkingadmin/othertax/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken
            },
            body: JSON.stringify(sentData)
        }).then((resp) => {
            resp.json()
            console.log(resp);
        })
        .then((data) => {
            setLoading(true);
            console.log(data);
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
            setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
        })
      }

      useEffect(() => {
        setLoadMsg('Loading Parking Lots')
        setLoading(true)
        fetch(`${Constants.apiUrl}/parkingadmin/parkinglots`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            // console.log(data);
            setLoading(false)
            if(data.code==0){
                setLots(data.lots);
                setFilteredData(data.lots);
            } else if(data.code === -10){
                setIsExpired(true)
            }else{
               // alert(data.status);
                setAlert({open:true, title:'Notification',subtitle: data.status })
            }
            
        })
        .catch((error) => {
            console.error(error);
            setLoading(false)
            setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
        })
      }, [adminToken])


      const onSaveParkinglot=(e)=>{
        e.preventDefault();
        const url= isNew ?`${Constants.apiUrl}/parkingadmin/parkinglot` : `${Constants.apiUrl}/parkingadmin/parkinglot/${activelot.parkinglotid}`;
        if((activelot.rateType=='both' || activelot.rateType=='hourly') && lotHours.length==0){
            setAlert({open:true, title:'Error in Saving', subtitle:'You have selected the rate type to be hourly and no hourly rate entry. Click the Add Hourly rates button.'});
            return;
        }
    
    
    var ractive={...activelot,lotImages:[], hourlyRates:lotHours,airportcode:'MSY'}
        console.log(JSON.stringify(ractive));
       /*  if (!isFormValid()) {
            setIsInputEmpty(true) 
          } else {
            setIsInputEmpty(false)
        }
 */
        setLoading(true);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken
            },
            body: JSON.stringify(ractive)
        })
        .then((resp) => resp.json())
        .then((data) => {
            setLoading(false);
            console.log(data);
            if(data.code==0){
                  setOpenCreate(false); //close the dialog
                setAlert({open:true, title:'Parking Lot', subtitle:'Parking Lot Saved Successfully'});
              
            } 
            else if(data.code==-10){
                setIsExpired(true);
            }
            else{
                setAlert({open:true, title:'Parking Lot Issues', subtitle:data.status});
            }
        })
        .catch((error) => {
            console.log('**************************error *************************')
            console.log(error)
            setLoading(false)
             setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
        })
      }

      const onBlockout=(e)=>{
        e.preventDefault();
        const url= (blockPicked.recordid==null || blockPicked.recordid==0 ) ?`${Constants.apiUrl}/parkingadmin/blockedoutdate` : `${Constants.apiUrl}/parkingadmin/blockedoutdate/${blockPicked.recordid}`;

        console.log(JSON.stringify(blockPicked));
        setLoading(true);
        var bl={...blockPicked, parkinglotid: activelot.parkinglotid, recordid:blockPicked.recordid}
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken
            },
            body: JSON.stringify(bl)
        })
        .then((resp) => resp.json())
        .then((data) => {
            setLoading(false);
            console.log(data);
            if(data.code==0){
                  setOpenCreate(false); //close the dialog
                setAlert({open:true, title:'Blockout Date', subtitle:'Blockdate Saved Successfully'});
              
            } 
            else if(data.code==-10){
                setIsExpired(true);
            }
            else{
                setAlert({open:true, title:'Blockout Issues', subtitle:data.status});
            }
        })
        .catch((error) => {
            console.log('**************************error *************************')
            console.log(error)
            setLoading(false)
             setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
        })
      }

    const [lotHours, setLotHours]=useState([]);
      const editLotHourChange = (id,e) => {
        var c_hrs=[...lotHours];
        var l={...c_hrs[id]};
        l={...l, [e.target.name]: e.target.value};
        c_hrs[id]={...l};
        setLotHours(c_hrs)  ;
    }
   const onRemoveLotHour=(id)=>{
        console.log(`index in question ${id}`);
        console.log(lotHours)
        var hrs=[...lotHours];
        hrs.splice(id,1);
        console.log(hrs)
        setLotHours(hrs);
    }



  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
        

            <div className="parking-lot">
                <div className="head">
                    <h1>Parking Lot</h1>
                    <p>Create, Modify and manage parking lot</p>
                </div>
                <div className= "body-wrapper">
                    <div className="head-block">
                        <h3>Parking Lot Details</h3>
                        <div className="head-icons">
                            <FontAwesomeIcon icon={faRotateRight} className='action-icon refresh'/>
                        </div>
                    </div>
                    <div className="adding-client">
                        <button className='add' onClick={createNewForm}>Create New</button>
                       {/*  <div className="selection">
                            <label htmlFor="filter-lots">Filter By Parking Lot</label>
                            <select name="filterlots" id="filter-lots" value={selectedLot} onChange={(e) => setSelectedLot(e.target.value)}>
                                <option value="">All Parking Lots</option>
                                {
                                    lots?.map((lot, id) => { return(
                                        <option key={id} value={lot.parkingLot}>{lot.parkingLot}</option>
                                    ) })
                                }
                            </select>
                        </div> */}
                        <div className="search-input">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="text" placeholder='Search Parking Lot' value={searchLot} onChange={(e) =>onSearch(e.target.value) }/>
                        </div>
                    </div>
                    <div className={openCreate ? "table-wrapper popUp" :"table-wrapper"}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Parking Lot</th>
                                    <th>Parking Type</th>
                                    <th>Lot Size</th>
                                    <th>Rate Type</th>
                                    <th>Rate</th>
                                    <th>Contact Details</th>
                                    <th>Airport</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData?.map((row, idx) => { return ( 
                                        <tr key={idx}>
                                            <td>{row.parkinglotname}</td>
                                            <td>{row.parkingtype}</td>
                                            <td>{row.lotsize}</td>
                                            <td>{row.rateType}</td>
                                            <td style={{width:'13%'}}>
                                                {(row.rateType=='daily' || row.rateType=='both') && <p style={{marginBottom:16}}>Overnight <strong>${row.rate}</strong></p> }
                                                {(row.rateType=='hourly' || row.rateType=='both') && 
                                                <table>
                                                    <tr>
                                                        <th>Duration</th>
                                                        <th> Rate</th>
                                                    </tr>
                                                    {row.hourlyRates?.map((hour, idxx) => { return (<tr id={{idxx}}>
                                                    <td>{ Constants.formatTime2(hour.startHr)} </td>
                                                    <td>{Constants.formatCurrency(hour.rate)}</td>
                                                    </tr> )})}
                                                    
                                                </table>
                                                }

                                            </td>
                                            <td className='contact' style={{width:'15%'}}>
                                                <ul className='details'>
                                                    <li className="name">{row.contactfirstname + ' ' + row.contactlastname}</li>
                                                    <li className="address">{row.address1}</li>
                                                    <li className="tel">Customer Service Tel: <span>{row.phone}</span></li>
                                                    <li className="tel">Lot Tel: <span>{row.parkinglotphone}</span></li>
                                                    <li className="email">E-mail: <span>{row.email}</span></li>                                                
                                                </ul>
                                            </td>
                                            <td>{row.airportcode}</td>
                                            <td className='active'>{row.status}</td>
                                            <td>
                                                <ul className='actions'>
                                                <li onClick={() => navigate(`/admin/parking-lot/${row.parkinglotid}`)}> Details</li>
                                                   {/*  <li onClick={() => lotDetails(row.parkinglotid)}>Details</li>
                                                    <li onClick={() => lotEdit(row)}>Edit</li>
                                                    <li onClick={() => rowManage(row)}>Manage</li>
                                                    <li onClick={() => rowLotSize(row)}>Daily Lot Size</li>
                                                    <li onClick={() => rowHolidayRate(row)}>Holiday Rate</li>
                                                    <li onClick={() => rowSeasonRate(row)}>Season Rate</li>
                                                    <li onClick={() => rowBlockoutDate(row)}>Block Out Dates</li>
                                                    <li onClick={() => rowOtherTaxes(row)}>Other Taxes</li>
                                                    <li className='delete' onClick={() => openDeleteBox(row.parkinglotid)}>Delete</li> */}
                                                </ul>
                                            </td>
                                </tr>
                                    )})
                                }
                            </tbody>
                        </table>
                    
                    </div>
                  {openCreate && <div className={  "create-new show" }>
                    {
                            itemClicked === 'edit' &&
                            <div className="main-form">
                            <div className="head">
                                <h3>  {isNew ?'New':'Update' } Parking Lot</h3>
                                <FontAwesomeIcon icon={faClose} className='head-icon' onClick={() => setOpenCreate(false)}/>
                            </div>
                            <form method="post" onSubmit={onSaveParkinglot}>
                                <div className="parking-info">
                                        <h3>Parking Lot Information</h3>
                                        <div className="input">
                                            <label htmlFor="lot-name">Parking Lot Name</label>
                                            <input type="text" name='parkinglotname' id='lot-name' value={activelot.parkinglotname} required onChange={lotDataChange}/>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-id">Parking Lot ID</label>
                                                <input type="text" name='parkinglotid' id='lot-id' maxLength={15} value={activelot.parkinglotid} disabled={isNew ?false:true } required onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-type">Parking Lot Type</label>
                                                <input type="text" name='parkingtype' id='lot-type' required value={activelot.parkingtype} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-state">Reservation Type</label>
                                                <select name="reservationtype" id="reservationtype" required value={activelot.reservationtype} onChange={lotDataChange}>
                                                    <option value="" >Select Reservation Parking Type</option>
                                                    <option value='cruise'>Cruise Parking</option>
                                                    <option value='hotel'>Hotel Parking</option>
                                                    <option value='both'>Cruise and Hotel Parking</option>
                                                    <option value='downtown'>Downtown Parking</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-days">Minimum Days Allowed</label>
                                                <input type="number" min={1} name='minday' id='lot-days' value={activelot.minday} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-threshold">Low Inventory Threshold</label>
                                                <input type="text" name='lowinventory' id='lot-threshold' value={activelot.lowinventory} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-code">Three Digit Code</label>
                                                <input type="text" name='threedigitcode' id='lot-code' value={activelot.threedigitcode} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-size">Parking Lot Size</label>
                                                <input type="text" name='lotsize' required id='lot-size' value={activelot.lotsize} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-contactFirst">Contact First Name</label>
                                                <input type="text" name='contactfirstname'  id='lot-contactFirst' value={activelot.contactfirstname} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-contactLast">Contact Last Name</label>
                                                <input type="text" name='contactlastname' id='lot-contactLast' value={activelot.contactlastname} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lot-address">Address 1</label>
                                            <input type="text" name='address1' id='lot-address' required value={activelot.address1} onChange={lotDataChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lot-addressTwo">Address 2</label>
                                            <input type="text" name='address2' id='lot-addressTwo' value={activelot.address2} onChange={lotDataChange}/>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-city">City</label>
                                                <input type="text" name='city' id='lot-city' required value={activelot.city} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-state">State</label>
                                                <select name="state" id="user-state" required value={activelot.state} onChange={lotDataChange}>
                                                    <option value="" disabled>Select State/Province</option>
                                                    {
                                                        states.map((state, id) => {
                                                        return (
                                                            <option key={id} value={state.code}>{state.name}</option>
                                                        )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-postcode">Postal Code</label>
                                                <input type="text" name='zipcode' id='lot-postcode' value={activelot.zipcode} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-phone">Phone</label>
                                                <input type="tel" name='phone' id='lot-phone' required value={activelot.phone} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-email">E-mail</label>
                                                <input  name='email' id='lot-email' required value={activelot.email} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-fax">Fax</label>
                                                <input type="tel" name='fax' id='lot-fax' value={activelot.fax} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="short-statement">Short Security Statement</label>
                                            <textarea name='shortsecuritystatement'
                                                value={activelot.shortsecuritystatement} onChange={lotDataChange}> </textarea>
                                            {/* <ReactQuill
                                                id="short-statement"
                                                name='shortsecuritystatement'
                                                value={activelot.shortsecuritystatement}
                                                onChange={(e) => setActivelot({ ...activelot, shortsecuritystatement: e })}
                                            /> */}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="full-statement">Full Security Statement</label>
                                        
                                            <textarea  name='fullsecuritystatement'
                                                value={activelot.fullsecuritystatement} onChange={lotDataChange}></textarea>
                                           {/*  <ReactQuill
                                                id="full-statement"
                                                name='fullsecuritystatement'
                                                value={activelot.fullsecuritystatement}
                                                onChange={(e) => setActivelot({ ...activelot, fullsecuritystatement: e })}
                                            /> */}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lot-dist">Distance from Airport</label>
                                            <input type="text" name="distancestatement" id="lot-dist" value={activelot.distancestatement} onChange={lotDataChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="transportation-statement">Transportation Statement</label>
                                            
                                            <textarea name='transportation'
                                                value={activelot.transportation} onChange={lotDataChange}></textarea>
                                            {/* <ReactQuill
                                                id="transportation-statement"
                                                name='transportation'
                                                value={activelot.transportation}
                                                onChange={(e) => setActivelot({ ...activelot, transportation: e })}
                                            /> */}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="arrival-info">Arrival Information</label>
                                           
                                            <textarea name='arrivalinfo'
                                                value={activelot.arrivalinfo} onChange={lotDataChange}></textarea>
                                            {/* <ReactQuill
                                                id="arrival-info"
                                                name='arrivalinfo'
                                                value={activelot.arrivalinfo}
                                                onChange={(e) => setActivelot({ ...activelot, arrivalinfo: e })}
                                            /> */}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="parking-info">Parking Information</label>
                                            
                                            <textarea name='parkingrateinfo'
                                                value={activelot.parkingrateinfo} onChange={lotDataChange}></textarea>
                                            {/* <ReactQuill
                                                id="parking-info"
                                                name='parkingrateinfo'
                                                value={activelot.parkingrateinfo}
                                                onChange={(e) => setActivelot({ ...activelot,parkingrateinfo : e })}
                                            /> */}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="other-info">Other Information</label>
                                            <textarea name='otherinfo'
                                                value={activelot.otherinfo} onChange={lotDataChange}></textarea>
                                            {/* <ReactQuill
                                                id="other-info"
                                                name='otherinfo'
                                                value={activelot.otherinfo}
                                                onChange={(e) => setActivelot({ ...activelot, otherinfo: e })}
                                            /> */}
                                        </div>                                 
                                </div>
                                <div className="another-section">
                                    
                                        <h3>Another Section</h3>
                                       
                                       
                                        
                                        <div className="input">
                                            <label htmlFor="direction">Direction</label>
                                            <textarea name='direction'
                                                value={activelot.direction} onChange={lotDataChange}></textarea>
                                            {/* <ReactQuill
                                                id="direction"
                                                name='direction'
                                                value={activelot.direction}
                                                onChange={(e) => setActivelot({ ...activelot, direction: e })}
                                            /> */}
                                        </div>
                                        <div className="inputs">
                                           
                                            <div className="input">
                                                <label htmlFor="pay-online">Full Payment Online</label>
                                                <select name="onlinepay" id="pay-online" value={activelot.onlinepay} onChange={lotDataChange}>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="head-input">
                                    <h2>PARKING RATES</h2>
                                    
                                    <div className="input">
                                        <label htmlFor="rateType">Rate Type</label>
                                        <select id="rateType" value={activelot?.rateType} name='rateType' onChange={lotDataChange} required>
                                        <option value="">Select Rate Type</option>
                                        <option value="both">Daily & Hourly Rate</option>
                                            <option value="daily">Daily</option>
                                            <option value="hourly">Hourly</option>
                                        </select>
                                    </div>   
                                    {(activelot?.rateType=='daily' || activelot?.rateType=='both')  &&  <div className="input">
                                        <label htmlFor="daily-rate">Daily Rate</label>
                                        <input type="number" id='daily-rate' value={activelot.rate} name='rate' min={0.1} step={0.01} onChange={lotDataChange} required/>
                                    </div>}
                                    {(activelot?.rateType=='hourly' || activelot?.rateType=='both') && <div>
                                            {
                                            lotHours?.length>0 &&  lotHours.map((hour, id) => {
                                                return (
                                                    <div className="inputs" key={id}>
                                            
                                                <div className="input">
                                                    <label htmlFor="holidayRateStartDate">Start Hour</label>
                                                    <input type="number"  value={ hour.startHr } name='startHr' min={0} onChange={(e)=>editLotHourChange(id,e)} required />
                                                </div>
                                                <div className="input">
                                                    <label htmlFor="holidayRateStartDate">End Hour</label>
                                                    <input type="number"  value={ hour.endHr } name='endHr' max={24} onChange={(e)=>editLotHourChange(id,e)}  />
                                                </div>
                                                <div className="input">
                                                    <label htmlFor="holidayRateStartDate">Rate</label>
                                                    <input type="number"  value={ hour.rate } name='rate' min={0.1} step={0.01} onChange={(e)=>editLotHourChange(id,e)} required  />
                                                </div>
                                                <div className="input">
                                                    <button style={{width:100}} className='buttons' onClick={()=> onRemoveLotHour(id)} >Remove</button>
                                                </div>
                                                
                                            </div>
                                                )
                                                })
                                            }
                                            <button type='button' onClick={()=> setLotHours([...lotHours,{parkinglotId:'--'}]) }  className='buttons'>Add Hourly Rates</button>
                                    </div>}
                                    <div className="inputs">
                                        <div className="input">
                                            <label htmlFor="tax-rate">Tax Rate</label>
                                            <input type="text" name='taxrate' id='tax-rate' min={0.01} step={0.01} value={activelot.taxrate} onChange={lotDataChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="service-fee">Service Fee</label>
                                            <input type="text" name='servicefee' id='service-fee' min={5} required value={activelot.servicefee} onChange={lotDataChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="access-fee">Access Fee</label>
                                            <input type="text" name='accessfee' id='access-fee' min={0.1} step={0.01} value={activelot.accessfee} onChange={lotDataChange}/>
                                        </div>              
                                        
                                    </div>
                                    </div>

                                       
                                       
                                        <div className="inputs">
                                             <div className="input">
                                                <label htmlFor="lot-rank">Rank</label>
                                                <input type="text" name='ranking' id='lot-rank' value={activelot.ranking} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-status">Status</label>
                                                <select name="status" id="lot-status" value={activelot.status} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="receipt-barcode">Barcode on Receipt</label>
                                                <select name="generatebarcode" id="receipt-barcode" value={activelot.generatebarcode} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="barcode-type">Barcode Type</label>
                                                <select name="barcodetype" id="barcode-type" value={activelot.barcodetype} onChange={lotDataChange}>
                                                    <option value="" disabled>Select Barcode</option>
                                                    <option value="128">Code 128</option>
                                                    <option value="39">Code 39</option>
                                                    <option value="39">Code 39</option>
                                                </select>
                                            </div>
                                            
                                          
                                        </div>
                                    
                                    
                                        <div className="head-input">
                                            <h2>NETPARK INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="location-code">Netpark Location Code</label>
                                                <input type="text" name='netparkCode' id='location-code' value={activelot.netparkCode} onChange={lotDataChange} />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="netpark-email">Should Netpark send E-mail?</label>
                                                <select name="netparkCode" id="netpark-email" value={activelot.netparkCode} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="head-input">
                                            <h2>PARKONECT INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="parkonect-id">Parkonect Garage ID</label>
                                                <input type="text" name='parkconectId' id='parkonect-id' value={activelot.parkconectId} onChange={lotDataChange} />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="same-daybooking">Allow same day booking?</label>
                                                <select name="samedaybooking" id="same-daybooking" value={activelot.samedaybooking} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="head-input">
                                            <h2>SPACE GENIUS INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="spacegenius-id">Space Genius Channel ID</label>
                                                <input type="text" name='spaceChannelid' id='spacegenius-id' value={activelot.spaceChannelid} onChange={lotDataChange} />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="spacegeniuscompany-id">Space Genius Company ID</label>
                                                <input type="text" name='spaceComId' id='spacegeniuscompany-id' value={activelot.spaceComId} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="head-input">
                                            <h2>AMINO INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="barcode-type">Parking Lot Support Amino?</label>
                                                <select name="isAmano" id="isAmano" value={activelot.isAmano} onChange={lotDataChange}>
                                                    <option value="" disabled>Select Yes For Amino</option>
                                                      <option value={false}>No</option>
                                                    <option value={true}>Yes</option>
                                                  
                                                   
                                                </select>
                                            </div>
                                            {(activelot.isAmano=='true' || activelot.isAmano) && <div className="input">
                                                <label htmlFor="Aminofacility">Amino Facility ID </label>
                                                <input type="text" name='aminoFacility' id='Aminofacility' required value={activelot.aminoFacility} onChange={lotDataChange}/>
                                            </div>} 
                                            
                                        </div>
                                    
                                </div>
                                <button className="savebtn" type='submit'>Save</button>
                            </form>
                        </div>
                        }
                        { itemClicked === 'details' && 
                            <div className="main-form">
                            <div className="head">
                                <h3>Parking Lot Details</h3>
                                <FontAwesomeIcon icon={faClose} className='head-icon' onClick={() => setOpenCreate(false)}/>
                            </div>
                            <div className='details'>
                                <div className="rows">
                                    <div className="row">
                                        <p className="title">Parking Lot ID</p>
                                        <p className="answer">{activelot.parkinglotid}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Three Digit Code</p>
                                        <p className="answer">{activelot.threedigitcode}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Parking Lot Name</p>
                                        <p className="answer">{activelot.parkinglotname}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Parking Type</p>
                                        <p className="answer">{activelot.parkingtype}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Parking Lot Size</p>
                                        <p className="answer">{activelot.lotsize}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Minimum Days Allowed</p>
                                        <p className="answer">{activelot.minday}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Low Inventory Threshold</p>
                                        <p className="answer">{activelot.lowinventory}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Short Security Statement</p>
                                        <p className="answer" dangerouslySetInnerHTML={createMarkup(activelot.shortsecuritystatement)}></p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Full Security Statement</p>
                                        <p className="answer" dangerouslySetInnerHTML={createMarkup(activelot.fullsecuritystatement)}></p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Distance from Airport</p>
                                        <p className="answer" dangerouslySetInnerHTML={createMarkup(activelot.distancestatement)}></p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Transportation Statement</p>
                                        <p className="answer" dangerouslySetInnerHTML={createMarkup(activelot.transportation)}></p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Rate</p>
                                        <p className="answer">{activelot.rate}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Arrival Information</p>
                                        <p className="answer" dangerouslySetInnerHTML={createMarkup(activelot.arrivalinfo)}></p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Parking Rate Information</p>
                                        <p className="answer" dangerouslySetInnerHTML={createMarkup(activelot.parkingrateinfo)}></p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Other Information</p>
                                        <p className="answer" dangerouslySetInnerHTML={createMarkup(activelot.otherinfo)}></p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Contact First Name</p>
                                        <p className="answer">{activelot.contactfirstname}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Contact Last Name</p>
                                        <p className="answer">{activelot.contactlastname}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Address</p>
                                        <p className="answer">{activelot.address1}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">City</p>
                                        <p className="answer">{activelot.city}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">State</p>
                                        <p className="answer">{activelot.state}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Postal Code</p>
                                        <p className="answer">{activelot.zipcode}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Customer Service Phone</p>
                                        <p className="answer">{activelot.phone}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Lot/Hotel Phone</p>
                                        <p className="answer">{activelot.parkinglotphone}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">E-mail Address</p>
                                        <p className="answer">{activelot.email}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Fax</p>
                                        <p className="answer">{activelot.fax}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Direction</p>
                                        <p className="answer" dangerouslySetInnerHTML={createMarkup(activelot.direction)}></p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Airport Code</p>
                                        <p className="answer">{activelot.airportcode}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Allow Full Payment Online</p>
                                        <p className="answer">{activelot.fullpayonline}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Online Service Fee($)</p>
                                        <p className="answer">{activelot.servicefee}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Airport Access Fee($)</p>
                                        <p className="answer">{activelot.accessfee}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Tax Rate($)</p>
                                        <p className="answer">{activelot.taxrate}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Status</p>
                                        <p className="answer">{activelot.status}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Rank</p>
                                        <p className="answer">{activelot.ranking}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Reservation Duration Basis</p>
                                        <p className="answer">{activelot.durationbasis}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Generate Barcode</p>
                                        <p className="answer">{activelot.generatebarcode}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Netpark Location Code</p>
                                        <p className="answer">{activelot.netparkcode}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Should Netpark Send email?</p>
                                        <p className="answer">{activelot.netparkcode}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Parkonect Garage ID</p>
                                        <p className="answer">{activelot.parkonectId}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Allow same day booking?</p>
                                        <p className="answer">{activelot.samedaybooking}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Space Genius Channel ID</p>
                                        <p className="answer">{activelot.spaceChannelid}</p>
                                    </div>
                                    <div className="row">
                                        <p className="title">Space Genius Company ID</p>
                                        <p className="answer">{activelot.spaceComid}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {
                            itemClicked === 'manage' &&
                            <div className="main-form">
                                <div className="head">
                                    <h3>{editData.parkinglotname}  Availability</h3>
                                    <FontAwesomeIcon icon={faClose} className='head-icon' onClick={() => setOpenCreate(false)}/>
                                </div>
                                {  whichManage === 'normal' &&
                                <div className="body">
                                    <div className="btns">
                                        <p onClick={() => setwhichManage('booked-dates')}>Booked Dates</p>
                                        <button className='savebtn' onClick={() => setwhichManage('new-dates')}>Add New Reserved Date</button>
                                    </div>
                                    <div className="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Reservation Date</th>
                                                    <th>Reservations</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    editData.reservations?.map((item, id) => { return ( 
                                                        <tr key={id}>
                                                            <td>{convertToHumanReadableDate(item.reserveDate)}</td>
                                                            <td>{item.numOfReservations}</td>
                                                            <td className='edit' onClick={() => handleEditReserve(id)}>Edit</td>
                                                        </tr>
                                                    ) })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                                {
                                    whichManage === 'booked-dates' &&
                                    <div className="body">
                                    <div className="btns">
                                        <button className='savebtn' onClick={() => setwhichManage('create-booked-date')}>Create Booked Date</button>
                                        <p onClick={() => setwhichManage('normal')}>Back to Reserved Dates</p>
                                    </div>
                                    <div className="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Booked Date</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    editData.booked ?  <tr>
                                                    <td></td>
                                                    <td className='edit' onClick={() => setwhichManage('edit-booked-dates')}>Edit</td>
                                                </tr>  : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                }
                                {
                                    whichManage === 'create-booked-date' &&
                                    <div className="body">
                                        <h3>Create Booked Date</h3>
                                        <div className="btns">
                                            <p onClick={() => setwhichManage('normal')}>Back to Reserved Dates</p>
                                            <p onClick={() => setwhichManage('booked-dates')}>Back to Booked Dates</p>
                                        </div>
                                        <div className="createDate">
                                            <div className="input">
                                                <label htmlFor="create-booked-date">Booked Date</label>
                                                <input type="date" name="createDate" id="create-booked-date" />
                                            </div>
                                            <button className='savebtn'>Save</button>
                                        </div>
                                </div>
                                }
                                {
                                    whichManage === 'new-dates' &&
                                    <div className="body">
                                    <h3>Create Reserved Date</h3>
                                    <div className="btns">
                                        <p onClick={() => setwhichManage('normal')}>Back to Reserved Dates</p>
                                    </div>
                                    <div className="createDate">
                                        <div className="input">
                                            <label htmlFor="create-booked-date">Booked Date</label>
                                            <input type="date" name="createDate" id="create-booked-date" />
                                        </div>
                                        <div className="input">
                                            <label htmlFor="reserve-count">Reservation Count</label>
                                            <input type="number" name="createDate" id="reserve-count" />
                                        </div>
                                        <button className='savebtn'>Save</button>
                                    </div>
                            </div>
                                }
                                {
                                    whichManage === 'edit-booked-dates' &&
                                    <div className="body">
                                    <h3>Edit Booked Date</h3>
                                    <div className="btns">
                                        <p onClick={() => setwhichManage('normal')}>Back to Reserved Dates</p>
                                    </div>
                                    <div className="createDate">
                                        <div className="input">
                                            <label htmlFor="edit-booked-date">Booked Date</label>
                                            <input type="date" name="reserveDate" id="edit-booked-date" value={editData.reservations[selectedReservationIndex]?.reserveDate || ''} onChange={(e) => editDataChange(e, selectedReservationIndex)} />
                                        </div>
                                        <div className="input">
                                            <label htmlFor="edit-reserve-count">Reservation Count</label>
                                            <input type="number" name="numOfReservations" id="edit-reserve-count" />
                                        </div>
                                        <button className='savebtn'>Save</button>
                                    </div>
                            </div>
                                }
                            </div>
                        }
                        {
                            itemClicked === 'lot-size' &&
                            <div className="main-form">
                                <div className="head">
                                    <h3>{editData.parkinglotname}  Daily Lots</h3>
                                    <FontAwesomeIcon icon={faClose} className='head-icon' onClick={() => setOpenCreate(false)}/>
                                </div>
                                { openBox ? 
                                <div className="lot">
                                    {(lotSize === 'edit' || lotSize === 'create') && <form className="create-lotSize">
                                        <h3>{lotSize === 'create' ? 'Create' : 'Edit'} Lot Size</h3>
                                        <div className="input">
                                            <label htmlFor="lotsizenum">Lot Size</label>
                                            <input type="number" id='lotsizenum' value={lotsizePicked.lotsize} name='lotsize' onChange={lotsizeChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lotdescription">Description</label>
                                            <textarea id="lotdescription" value={lotsizePicked.description} name='description' onChange={lotsizeChange}></textarea>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lotsizeStartDate">Start Date</label>
                                                <input type="date" id='lotsizeStartDate' value={lotsizePicked.startdate} name='startdate' onChange={lotsizeChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lotsizeEndDate">End Date</label>
                                                <input type="date" id='lotsizeEndDate' value={lotsizePicked.enddate} name='enddate' onChange={lotsizeChange}/>
                                            </div>
                                        </div>
                                        <div className="btns">
                                            <button type='submit' className='savebtn'>Save</button>
                                            <p  onClick={() => setopenBox(false)}>Back to Daily Lot List</p>
                                        </div>
                                    </form>}
                                </div>
                                 : <div className="body">
                                <p className='create' onClick={() => createLotSize()}>Create Daily Parking Lot Size</p>
                                <div className="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Lot Size</th>
                                                    <th>Description</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    lotSizeTable?.map((item, i) => { return  (
                                                <tr key={i}>
                                                    <td>{item.lotsize}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.startdate}</td>
                                                    <td>{item.enddate}</td>
                                                    <td className='edit' onClick={() => editLotSize(item.id)}>Edit</td>
                                                </tr>
                                                    )})
                                                }
                                            </tbody>
                                        </table>
                                </div>
                                </div>}
                            </div>
                        }
                        {
                            itemClicked === 'holiday-rate' &&
                            <div className="main-form">
                                <div className="head">
                                    <h3>{editData.parkinglotname}  Holiday Rates</h3>
                                    <FontAwesomeIcon icon={faClose} className='head-icon' onClick={() => setOpenCreate(false)}/>
                                </div>
                                { openBox ?
                                <div className="hol-body">
                                    { (holidayPage === 'edit' || holidayPage === 'create') && <form className="create-lotSize">
                                    <h3>{holidayPage === 'edit' ? 'Edit' : 'Create'} Holiday Rate</h3>
                                    <div className="input">
                                        <label htmlFor="daily-rate">Daily Rate</label>
                                        <input type="number" id='daily-rate' value={holidayPicked.dailyrate} name='dailyrate' onChange={editholRateChange}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="weekly-rate">Weekly Rate</label>
                                        <input type="number" id='weekly-rate' value={holidayPicked.weeklyrate} name='weeklyrate' onChange={editholRateChange}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="monthly-holiday">Monthly Rate</label>
                                        <input type="number" id='monthly-holiday' value={holidayPicked.monthlyrate} name='monthlyrate' onChange={editholRateChange}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="chnageCorpRate">Change in Corp Rate</label>
                                        <input type="number" id='chnageCorpRate' value={holidayPicked.adjcorprate} name='adjcorprate' onChange={editholRateChange}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="holidaydescription">Description</label>
                                        <textarea id="holidaydescription" value={holidayPicked.description} name='description' onChange={editholRateChange}></textarea>
                                    </div>
                                    <div className="inputs">
                                        <div className="input">
                                            <label htmlFor="holidayRateStartDate">Start Date</label>
                                            <input type="date" id='holidayRateStartDate' value={ holidayPicked.startdate ? dateOnly(holidayPicked.startdate) : getTodayDate()  } name='startdate' onChange={editholRateChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="holidayRateEndDate">End Date</label>
                                            <input type="date" id='holidayRateEndDate' value={ holidayPicked.enddate ? dateOnly(holidayPicked.enddate) : getTodayDate()} name='enddate' onChange={editholRateChange}/>
                                        </div>
                                    </div>
                                    <div className="btns">
                                        <button className='savebtn' type='submit'>Save</button>
                                        <p  onClick={() => setopenBox(false)}>Back to Holiday Rates</p>
                                    </div>
                                    </form>}
                                </div>
                                  : <div className="body">
                                <p className='create' onClick={() => createholRate()}>Create Holiday Rates</p>
                                <div className="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Daily Rate</th>
                                                    <th>Monthly Rate</th>
                                                    <th>% Change in Corp Rate</th>
                                                    <th>Description</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    holiday.map((hol, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <td>${hol.dailyrate}</td>
                                                                <td>${hol.monthlyrate}</td>
                                                                <td>{hol.adjcorprate}</td>
                                                                <td>{hol.description}</td>
                                                                <td>{changeDate(hol.startdate)}</td>
                                                                <td>{changeDate(hol.enddate)}</td>
                                                                <td>
                                                                    <ul>
                                                                        <li onClick={() => editholRate(hol.recordid
                                                                            )}>Edit</li>
                                                                        <li className='delete' onClick={() => deleteHolidayRate(idx)}>Delete</li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                </div>
                                </div>}
                            </div>
                        }
                        {
                            itemClicked === 'season-rate' &&
                            <div className="main-form">
                                <div className="head">
                                    <h3>{editData.parkinglotname}  Season Rates</h3>
                                    <FontAwesomeIcon icon={faClose} className='head-icon' onClick={() => setOpenCreate(false)}/>
                                </div>
                                { openBox ? 
                                <div className="body">
                                    {
                                     (seasonRate === 'edit' || seasonRate === 'create') && <form className="create-lotSize">
                                        <h3>{seasonRate === 'edit' ? 'Edit' : 'Create'} Season Rate</h3>
                                        <div className="input">
                                            <label htmlFor="dailySeason">Daily Rate</label>
                                            <input type="number" id='dailySeason' value={seasonPicked.dailyrate} name='dailyrate' onChange={editseasonRateChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="weeklySeason">Weekly Rate</label>
                                            <input type="number" id='weeklySeason' value={seasonPicked.weeklyrate} name='weeklyrate' onChange={editseasonRateChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="monthly-season">Monthly Rate</label>
                                            <input type="number" id='monthly-season' value={seasonPicked.monthlyrate} name='monthlyrate' onChange={editseasonRateChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="chnageCorpRateseason">Change in Corp Rate</label>
                                            <input type="number" id='chnageCorpRateseason' value={seasonPicked.adjcorprate} name='adjcorprate' onChange={editseasonRateChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="seasondescription">Description</label>
                                            <textarea id="seasondescription" value={seasonPicked.description} name='description' onChange={editseasonRateChange}></textarea>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="seasonRateStartDate">Start Date</label>
                                                <input type="date" id='seasonRateStartDate' value={ seasonPicked.startdate ? dateOnly(seasonPicked.startdate) : getTodayDate()} name='startdate' onChange={editseasonRateChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="seasonRateEndDate">End Date</label>
                                                <input type="date" id='seasonRateEndDate' value={ seasonPicked.enddate? dateOnly(seasonPicked.enddate) : getTodayDate()} name='enddate' onChange={editseasonRateChange}/>
                                            </div>
                                        </div>
                                        <div className="btns"> 
                                            <button className='savebtn' type='submit'>Save</button>
                                            <p  onClick={() => setopenBox(false)}>Back to Season Rates</p>
                                        </div>
                                    </form> 
                                    }
                                </div>
                                  : <div className="body">
                                <p className='create' onClick={() => seasonRateCreate()}>Create Season Rates</p>
                                <div className="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Daily Rate</th>
                                                    <th>Monthly Rate</th>
                                                    <th>% Change in Corp Rate</th>
                                                    <th>Description</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    season.map((item, id) => { return (
                                                        <tr key={id}>
                                                            <td>{item.dailyrate}</td>
                                                            <td>{item.monthlyrate}</td>
                                                            <td>{item.adjcorprate}</td>
                                                            <td>{item.description}</td>
                                                            <td>{changeDate(item.startdate)}</td>
                                                            <td>{changeDate(item.enddate)}</td>
                                                            <td className='edit' onClick={() => seasonRateEdit(item.recordid)}>Edit</td>
                                                        </tr>
                                                    )})
                                                }
                                            </tbody>
                                        </table>
                                </div>
                                </div>}
                            </div>
                        }
                        {
                            itemClicked === 'blockout-dates' &&
                            <div className="main-form">
                                <div className="head">
                                    <h3>{editData.parkinglotname}  Blockout Dates</h3>
                                    <FontAwesomeIcon icon={faClose} className='head-icon' onClick={() => setOpenCreate(false)}/>
                                </div>
                                { openBox ? 
                                <div className="body">
                                    {
                                        (blockout === 'edit' || blockout === 'create') && <form className="create-lotSize" onSubmit={onBlockout}>
                                        <h3>{blockout === 'edit' ? 'Edit' : 'Create'} Blockout Date</h3>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="blockoutStart">Start Date</label>
                                                <input type="date" id='blockoutStart' value={blockPicked.startdate ? dateOnly(blockPicked.startdate) : getTodayDate()} name='startdate' onChange={editblockchange} required/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="blockoutEnd">End Date</label>
                                                <input type="date" id='blockoutEnd' value={blockPicked.enddate ? dateOnly(blockPicked.enddate) : getTodayDate()} name='enddate' onChange={editblockchange} required/>
                                            </div>
                                        </div>
                                        <div className="btns">
                                            <button className='savebtn' type='submit'>Save</button>
                                            <p  onClick={() => setopenBox(false)}>Back to Blockout Dates</p>
                                        </div>
                                    </form>
                                    }
                                </div>
                                : <div className="body">
                                <p className='create' onClick={() => createBlockout()}>Create Blockout Dates</p>
                                <div className="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Last Updated</th>
                                                    <th>Updated By</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    blockoutDates?.map((item, id) => {return ( item!=null &&
                                                <tr key={id}>
                                                    <td>{changeDate(item.startdate)}</td>
                                                    <td>{changeDate(item.enddate)}</td>
                                                    <td>{changeDate(item.updatedate)}</td>
                                                    <td>{item.updatedby}</td>
                                                    <td className='edit' onClick={() => editBlockout(item.recordid)}>Delete</td>
                                                </tr>
                                                    )})
                                                }
                                            </tbody>
                                        </table>
                                </div>
                                </div>}
                            </div>
                        }
                        {
                            itemClicked === 'other-taxes' &&
                            <div className="main-form">
                                <div className="head">
                                    <h3>{editData.parkinglotname}  Other Taxes</h3>
                                    <FontAwesomeIcon icon={faClose} className='head-icon' onClick={() => setOpenCreate(false)}/>
                                </div>
                                { openBox ? 
                                <div className="body">
                                    {
                                        (otherTaxes === 'edit' || otherTaxes === 'create') && <form className="create-lotSize" onSubmit={updateTaxSubmit}>
                                        <h3>{otherTaxes === 'edit' ? 'Edit' : 'Create'} Tax</h3>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="taxName">Tax Name</label>
                                                <input type="text" id='taxName' value={otherTaxPicked.taxname} name='taxname' onChange={edittaxchange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="taxValue">Tax Value</label>
                                                <input type="number" id='taxValue' value={otherTaxPicked.taxvalue} name='taxvalue' onChange={edittaxchange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="taxUnit">Tax Unit</label>
                                                <select id="taxUnit" value={otherTaxPicked?.taxtype} name='taxtype' onChange={edittaxchange}>
                                                    <option value="" disabled>Select Unit</option>
                                                    <option value="DOLLAR">$</option>
                                                    <option value="PERCENT">%</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="taxBasis">Tax Basis</label>
                                                <select id="taxBasis" value={otherTaxPicked?.taxbasis} name='taxbasis' onChange={edittaxchange}>
                                                    <option value="" disabled>Select Basis</option>
                                                    <option value="Per Day">Per Day</option>
                                                    <option value="Per Reservation">Per Reservation</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="taxStatus">Tax Status</label>
                                                <select id="taxStatus" value={otherTaxPicked?.taxstatus} name='taxstatus' onChange={edittaxchange}>
                                                    <option value="" disabled>Select Tax Status</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="taxFreeDays">Tax Free Days</label>
                                                <select id="taxFreeDays" value={otherTaxPicked?.taxfreedays} name='taxfreedays' onChange={edittaxchange}>
                                                    <option value="" disabled></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="btns">
                                            <button type='submit' className='savebtn'>Save</button>
                                            <p  onClick={() => setopenBox(false)}>Back to Other Taxes</p>
                                        </div>
                                        </form>
                                    }
                                </div> : <div className="body">
                                <p className='create' onClick={() => createTax()}>Create New Tax</p>
                                <div className="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Tax Name</th>
                                                    <th>Tax Amount</th>
                                                    <th>Tax Type</th>
                                                    <th>Tax Basis</th>
                                                    <th>Tax Free Days</th>
                                                    <th>Create Date</th>
                                                    <th>Last Modified Date</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    otherTaxBox?.map((item, idx) => { return (
                                                    <tr key={idx}>
                                                        <td>{item.taxname}</td>
                                                        <td>${item.taxvalue}</td>
                                                        <td>{item.taxtype}</td>
                                                        <td>{item.taxbasis}</td>
                                                        <td>{item.taxfreedays}</td>
                                                        <td>{changeDate(item.createdate)}</td>
                                                        <td>{changeDate(item.updatedate)}</td>
                                                        <td>{item.taxstatus}</td>
                                                        <td className='edit' onClick={() => editTax(item.taxid)}>Edit</td>
                                                    </tr>
                                                    )})
                                                }
                                            </tbody>
                                        </table>
                                </div>
                                </div>}
                            </div> 
                        }
                    </div>}  
                  
                </div>
            </div>
          {isDialogBoxOpen && <div className={  "delete-wrapper show"}>
                <div className="dialog">
                <div className="head">
                    <h4>Delete Parking Lot</h4>
                    <FontAwesomeIcon icon={faClose} onClick={() => closeDialogBox()}/>
                </div>
                <div className="main">
                    <h3>Are you sure you want to delete Parking Lot: { activelot.parkinglotname }?</h3>
                    <p>This action will permanently delete this Parking Lot. Please click 'Delete Parking Lot' to continue or Cancel to abort!</p>
                    <div className="btns">
                    <button onClick={() => deleteIt(activelot.parkinglotid)}>Delete Parking Lot</button>
                    <p onClick={() => closeDialogBox()}>Cancel</p>
                    </div>
                    </div>
                </div>
            </div>}  
             
        </div>
            {alert.open && <div className={ "congrats show" }>
                            <ApiAlert {...alert} onOk={onAlertOk}/>
                        </div> }
            {loading &&  <div className={ "congrats show" }>
                            <Loading />
                        </div>}  
            { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}            
    </div>
  )
}

export default ParkingLot
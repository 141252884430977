import React from 'react'
/* import LoadingImg from '../assets/loading.gif'; */
import '../styles/loading.css'
import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

function Expired({mode}) {
  //mode =admin/customer
  const navpath= mode =='admin'?'/admin': '/reservation/login';
  // const navigate = useNavigate()
  // navigate(navpath);
  return (
    <div className='load-container'>
        <h2>Expired Session</h2>
        <h2 className='small-head'>The Session has expired. Please login again</h2>
       {/*  <img src={LoadingImg} width='200px' alt="Loading" /> */}
       <Link to={navpath} > Re-Login</Link>
       

    </div>
  )
}

export default Expired
export const timeArray = [
    {
        time: '12:00 AM',
        value: '0:00'
    },
    {
        time: '12:15 AM',
        value: '0:15'
    },
    {
        time: '12:30 AM',
        value: '0:30'
    },
    {
        time: '12:45 AM',
        value: '0:45'
    },
    {
        time: '1:00 AM',
        value: '1:00'
    },
    {
        time: '1:15 AM',
        value: '1:15'
    },
    {
        time: '1:30 AM',
        value: '1:30'
    },
    {
        time: '1:45 AM',
        value: '1:45'
    },
    {
        time: '2:00 AM',
        value: '2:00'
    },
    {
        time: '2:15 AM',
        value: '2:15'
    },
    {
        time: '2:30 AM',
        value: '2:30'
    },
    {
        time: '2:45 AM',
        value: '2:45'
    },
    {
        time: '3:00 AM',
        value: '3:00'
    },
    {
        time: '3:15 AM',
        value: '3:15'
    },
    {
        time: '3:30 AM',
        value: '3:30'
    },
    {
        time: '3:45 AM',
        value: '3:45'
    },
    {
        time: '4:00 AM',
        value: '4:00'
    },
    {
        time: '4:15 AM',
        value: '4:15'
    },
    {
        time: '4:30 AM',
        value: '4:30'
    },
    {
        time: '4:45 AM',
        value: '4:45'
    },
    {
        time: '5:00 AM',
        value: '5:00'
    },
    {
        time: '5:15 AM',
        value: '5:15'
    },
    {
        time: '5:30 AM',
        value: '5:30'
    },
    {
        time: '5:45 AM',
        value: '5:45'
    },
    {
        time: '6:00 AM',
        value: '6:00'
    },
    {
        time: '6:15 AM',
        value: '6:15'
    },
    {
        time: '6:30 AM',
        value: '6:30'
    },
    {
        time: '6:45 AM',
        value: '6:45'
    },
    {
        time: '7:00 AM',
        value: '7:00'
    },
    {
        time: '7:15 AM',
        value: '7:15'
    },
    {
        time: '7:30 AM',
        value: '7:30'
    },
    {
        time: '7:45 AM',
        value: '7:45'
    },
    {
        time: '8:00 AM',
        value: '8:00'
    },
    {
        time: '8:15 AM',
        value: '8:15'
    },
    {
        time: '8:30 AM',
        value: '8:30'
    },
    {
        time: '8:45 AM',
        value: '8:45'
    },
    {
        time: '9:00 AM',
        value: '9:00'
    },
    {
        time: '9:15 AM',
        value: '9:15'
    },
    {
        time: '9:30 AM',
        value: '9:30'
    },
    {
        time: '9:45 AM',
        value: '9:45'
    },
    {
        time: '10:00 AM',
        value: '10:00'
    },
    {
        time: '10:15 AM',
        value: '10:15'
    },
    {
        time: '10:30 AM',
        value: '10:30'
    },
    {
        time: '10:45 AM',
        value: '10:45'
    },
    {
        time: '11:00 AM',
        value: '11:00'
    },
    {
        time: '11:15 AM',
        value: '11:15'
    },
    {
        time: '11:30 AM',
        value: '11:30'
    },
    {
        time: '11:45 AM',
        value: '11:45'
    },
    {
        time: '12:00 PM',
        value: '12:00'
    },
    {
        time: '12:15 PM',
        value: '12:15'
    },
    {
        time: '12:30 PM',
        value: '12:30'
    },
    {
        time: '12:45 PM',
        value: '12:45'
    },
    {
        time: '1:00 PM',
        value: '13:00'
    },
    {
        time: '1:15 PM',
        value: '13:15'
    },
    {
        time: '1:30 PM',
        value: '13:30'
    },
    {
        time: '1:45 PM',
        value: '13:45'
    },
    {
        time: '2:00 PM',
        value: '14:00'
    },
    {
        time: '2:15 PM',
        value: '14:15'
    },
    {
        time: '2:30 PM',
        value: '14:30'
    },
    {
        time: '2:45 PM',
        value: '14:45'
    },
    {
        time: '3:00 PM',
        value: '15:00'
    },
    {
        time: '3:15 PM',
        value: '15:15'
    },
    {
        time: '3:30 PM',
        value: '15:30'
    },
    {
        time: '3:45 PM',
        value: '15:45'
    },
    {
        time: '4:00 PM',
        value: '16:00'
    },
    {
        time: '4:15 PM',
        value: '16:15'
    },
    {
        time: '4:30 PM',
        value: '16:30'
    },
    {
        time: '4:45 PM',
        value: '16:45'
    },
    {
        time: '5:00 PM',
        value: '17:00'
    },
    {
        time: '5:15 PM',
        value: '17:15'
    },
    {
        time: '5:30 PM',
        value: '17:30'
    },
    {
        time: '5:45 PM',
        value: '17:45'
    },
    {
        time: '6:00 PM',
        value: '18:00'
    },
    {
        time: '6:15 PM',
        value: '18:15'
    },
    {
        time: '6:30 PM',
        value: '18:30'
    },
    {
        time: '6:45 PM',
        value: '18:45'
    },
    {
        time: '7:00 PM',
        value: '19:00'
    },
    {
        time: '7:15 PM',
        value: '19:15'
    },
    {
        time: '7:30 PM',
        value: '19:30'
    },
    {
        time: '7:45 PM',
        value: '19:45'
    },
    {
        time: '8:00 PM',
        value: '20:00'
    },
    {
        time: '8:15 PM',
        value: '20:15'
    },
    {
        time: '8:30 PM',
        value: '20:30'
    },
    {
        time: '8:45 PM',
        value: '20:45'
    },
    {
        time: '9:00 PM',
        value: '21:00'
    },
    {
        time: '9:15 PM',
        value: '21:15'
    },
    {
        time: '9:30 PM',
        value: '21:30'
    },
    {
        time: '9:45 PM',
        value: '21:45'
    },
    {
        time: '10:00 PM',
        value: '22:00'
    },
    {
        time: '10:15 PM',
        value: '22:15'
    },
    {
        time: '10:30 PM',
        value: '22:30'
    },
    {
        time: '10:45 PM',
        value: '22:45'
    },
    {
        time: '11:00 PM',
        value: '23:00'
    },
    {
        time: '11:15 PM',
        value: '23:15'
    },
    {
        time: '11:30 PM',
        value: '23:30'
    },
    {
        time: '11:45 PM',
        value: '23:45'
    },
]
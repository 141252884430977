import { faAngleLeft, faChartColumn, faFileZipper, faFolderOpen, faHouse, faPassport, faPeopleGroup, faPeopleRoof, faPlaneDeparture, faSquareParking, faSuitcase, faTags, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useContext, useEffect } from 'react'
import { ShareContext } from '../../context/ShareContext';
import '../../styles/Admin/sidebar.css'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Sidebar() {
    const [close, setClose] = useState(false)

    const { adminUserDetails, setIsSidebarClosed } = useContext(ShareContext);

    const userDetails = adminUserDetails;

    const path = useLocation();

    const reportsPath = [ '/admin/reports' , '/admin/reports/submitted-tickets', '/admin/reports/reservations-internal', '/admin/reports/reservations-external', '/admin/reports/hotel-report', '/admin/reports/arrival-departure-report', '/admin/reports/other-taxes-report', '/admin/reports/travel-agency-commission', '/admin/reports/reservation-refund', '/admin/reports/current-inventory' ]

    useEffect(() => {
        setIsSidebarClosed(close)
    },[close])

  return (
    <div className={ close ? 'sidebar close' : 'sidebar'}>
        <div className='sidebar-main'>
            <div className='user'>
                <FontAwesomeIcon icon={faUser} className='user-icon' />
                <h2>{userDetails?.firstname} <br /> <span>{userDetails?.userid}</span></h2>
            </div>
            <nav className='admin-sidebar'>
                <ul>
                    <li className={ path.pathname === '/admin/users' ? 'active' : ''}>
                        <Link to='/admin/users'>
                            <div className='link'>
                                <FontAwesomeIcon icon={faPeopleGroup} />
                                <p>Users</p>
                            </div>
                            <div className='lineSide'></div>
                        </Link>
                    </li>
                    <li className={ path.pathname === '/admin/manage-client' ? 'active' : ''}>
                        <Link to='/admin/manage-client'>
                            <div className='link'>
                                <FontAwesomeIcon icon={faPeopleRoof} />
                                <p>Manage Client</p>
                            </div>
                            <div className='lineSide'></div>
                        </Link>
                    </li>
                    <li className={ path.pathname === '/admin/parking-lot' ? 'active' : ''}>
                        <Link to='/admin/parking-lot'>
                            <div className='link'>
                                <FontAwesomeIcon icon={faSquareParking} />
                                <p>Parking Lots</p>
                            </div>
                            <div className='lineSide'></div>
                        </Link>
                    </li>
                    <li className={ path.pathname === '/admin/reservations' ? 'active' : ''}>
                        <Link to='/admin/reservations'>
                            <div className='link'>
                                <FontAwesomeIcon icon={faFileZipper} />
                                <p>Reservations</p>
                            </div>
                            <div className='lineSide'></div>
                        </Link>
                    </li>
                    <li className={ path.pathname === '/admin/manage-airport' ? 'active' : ''}>
                        <Link to='/admin/manage-airport'>
                            <div className='link'>
                                <FontAwesomeIcon icon={faPlaneDeparture} />
                                <p>Manage Airport</p>
                            </div>
                            <div className='lineSide'></div>
                        </Link>
                    </li>
                    <li className={ path.pathname === '/admin/corporate' ? 'active' : ''}>
                        <Link to='/admin/corporate'>
                            <div className='link'>
                                <FontAwesomeIcon icon={faSuitcase} />
                                <p>Corporate</p>
                            </div>
                            <div className='lineSide'></div>
                        </Link>
                    </li>
                    <li className={ path.pathname === '/admin/travel-agency' ? 'active' : ''}>
                        <Link to='/admin/travel-agency'>
                            <div className='link'>
                                <FontAwesomeIcon icon={faPassport} />
                                <p>Travel Agency</p>
                            </div>
                            <div className='lineSide'></div>
                        </Link>
                    </li>
                    <li className={ path.pathname === '/admin/manage-discount' ? 'active' : ''}>
                        <Link to='/admin/manage-discount'>
                            <div className='link'>
                                <FontAwesomeIcon icon={faTags} />
                                <p>Manage Discount</p>
                            </div>
                            <div className='lineSide'></div>
                        </Link>
                    </li>
                    <li className={ reportsPath.includes(path.pathname) ? 'active' : ''}>
                        <Link to='/admin/reports'>
                            <div className='link'>
                                <FontAwesomeIcon icon={faFolderOpen} />
                                <p>Reports</p>
                            </div>
                            <div className='lineSide'></div>
                        </Link>
                    </li>
                    <li className={ path.pathname === '/admin/lot-performance' ? 'active' : ''}>
                        <Link to='/admin/lot-performance'>
                            <div className='link'>
                                <FontAwesomeIcon icon={faChartColumn} />
                                <p>Lot Performance</p>
                            </div>
                            <div className='lineSide'></div>
                        </Link>
                    </li>
                    <li className={ path.pathname === '/admin/cms/specialplaces' ? 'active' : ''}>
                        <Link to='/admin/cms/specialplaces'>
                            <div className='link'>
                                <FontAwesomeIcon icon={faHouse} />
                                <p>Special Places</p>
                            </div>
                            <div className='lineSide'></div>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
        <div className='expand' onClick={() => setClose(!close)}>
            <FontAwesomeIcon icon={faAngleLeft} />
        </div> 
    </div>
  )
}

export default Sidebar
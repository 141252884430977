import React, { useContext, useState, useEffect } from 'react'
import '../../styles/Admin/othertaxes.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { ShareContext } from '../../context/ShareContext'
import * as Constants from '../../constants';

function InventoryReport() {
    const { isSidebarClosed, adminReport,reportForm } = useContext(ShareContext)
    const [list, setList]=useState({});
    const [total, setTotal]=useState([]);

    const report=adminReport.reservations;

    const onRearrange=()=>{
        console.log('data rearrangement');
        var _list={};
        var fortotal=[];
    for (const re of report){
            var id=Constants.removeSpace(re.parkinglotid);
        var pak=_list[id];
       // var totalamt=re.payatlot>0? (re.totalamount - (re.servicefee   +re.tax + re.totalothertax + re.refundamt + re.payatlot)):(re.totalamount - (re.servicefee + re.accessfee + re.tax + re.totalothertax + re.refundamt + re.payatlot));
       var totalamt= (re.totalamount - (re.servicefee   +re.tax + re.totalothertax + re.refundamt + re.payatlot));
       var totalamtstr=totalamt+ (re.refundamt >0 ? ` Refund ${re.refundamt}`:'');
        if(pak==null){
            _list[id]={res:[{...re,totalamt,totalamtstr}]}; //adding first item
            
        }else{
            var pak=_list[id];
            pak.res=[...pak.res,{...re,totalamt,totalamtstr}];
            _list[id]={...pak};
        }

        fortotal.push({...re,totalamt,totalamtstr}) ;

        }
        console.log(_list) ;
        setList(_list);
        console.log('total list items')
        console.log(fortotal);
        setTotal(fortotal);
        //calculate total

    }
   
    useEffect(() => {
     onRearrange();
      }, [])
    


  return (
    <div className='admin-body'>
    <Sidebar />
    <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
        <Head />
         <div className="report-body">
                 <div className="head">
                    <h2>RESERVATIONS - INVENTORY</h2>
                    
                </div>
                 {total?.length>0 && Object.values(list) .map((li, id) => {return ( <> 
                <div className="head">
                    
                    <h3>{li.res[0].parkinglotname} {`Inventory Report for ${li.res[0].parkinglotname} ${li.res[0].lotsize} Capacity`}</h3>
                </div>
                <div className="table-wrapper">
                    <table className='full-table'>
                        <thead>
                            <tr>
                           
                            <th>Inventory Date</th>
                                               
                            <th>Inventory </th>
                            </tr>
                        </thead>
                        <tbody>
                        { li.res.map((item, idx) => {return ( 
                           <tr className='record' key={idx}>
                       
                                <td>{Constants.formatDateTime(item.reservationdates)} </td>
                                <td>{item.reservations}</td>
                               
                            </tr> )}) }
                        </tbody>
                    </table>
                </div>
                <div className="table-wrapper">
                    <table className='total-table'>
                        <tbody>
                            <tr className='total'>
                                <td colSpan={2}>Record Count: <span>TOTAL</span></td>
                                
                      
                                <td>Total Others: <span>{(li.res.reduce((sum, item) => sum + item.reservations, 0)) }</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </> )}) }
                <div className="table-wrapper">
                    <table className='total-table'>
                        <tbody>
                            <tr className='total'>
                                <td colSpan={5}>TOTAL: <span></span></td>
                                
                           
                                <td>Inventory: <span>{(total.reduce((sum, item) => sum + item.reservations, 0)) }</span></td>
                             
                            </tr>
                        </tbody>
                    </table>
                </div>


                 

            </div> 

    
    </div>
</div>
  )
}

export default InventoryReport
import React, { useContext,useEffect,useState } from 'react'
import '../../styles/Admin/allreports.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { ShareContext } from '../../context/ShareContext'
import * as Constants from '../../constants';

function ArrivalDeparture() {
    const { isSidebarClosed, adminReport,reportForm } = useContext(ShareContext)
    const [list, setList]=useState({});
    const [total, setTotal]=useState([]);

    const [list2, setList2]=useState({});
    const [total2, setTotal2]=useState([]);

    const report=adminReport.reservations;
    const report2= adminReport.reservations2;

    const onRearrange=()=>{
        console.log('data rearrangement');
        var _list={};
        var fortotal=[];
    for (const re of report){
            var id=Constants.removeSpace(re.parkinglotid);
        var pak=_list[id];
       // var totalamt=re.payatlot>0? (re.totalamount - (re.servicefee   +re.tax + re.totalothertax + re.refundamt + re.payatlot)):(re.totalamount - (re.servicefee + re.accessfee + re.tax + re.totalothertax + re.refundamt + re.payatlot));
       var totalamt= (re.totalamount - (re.servicefee   +re.tax + re.totalothertax + re.refundamt + re.payatlot));
       var totalamtstr=totalamt+ (re.refundamt >0 ? ` Refund ${re.refundamt}`:'');
        if(pak==null){
            _list[id]={res:[{...re,totalamt,totalamtstr}]}; //adding first item
            
        }else{
            var pak=_list[id];
            pak.res=[...pak.res,{...re,totalamt,totalamtstr}];
            _list[id]={...pak};
        }

        fortotal.push({...re,totalamt,totalamtstr}) ;

        }
        console.log(_list) ;
        setList(_list);
        console.log('total list items')
        console.log(fortotal);
        setTotal(fortotal);
        //calculate total

    }
    const onRearrange2=()=>{
        console.log('data rearrangement');
        var _list={};
        var fortotal=[];
    for (const re of report2){
            var id=Constants.removeSpace(re.parkinglotid);
        var pak=_list[id];
       // var totalamt=re.payatlot>0? (re.totalamount - (re.servicefee   +re.tax + re.totalothertax + re.refundamt + re.payatlot)):(re.totalamount - (re.servicefee + re.accessfee + re.tax + re.totalothertax + re.refundamt + re.payatlot));
       var totalamt= (re.totalamount - (re.servicefee   +re.tax + re.totalothertax + re.refundamt + re.payatlot));
       var totalamtstr=totalamt+ (re.refundamt >0 ? ` Refund ${re.refundamt}`:'');
        if(pak==null){
            _list[id]={res:[{...re,totalamt,totalamtstr}]}; //adding first item
            
        }else{
            var pak=_list[id];
            pak.res=[...pak.res,{...re,totalamt,totalamtstr}];
            _list[id]={...pak};
        }

        fortotal.push({...re,totalamt,totalamtstr}) ;

        }
        console.log(_list) ;
        setList2(_list);
        console.log('total list items')
        console.log(fortotal);
        setTotal2(fortotal);
        //calculate total

    }
   
    useEffect(() => {
     onRearrange();
     onRearrange2();
      }, [])
  return (
    <div className='admin-body'>
            <Sidebar />
            <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
                <Head />

            {reportForm.reportType=='details' && <div>
                    <div className="report-body">
                     <div className="head">
                        <h2>RESERVATIONS - ARRIVAL REPORT</h2>
                        
                    </div>
                     {total?.length>0 && Object.values(list) .map((li, id) => {return ( <> 
                    <div className="head">
                        
                        <h3>{li.res[0].parkinglotname}</h3>
                    </div>
                    <div className="table-wrapper">
                        <table className='full-table'>
                            <thead>
                                <tr>
                                <th>ID</th>
                                <th>Arrival Date</th>
                                <th>Name</th>
                                <th>Date Range</th>
                                <th>Duration</th>
                                <th> Total</th> 
                                <th>Access Fee</th>
                                <th>Tax</th> 
                                <th>Others</th>                      
                                <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            { li.res.map((item, idx) => {return ( 
                               <tr className='record' key={idx}>
                                    <td><strong>{item.transactionid} </strong><br /> {item.carCount} cars(s)</td>
                                    <td>{Constants.formatDateTime(item.arrivaldate)}  </td>
                                    <td>{item.firstname}  {item.lastname}  <br />{item.numofpersons } persons <br/> {item.vesselname}</td>
                                    <td>{Constants.formatDateTime(item.arrivaldate)} to {Constants.formatDateTime(item.returndate)}</td>
                                    <td>{Constants.duration(item.arrivaldate,item.returndate)}</td>
                                    <td>{item.totalamount} {item.refundamt> 0 && `Refunded ${item.refundamt}`}</td>
                                    <td>{item.accessfee}</td>
                                    <td>{item.tax}</td>
                                    <td>{item.totalothertax}</td>
                                    <td>{item.reservationstatus}</td>
                                   
                                </tr> )}) }
                            </tbody>
                        </table>
                    </div>
                    <div className="table-wrapper">
                        <table className='total-table'>
                            <tbody>
                                <tr className='total'>
                                    <td colSpan={5}>Record Count: <span>{li.res.length} | Cars {li.res.reduce((sum, item) => sum + item.carCount, 0)} | Persons {li.res.reduce((sum, item) => sum + item.numofpersons, 0)}</span></td>
                                    <td>Total: <span>{ Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.totalamt, 0))  } 
                                            {li.res.reduce((sum, item) => sum + item.refundamt, 0)>0?
                                             ` Refund: ${Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.refundamt, 0))}`:''}</span></td>
                                    <td>Total Access Fee: <span>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.accessfee, 0))}</span></td>
                                    <td>Total Tax: <span>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.tax, 0)) }</span></td>
                                    <td>Total Others: <span>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.totalothertax, 0)) }</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </> )}) }
                    <div className="table-wrapper">
                        <table className='total-table'>
                            <tbody>
                                <tr className='total'>
                                    <td colSpan={5}>Record Count: <span>{total.length} | Cars {total.reduce((sum, item) => sum + item.carCount, 0)} | Persons {total.reduce((sum, item) => sum + item.numofpersons, 0)}</span></td>
                                    <td>Total: <span>{ Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalamt, 0))  } 
                                            {total.reduce((sum, item) => sum + item.refundamt, 0)>0?
                                             ` Refund: ${Constants.formatCurrency(total.reduce((sum, item) => sum + item.refundamt, 0))}`:''}</span></td>
                                    <td>Total Access Fee: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.accessfee, 0))}</span></td>
                                    <td>Total Tax: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.tax, 0)) }</span></td>
                                    <td>Total Others: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalothertax, 0)) }</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                     

                </div> 
             


                <div className="report-body">
                    <div className="head">
                        <h2>RESERVATIONS - DEPARTURE REPORT</h2>
                     
                    </div>
                    {total2?.length>0 && Object.values(list2) .map((li, id) => {return ( <> 
                    <div className="head">
                        
                        <h3>{li.res[0].parkinglotname}</h3>
                    </div>
                    <div className="table-wrapper">
                        <table className='full-table'>
                            <thead>
                                <tr>
                                <th>ID</th>
                                <th>Departure Date</th>
                                <th>Name</th>
                                <th>Date Range</th>
                                <th>Duration</th>
                                <th> Total</th> 
                                <th>Access Fee</th>
                                <th>Tax</th> 
                                <th>Others</th>                      
                                <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            { li.res.map((item, idx) => {return ( 
                               <tr className='record' key={idx}>
                                    <td><strong>{item.transactionid} </strong><br /> {item.carCount} cars(s)</td>
                                    <td>{item.returndate}  </td>
                                    <td>{item.firstname}  {item.lastname}  <br />{item.numofpersons } persons <br/> {item.vesselname}</td>
                                    <td>{Constants.formatDateTime(item.arrivaldate)} to {Constants.formatDateTime(item.returndate)}</td>
                                    <td>{Constants.duration(item.arrivaldate,item.returndate)}</td>
                                    <td>{item.totalamount} {item.refundamt> 0 && `Refunded ${item.refundamt}`}</td>
                                    <td>{item.accessfee}</td>
                                    <td>{item.tax}</td>
                                    <td>{item.totalothertax}</td>
                                    <td>{item.reservationstatus}</td>
                                   
                                </tr> )}) }
                            </tbody>
                        </table>
                    </div>
                    <div className="table-wrapper">
                        <table className='total-table'>
                            <tbody>
                                <tr className='total'>
                                    <td colSpan={5}>Record Count: <span>{li.res.length} | Cars {li.res.reduce((sum, item) => sum + item.carCount, 0)} | Persons {li.res.reduce((sum, item) => sum + item.numofpersons, 0)}</span></td>
                                    <td>Total: <span>{ Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.totalamt, 0))  } 
                                            {li.res.reduce((sum, item) => sum + item.refundamt, 0)>0?
                                             ` Refund: ${Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.refundamt, 0))}`:''}</span></td>
                                    <td>Total Access Fee: <span>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.accessfee, 0))}</span></td>
                                    <td>Total Tax: <span>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.tax, 0)) }</span></td>
                                    <td>Total Others: <span>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.totalothertax, 0)) }</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </> )}) }
                    <div className="table-wrapper">
                        <table className='total-table'>
                            <tbody>
                                <tr className='total'>
                                    <td colSpan={5}>Record Count: <span>{total.length} | Cars {total.reduce((sum, item) => sum + item.carCount, 0)} | Persons {total.reduce((sum, item) => sum + item.numofpersons, 0)}</span></td>
                                    <td>Total: <span>{ Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalamt, 0))  } 
                                            {total.reduce((sum, item) => sum + item.refundamt, 0)>0?
                                             ` Refund: ${Constants.formatCurrency(total.reduce((sum, item) => sum + item.refundamt, 0))}`:''}</span></td>
                                    <td>Total Access Fee: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.accessfee, 0))}</span></td>
                                    <td>Total Tax: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.tax, 0)) }</span></td>
                                    <td>Total Others: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalothertax, 0)) }</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>}    
                {reportForm.reportType!='details' && 
                <div>
                    <div className="report-body">
                     <div className="head">
                        <h2>RESERVATIONS - ARRIVAL REPORT</h2>
                        
                    </div>
                     
                    <div className="table-wrapper">
                        <table className='full-table'>
                            <thead>
                                <tr>
                                <th colSpan={5}>Parking lot</th>
                               
                                <th> Total</th> 
                                <th>Access Fee</th>
                                <th>Tax</th> 
                                <th>Others</th>                      
                                <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            { total.map((item, idx) => {return ( 
                               <tr className='record' key={idx}>
                                    <td colSpan={5}><strong>{item.parkinglotname} </strong><br /> {item.carCount} cars(s)</td>
                                   
                                    <td>{item.totalamount} {item.refundamt> 0 && `Refunded ${item.refundamt}`}</td>
                                    <td>{item.accessfee}</td>
                                    <td>{item.tax}</td>
                                    <td>{item.totalothertax}</td>
                                    <td>{item.reservationstatus}</td>
                                   
                                </tr> )}) }
                            </tbody>
                        </table>
                    </div>
                    <div className="table-wrapper">
                        <table className='total-table'>
                            <tbody>
                                <tr className='total'>
                                    <td colSpan={5}>Record Count: <span>{total.length} | Cars {total.reduce((sum, item) => sum + item.carCount, 0)} | Persons {total.reduce((sum, item) => sum + item.numofpersons, 0)}</span></td>
                                    <td>Total: <span>{ Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalamt, 0))  } 
                                            {total.reduce((sum, item) => sum + item.refundamt, 0)>0?
                                             ` Refund: ${Constants.formatCurrency(total.reduce((sum, item) => sum + item.refundamt, 0))}`:''}</span></td>
                                    <td>Total Access Fee: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.accessfee, 0))}</span></td>
                                    <td>Total Tax: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.tax, 0)) }</span></td>
                                    <td>Total Others: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalothertax, 0)) }</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div> 
             


                <div className="report-body">
                     <div className="head">
                        <h2>RESERVATIONS - DEPARTURE REPORT</h2>
                        
                    </div>
                     
                    <div className="table-wrapper">
                        <table className='full-table'>
                            <thead>
                                <tr>
                                <th colSpan={5}>Parking lot</th>
                               
                                <th> Total</th> 
                                <th>Access Fee</th>
                                <th>Tax</th> 
                                <th>Others</th>                      
                                <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            { total2.map((item, idx) => {return ( 
                               <tr className='record' key={idx}>
                                    <td colSpan={5}><strong>{item.parkinglotname} </strong><br /> {item.carCount} cars(s)</td>
                                   
                                    <td>{item.totalamount} {item.refundamt> 0 && `Refunded ${item.refundamt}`}</td>
                                    <td>{item.accessfee}</td>
                                    <td>{item.tax}</td>
                                    <td>{item.totalothertax}</td>
                                    <td>{item.reservationstatus}</td>
                                   
                                </tr> )}) }
                            </tbody>
                        </table>
                    </div>
                    <div className="table-wrapper">
                        <table className='total-table'>
                            <tbody>
                                <tr className='total'>
                                    <td colSpan={5}>Record Count: <span>{total2.length} | Cars {total2.reduce((sum, item) => sum + item.carCount, 0)} | Persons {total2.reduce((sum, item) => sum + item.numofpersons, 0)}</span></td>
                                    <td>Total: <span>{ Constants.formatCurrency(total2.reduce((sum, item) => sum + item.totalamt, 0))  } 
                                            {total2.reduce((sum, item) => sum + item.refundamt, 0)>0?
                                             ` Refund: ${Constants.formatCurrency(total2.reduce((sum, item) => sum + item.refundamt, 0))}`:''}</span></td>
                                    <td>Total Access Fee: <span>{Constants.formatCurrency(total2.reduce((sum, item) => sum + item.accessfee, 0))}</span></td>
                                    <td>Total Tax: <span>{Constants.formatCurrency(total2.reduce((sum, item) => sum + item.tax, 0)) }</span></td>
                                    <td>Total Others: <span>{Constants.formatCurrency(total2.reduce((sum, item) => sum + item.totalothertax, 0)) }</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div> 
                </div>}          
            </div>
    </div>
  )
}

export default ArrivalDeparture
import React, {useEffect, useState, useContext} from 'react'
import '../../styles/User/contact.css'
import TopBar from '../../components/User/TopBar'
import { airports } from '../../data/airportFacilities'
import ContactImg from '../../assets/contact-img.png'
import ReactGA from 'react-ga';
import * as Constants from '../../constants';
import ApiAlert from '../../components/ApiAlert';
import { ShareContext } from '../../context/ShareContext';
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/Loading';

function Contact() {


    ReactGA.pageview(window.location.pathname + window.location.search);

    const navigate = useNavigate()

    const [contact, setContact]=useState({airport:'MSY'});
    const [alert, setAlert] =useState({open:false, title:'', subtitle:''})
    const [isExpired, setIsExpired] = useState(false);

    const {airportList, token} = useContext(ShareContext);

    const [isLoading, setIsLoading]= useState(false);

    const editChange = (e) => {
        setContact({...contact, [e.target.name]: e.target.value})
        console.log({[e.target.name]: e.target.value});
    }
    const onOk=()=>{
        setAlert({open:false})
        navigate('/reservation')
    }


    const submit = async(e) => {
        e.preventDefault();
    

        //var dd={reason}
      setIsLoading(true);
      //  console.log(JSON.stringify(dd));
        try {
            const resp = await fetch(`${Constants.apiUrl}/reservation/contact`, {
              method: 'POST',
              body: JSON.stringify(contact),
              headers: {
                'Content-Type': 'application/json',
                'Authentication': token
              },
            });
            
            const responseData = await resp.json();
            
            console.log(responseData);
           setIsLoading(false);
  
            if (responseData.code === 0) {
                //navigate('/reservation/search/'+item.reservationid)
                setAlert({open:true, title:'Contact', subtitle:"Contact information sent successfully", onOk:onOk})

            }else if (responseData.code === -10) {
                setIsExpired(true)
            }else {
              console.log(responseData.status);
            }
          } catch (error) {
            console.error(error);
            setIsLoading(false);
          }
    }
  return (
    <div className='contact'>
        <TopBar />
        <div className="contact-page">
            <div className="form-text">
                <div className="text">
                    <h1>Contact Us</h1>
                    <p>Your concerns are important to us, and we work hard to respond to all inquiries promptly. Please fill out the form below and we'll do our best to respond quickly. 
                        Thank you for visiting our New Orleans Parking website. * Indicates a required field. If your airport or category of concern is not listed in the menus below,
                         please simply select "other" from the bottom of the menu.</p>
                    <p className="note">You may also reach us via phone at <a href="tel:+18653667014">865.366.7014</a>  from 8:00 AM EST to 6:00 PM EST 7 Days a Week.</p>
                </div>
                <form onSubmit={submit}>
                   {/*  <div className="input">
                        <label htmlFor="dept-airport">Departure Airport</label>
                        <select name="airport" id="dept-airport" value={contact.airport} onChange={editChange}>
                            <option value="airport or seaport" disabled selected>Airport or Seaport</option>
                            { airportList.map((airport, idx) => {
                                return (
                                    <option key={idx} value={airport.airportcode}>{airport.airportlongname}</option>
                                )
                            })}
                        </select>
                    </div> */}
                    <div className="inputs">
                        <div className="input">
                            <label htmlFor="contact-name">Name</label>
                            <input type="text" name='clientname' id='contact-name' value={contact.clientname} onChange={editChange}/>
                        </div>
                        <div className="input">
                            <label htmlFor="contact-email">E-mail</label>
                            <input type="email" name='clientemail' id='contact-email' value={contact.clientemail} onChange={editChange}/>
                        </div>
                    </div>
                    <div className="input">
                        <label htmlFor="category-contact">Category</label>
                        <select name="category" id="category-contact" onChange={editChange}>
                            <option value="" selected disabled>--Select Category--</option>
                            <option value="Billing Inquiries">Billing Inquiries</option>
                            <option value="Cancellations">Cancellations</option>
                            <option value="Change Reseervation Dates">Change Reseervation Dates</option>
                            <option value="Directions">Directions</option>
                            <option value="General Information">General Information</option>
                            <option value="Investor Relations">Investor Relations</option>
                            <option value="Marketing and Advertising">Marketing and Advertising</option>
                            <option value="Press Inquiries">Press Inquiries</option>
                            <option value="Prices and Rates">Prices and Rates</option>
                            <option value="Receipt Copy">Receipt Copy</option>
                            <option value="Technical Issues">Technical Issues</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>
                    <div className="input">
                        <label htmlFor="reservationid">Reservation ID(<span>if applicable</span>)</label>
                        <input type="text"  name='ReservationID' onChange={editChange} />
                    </div>
                    <div className="textarea">
                        <label htmlFor="comment">Comments/Questions</label>
                        <textarea name="comment" onChange={editChange}></textarea>
                    </div>

                    <div className="textarea" style={{display:'none'}}>
                        <label htmlFor="csrcomment" onChange={editChange}>Comments/Questions</label>
                        <textarea name="csrcomment" ></textarea>
                    </div>

                    <button type='submit' className='submit-btn'>Send Message</button>
                </form>
            </div>
            <img src={ContactImg} alt="Contact svg"  loading='lazy'/>
            {alert.open && <div className={ "congrats show" }>
                <ApiAlert {...alert} />  
            </div> }
            {isLoading && <div className={ "congrats show" }>
                <Loading message='Processing' />
            </div>}  
        </div>
    </div>
  )
}

export default Contact
import React, { useContext, useState, useRef,useEffect } from 'react'
import '../../styles/User/checkout.css'
import { useParams } from 'react-router-dom'
import TopBar from '../../components/User/TopBar';
import { Link } from 'react-router-dom';
import Card from '../../assets/card.jpg';
import { ShareContext } from '../../context/ShareContext';
import { useNavigate } from 'react-router-dom';
import LoadingImg from '../../assets/loading.gif';
import * as Constants from '../../constants';
import moment from "moment";
import Expired from '../../components/Expired';
import Loading from '../../components/Loading';
import ReactGA from 'react-ga';
import ApiAlert from '../../components/ApiAlert';
function Confirm() {
    const navigate = useNavigate();
    const { formData,  confirmData} = useContext(ShareContext);
    const [transactionId, setTransactionId] = useState('')
   
    if(confirmData==null){
        navigate("/"); //on reload it will go home page
     }
    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search); 
        ReactGA.event({
            category:  'booking',
            action: 'Reservation created'
          });
          ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            id: confirmData.reservation.transactionid,
            revenue: confirmData?.reservation.totalpaid,
            category: 'booking',
            name: 'Reservation created'
          });
        setTransactionId(confirmData.reservation.transactionid);

       }, []);

    return (

      <div className='checkout'>
        <TopBar />

        <div className="body">
            <div style={{height:400}}></div>
            <div className={  "success show" }>
                    <div className="container">
                        <h2>Congratulations! Your payment was successful</h2>
                        <p className='main-note'>You can print your receipt now or head back to your reservations list. <br /> <span>Note: Your receipt is always available on your profile</span></p>
                        <div className="receipt-details">
                            <div className="barcode" style={{textAlign:'center'}}>
                                {confirmData?.parkinglot.generatebarcode == "Yes"  && 
                                <img src={"http://www.barcodesinc.com/generator/image.php?code="+confirmData?.reservation.barcodenum+"&style=196&type=C128C&width=150&height=80&xres=1&font=3"} />}
                              
                            
                            </div>
                            
                            <div className="row">
                                <p className="title">Reservation ID</p>
                                <p className="answer">AW{transactionId}</p>
                            </div>
                            <div className="row">
                                <p className="title">Customer Name</p>
                                <p className="answer">{ confirmData.customer.firstname + ' ' + confirmData.customer.lastname}</p>
                            </div>
                            <div className="row">
                                <p className="title">Lot</p>
                                <p className="answer">{confirmData?.parkinglot.parkinglotname}</p>
                            </div>
                            <div className="row">
                                <p className="title">Arrival</p>
                                <p className="answer">{Constants.formatDate_string(formData.startDate)}  {formData.startTime}</p>
                            </div>
                            <div className="row">
                                <p className="title">Departure</p>
                                <p className="answer">{Constants.formatDate_string(formData.endDate)}  {formData.endTime}</p>
                            </div>
                            <div className="row">
                                <p className="title">Paid Online</p>
                                <p className="answer">{Constants.formatCurrency(confirmData?.reservation.totalpaid)}</p>
                            </div>
                            <div className="row">
                                <p className="title">Total</p>
                                <p className="answer">{Constants.formatCurrency(confirmData?.reservation.totalamount)}</p>
                            </div>
                            <div className="row">
                                <p className="title">Person Parking</p>
                                <p className="answer">{confirmData?.reservation.parkingperson}</p>
                            </div>
                            <div className="row">
                                <p className="title">Number of Persons</p>
                                <p className="answer">{confirmData?.reservation.numofpersons}</p>
                            </div>
                        </div>
                        <div className="down">
                            <button onClick={() =>  window.print()}>Print</button>
                            <div className="btns">
                                <button onClick={() => navigate(`/reservation/full-receipt/${confirmData.reservation.reservationid}`)}>View Full Receipt</button>
                                <button onClick={() => navigate('/reservation')}>Back to Reservations</button>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
       </div>
      );
}
export default Confirm
import React, { useState, useContext, useEffect } from 'react'
import '../../styles/Admin/corporate.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faClose, faRotateRight, faSearch, faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter} from '@fortawesome/free-solid-svg-icons';
import { states } from '../../data/userstates'
import { ShareContext } from '../../context/ShareContext'
import Loading from '../../components/Loading'
import * as Constants from '../../constants';
import moment from "moment";
import Expired from '../../components/Expired'
function Corporate() {

    const { adminToken,isSidebarClosed } = useContext(ShareContext);

    const corporateData = [
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ATravel',
        //     corporateName: 'A Mason Destination',
        //     contactName: 'Ann Mason',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '2900 Neil Court Palm Harbour, FL 34684',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
        // {
        //     corporateCode: 234,
        //     parkingLot: 'ATLCrowne',
        //     dailyRate: 2,
        //     monthlyRate: 50,
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga debitis, cum harum ipsa ea quas necessitatibus impedit libero ipsam animi autem dolore est facilis expedita suscipit excepturi sit quibusdam ex!',
        //     startDate: '2024-02-25',
        //     endDate: '2024-03-10',
        //     corporateId: 'ALOT',
        //     corporateName: 'A Love Of Travel',
        //     contactName: 'Marci Zied/ Ernest Zied',
        //     corporateCity: '',
        //     state: 'Florida',
        //     corporateZip: '1009',
        //     address: '652 Laurel Crossing Canton, GA 30114',
        //     addressTwo: '',
        //     email: 'marci@aloveoftravel.com',
        //     phone: '770-345-8687',
        //     fax:'',
        //     updatedDate: 'September 18, 2016'
        // },
    ]
    const [openCreate, setOpenCreate] = useState(false);
    const [expandForm, setExpandForm] = useState(false);
    const [openedForm, setOpenedForm] = useState('create')
    const formObj = {
        corporateId: '',
        corporateName: '',
        contactName: '',
        corporateCity: '',
        state: '',
        corporateZip: '',
        address: '',
        addressTwo: '',
        email: '',
        phone: '',
        fax:'',
        updatedDate: '',
        parkingLot: '',
        dailyRate: '',
        monthlyRate: '',
        description: '',
        startDate: '',
        endDate: ''
};
    const [corporateForm, setCorporateForm] = useState(formObj)
    const [editData, setEditData] = useState({})
    const [searchValue, setSearchValue] = useState('');
    const [tableState, setTableState] =  useState(corporateData);
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false)
    const [corporates, setCorporates] = useState([]);
    const [filterData, setFilterData]=useState([]);
    const [isLoading, setIsLoading] =useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [discount, setDiscount] =useState({});
    const [discounts, setDiscounts] =useState([]);
    const [parkinglots, setParkinglots]=useState([]);


    const formChange = (e) => {
        setCorporateForm({...corporateForm, [e.target.name]: e.target.value})
    }

    const onParkinglots=()=>{
        
        fetch(`${Constants.apiUrl}/parkingadmin/parkinglots`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
          
          })
          .then((resp) => resp.json())
          .then((data) =>  {
            
            if(data.code==0){
                setParkinglots(data.lots)
            }else if (data.code==-10){
              setIsExpired(true);
          }
            // console.log(jresp);
            
          })
          .catch((error) => {
            
            console.log(error);
          })
      }

    const changeEdit = (e) => {
        setEditData({...editData, [e.target.name]: e.target.value})
    }
    const changeDiscount = (e) => {
        setDiscount({...discount, [e.target.name]: e.target.value})
    }

    const create = () => {
        setOpenCreate(true);
        setOpenedForm('create')
        setEditData({})
    }

    const retrieve = (id) => {
        const theData = tableState.find(item =>  item.corporateId === id)

        return theData;
    }

    const editClick = (acc) => {
        setOpenCreate(true);
        setOpenedForm('edit')

       // const idData = retrieve(id);

        setEditData(acc)
    }

    const addDiscount = (acc) => {
        setOpenCreate(true);
        setOpenedForm('add-discount')
        setDiscount({corporateid:acc.corporateid});
      //  const idData = retrieve(id);

       // setEditData(idData)
    }

    const viewDiscount = (acc) => {
        setOpenCreate(true);
        setOpenedForm('view-discount')
        onCorporateDiscount(acc.corporateid);
    }

    const searchBar = (e) => {
        setSearchValue(e.target.value)
        const value=e.target.value;
        if(value==''){
            setFilterData(corporates);
        }else{
           const filteredData_ = corporates?.filter(item => {
            const searchMatch = item.corporateid.toLowerCase().includes(value) || item.corporatename.toLowerCase().includes(value) 

            return searchMatch 
        })
        setFilterData(filteredData_); 
        }
        
        
    }

    const filteredData = tableState?.filter(item => {
        const searchMatch = item.corporateName.toLowerCase().includes(searchValue.toLowerCase());
        return searchMatch 
    })

    const closePopUp = () => {
        setOpenCreate(false)
        setCorporateForm(formObj) 
    }

    const openDeleteBox = (acc) => {
        setIsDialogBoxOpen(true);
    
      //  const data = retrieve(id)
    
       // setEditData(data)
    }

    const closeDialogBox = () => {
        setIsDialogBoxOpen(false);
    }

    const deleteIt = (acc) => {
        const updatedArray = corporates?.filter(obj => obj.corporateid !==acc.corporateid)
      
        setFilterData(updatedArray)
        setIsDialogBoxOpen(false)
      }
    
      useEffect(() => {
        //Runs only on the first render
       // var sToken=localStorage.getItem("accessToken");
       onCorporates();
       onParkinglots();
      }, []);
      const onCorporates=()=>{
        setIsLoading(true);
        fetch(`${Constants.apiUrl}/parkingadmin/corporates`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
          
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
            setIsLoading(false);
            if(jresp.code==0){
                // console.log(jresp);
                setCorporates(jresp.c);
            setFilterData(jresp.c);
            }else if (jresp.code==-10){
                setIsExpired(true);
            }else{
                alert(jresp.status)
            }
            
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          })
      }
      const onCorporateDiscount=(corporateid)=>{
        setIsLoading(true);
        fetch(`${Constants.apiUrl}/parkingadmin/${corporateid}/corporatediscounts`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
          
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
            setIsLoading(false);
            if(jresp.code==0){
                // console.log(jresp);
                setDiscounts(jresp.cds);
         
            }else if (jresp.code==-10){
                setIsExpired(true);
            }else{
                alert(jresp.status)
            }
            
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          })
      }

      const onSaveCorp = async(e) => {
        e.preventDefault();
 
        
        const rdata={...editData};
        const url=openedForm === 'edit' ?`${Constants.apiUrl}/parkingadmin/corporates/${editData.recordid}`:`${Constants.apiUrl}/parkingadmin/corporate`

        setOpenCreate(false);
        setIsLoading(true);
        console.log(JSON.stringify(rdata));
          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': adminToken
            },
            body: JSON.stringify(rdata)
          })
          .then((resp) => resp.json())
          .then((data) => {
            setIsLoading(false);
            if (data.code === 0) {
                //setAgencyOpen(false);
              alert('Congratulations, Corporate account successfully '+(!openedForm === 'create'?'created!':'updated!'))
            }else{
                alert(data.status);
                setOpenCreate(true);
            }
          })
    
      }
        const onSaveDiscount = async(e) => {
                e.preventDefault();
        
                
                const rdata={...discount};
                const url=`${Constants.apiUrl}/parkingadmin/corporatediscount`

                setOpenCreate(false);
                setIsLoading(true);
                console.log(JSON.stringify(rdata));
                fetch(url, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': adminToken
                    },
                    body: JSON.stringify(rdata)
                })
                .then((resp) => resp.json())
                .then((data) => {
                    setIsLoading(false);
                    if (data.code === 0) {
                        //setAgencyOpen(false);
                    alert('Congratulations, Corporate discount save successfully ')
                    }else{
                        alert(data.status);
                        setOpenCreate(true);
                    }
                })
            
            }

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            <div className="corporate">
                <div className="head">
                    <h1>Corporate</h1>
                    <p>See and add corporate accounts</p>
                </div>
                <div className="body-wrapper">
                    <div className="head-block">
                        <h3>Corporate Accounts</h3>
                        <div className="head-icons">    
                            <FontAwesomeIcon icon={faRotateRight} className='action-icon refresh'/>
                        </div>
                    </div>
                    <div className="adding-client">
                        <button onClick={() => create()}>Create New Account</button>
                        <div className="search-input">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="text" placeholder='Search Corporate Accounts' value={searchValue} onChange={searchBar}/>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Corporate ID</th>
                                    <th>Corporate Name</th>
                                    <th>Contact Name</th>
                                    <th>Address</th>
                                    <th>E-mail</th>
                                    <th>Phone</th>
                                    <th>Fax</th>
                                    <th>Updated Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filterData.map((acc, idx) => { return(
                                        <tr key={idx}>
                                            <td>{acc.corporateid}</td>
                                            <td>{acc.corporatename}</td>
                                            <td>{acc.contactname}</td>
                                            <td>{acc.corpaddress1}</td>
                                           <td>{acc.contactpersonemail}</td>
                                           <td>{acc.contactpersonphone}</td>
                                           <td>{acc.contactpersonfax}</td>
                                            <td>{Constants.formatDate(acc.updatedate) }</td>
                                            <td>
                                                <ul>
                                                    <li onClick={() => editClick(acc)}>Edit</li>
                                                    <li onClick={() => addDiscount(acc)}>Add Discounts</li>
                                                    <li onClick={() => viewDiscount(acc)}>View Discounts</li>
                                                    {/* <li className='delete' onClick={() => openDeleteBox(acc)}>Delete</li> */}
                                                </ul>
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </table>
                    </div>
                  {openCreate && <div className={ "create-wrapper active" }>
                        <div className="close-it" onClick={() => closePopUp()}>
                            <FontAwesomeIcon icon={faClose} />  
                        </div>
                        <div className={expandForm ? "create-account expanded": "create-account"}>
                            <div className="create-header">
                                <h3>{ openedForm === 'create' ? 'New Corporate Account' :
                                openedForm === 'edit' ? 'Edit Corporate Account'  : openedForm === 'add-discount' ? 'Create Corporate Discount' : openedForm === 'view-discount' ? 'Corporate Discounts' : 'New Corporate Account'}</h3>
                                <FontAwesomeIcon icon={expandForm ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} className='close-create' onClick={() => setExpandForm(!expandForm)}/>
                            </div>
                            {
                                (openedForm === 'edit' || openedForm === 'create') &&
                                <form method="post" onSubmit={onSaveCorp}>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="corporateid">Corporate ID</label>
                                        <input type="text" name='corporateid'  value={editData.corporateid} disabled={openedForm === 'edit'} onChange={changeEdit} required />
                                    </div>
                                    <div className="input">
                                        <label htmlFor="corporateName">Corporate Name</label>
                                        <input type="text" name='corporatename' id='corporatename' value={editData.corporatename} required onChange={changeEdit}/>
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="address">Address 1</label>
                                    <input type="text" name='corpaddress1' id='address' value={editData.corpaddress1} required onChange={changeEdit}/>
                                </div>
                                <div className="input">
                                    <label htmlFor="addressTwo">Address 2</label>
                                    <input type="text" name='corpaddress2'  value={editData.corpaddress2} onChange={changeEdit}/>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="corporateCity">City</label>
                                        <input type="text" name='corpcity'  value={editData.corpcity} onChange={changeEdit}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="corpstate">State</label>
                                        <select name="corpstate" id="corporateState" value={editData.corpstate} required onChange={changeEdit}>
                                            <option value="" disabled selected>Select State/Province</option>
                                            { states.map((state, idx) => <option key={idx} value={state.code}>{state.name}</option>) }
                                        </select>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="corpzip">ZIP</label>
                                        <input type="text" name='corpzip'  value={editData.corpzip} onChange={changeEdit}/>
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="contactName">Contact Name</label>
                                        <input type="text" name='contactpersonname' id='contactName' value={editData.contactpersonname} required onChange={changeEdit}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="contactEmail">Contact E-mail</label>
                                        <input type="text" name='contactpersonemail' id='contactEmail' value={editData.contactpersonemail} required onChange={changeEdit}/>
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="contactPhone">Contact Phone</label>
                                        <input type="tel" name='contactpersonphone' id='contactPhone' value={editData.contactpersonphone} onChange={changeEdit}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="contactFax">Contact Fax</label>
                                        <input type="text" name='contactpersonfax' id='contactFax' value={editData.contactpersonfax} onChange={changeEdit}/>
                                    </div>
                                </div>
                                <div className="btns">
                                    <button type='submit'>Save</button>
                                    <p onClick={()=> closePopUp()} className='goBack'>Back to Corporate</p>
                                </div>
                            </form> 
                            }
                            {
                                openedForm === 'add-discount' &&
                                <form method="post" onSubmit={onSaveDiscount}>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="corporateId">Corporate ID</label>
                                        <input type="text" name='corporateid' id='corporateId' value={discount.corporateid} disabled/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="parkingLot">Parking Lot</label>
                                        <select name="parkinglotid" onChange={changeDiscount} value={discount.parkinglotid}>
                                            <option value="all">All Parking Lots</option>
                                            {
                                            parkinglots?.map((lot, idx) => <option key={idx} value={lot.parkinglotid}>{lot.parkinglotname}</option>)
                                        }
                                        </select>
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="dailyRate">Daily Rate</label>
                                    <input type="number" name='dailyrate' id='dailyRate' value={discount.dailyrate} onChange={changeDiscount} required/>
                                    <p className="note">Entering 1 or greater value here will mean dollar amount. Fractional vallue means % amount (e.g. 0.9 means 90% of regular rate implying 10% discount)</p>
                                </div>
                                <div className="input">
                                    <label htmlFor="monthlyRate">Monthly Rate</label>
                                    <input type="number" name='monthlyrate'  value={discount.monthlyrate} onChange={changeDiscount} required/>
                                    <p className="note">Entering 1 or greater value here will mean dollar amount. Fractional vallue means % amount (e.g. 0.9 means 90% of regular rate implying 10% discount)</p>
                                </div>
                                <div className="input">
                                    <label htmlFor="description">Description</label>
                                    <input type="text" name='description'  value={discount.description} onChange={changeDiscount}/>
                                </div> 
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="startDate">Start Date</label>
                                        <input type="date" name='startDate'  value={discount.startdate} onChange={changeDiscount} required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="endDate">End Date</label>
                                        <input type="date" name='enddate'  value={editData.enddate} onChange={changeDiscount}/>
                                        <p className="note">Leave blank if no date</p>
                                    </div>
                                </div>   
                                <div className="btns">
                                    <button type='submit'>Save Corporate Discount</button>
                                    <p onClick={()=> closePopUp()} className='goBack'>Back to Corporate</p>
                                </div>
                            </form> 
                            }
                            {
                                openedForm === 'view-discount' &&
                                <div className="body">
                                    <div className="table-wrapper">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Corporate Code</th>
                                                <th>Parking Lot</th>
                                                <th>Daily Rate</th>
                                                <th>Monthly Rate</th>
                                                <th>Description</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Last Updated By</th>
                                                {/* <td></td> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                    discounts.map((acc, idx) => { return(
                                        <tr key={idx}>
                                           <td>{acc.corporateid}</td>
                                                <td>{acc.parkinglotid}</td>
                                                <td>{acc.dailyrate}</td>
                                                <td>{acc.monthlyrate}</td>
                                                <td>{acc.description}</td>
                                                <td>{Constants.formatDate(acc.startdate)}</td>
                                                <td>{Constants.formatDate(acc.enddate)}</td>
                                                <td>{acc.lastupdatedby }</td>
                                               {/*  <td><ul><li className='delete' onClick={() => openDeleteBox(acc)}>Delete</li></ul></td> */}
                                                
                                        </tr>
                                            )})
                                        }
                                          
                                        </tbody>
                                    </table>
                                </div>

                                    <p className="goBack" onClick={() => closePopUp()}>Back to Corporate</p>
                                </div>
                            }
                        </div>
                    </div>}  
                </div>
            </div>
            <div className={ isDialogBoxOpen ? "delete-wrapper show" : "delete-wrapper"}>
                <div className="dialog">
                    <div className="head">
                        <h4>Delete Corporate Account</h4>
                        <FontAwesomeIcon icon={faClose} onClick={() => closeDialogBox()}/>
                    </div>
                    <div className="main">
                        <h3>Are you sure you want to delete Corporate Account: { editData.corporatename }?</h3>
                        <p>This action will permanently delete this Corporate Account. Please click 'Delete Corporate Account' to continue or Cancel to abort!</p>
                        <div className="btns">
                            <button onClick={() => deleteIt(editData.corporateid)}>Delete Corporate Account</button>
                            <p onClick={() => closeDialogBox()}>Cancel</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Corporate
import React, { useState, useContext } from 'react'
import '../../styles/Admin/profile.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faUser } from '@fortawesome/free-solid-svg-icons'
import { states } from '../../data/userstates'
import { ShareContext } from '../../context/ShareContext';

function Profile() {
  const { adminUserDetails, isSidebarClosed } = useContext(ShareContext);

  const [passwordOrEdit, setPasswordOrEdit] = useState('')
  const [showPop, setShowPop] = useState(false)

  // console.log(adminUserDetails);
  
  const profile = {
    firstName: adminUserDetails.firstname,
    lastName: adminUserDetails.lastname,
    email: adminUserDetails.emailaddress,
    number: adminUserDetails.preferredphone,
    parkingLot: 'MIA Extended Stay America - Doral - 25th Street',
    userId: adminUserDetails.userid,
    password: adminUserDetails.userpwd,
    altPhone: adminUserDetails.alternatephone,
    ext: adminUserDetails.extension,
    address: adminUserDetails.address1,
    addressTwo: adminUserDetails.address2,
    city: adminUserDetails.city,
    state: adminUserDetails.state,
    zip: adminUserDetails.zip,
    accessLevels: adminUserDetails.accesslevel,
    status: adminUserDetails.userstatus
  }
  const [form, setForm] = useState(profile)
  const [newPassword, setnewPassword] = useState({
    oldPassword: '',
    recentPassword: '',
    retypePassword: '',
  })
  const [changeGood, setChangeGood] = useState('');
  const [whichInp, setWhichInp] = useState('')

  const formChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
  }

  const passChange = (e) => {
    setnewPassword({...newPassword, [e.target.name]: e.target.value})
  }

  const closePop = () => {
    setShowPop(false)
    setPasswordOrEdit('')
  }

  const showEdit = () => {
    setShowPop(true)
    setPasswordOrEdit('edit')
  }

  const passwordChange = () => {
    setShowPop(true)
    setPasswordOrEdit('password')
  }

  const changePassword = (e) => {
    e.preventDefault();

    if (newPassword.oldPassword !== form.password) {
      setWhichInp('old')
      setChangeGood('Old Password does not match')
    } else if (newPassword.recentPassword !== newPassword.retypePassword) {
      setWhichInp('new')
      setChangeGood('New password and the retyped new password do not match')
    } else if(newPassword.oldPassword === '' || newPassword.recentPassword === '' || newPassword.retypePassword === '') {
      setWhichInp('all')
      setChangeGood('Please enter all inputs')
    } else {
      setWhichInp('')
      setChangeGood('')
    }
  }
 
  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            <div className="profile">
                <h1>User Profile</h1>
                <div className="body">
                  <div className="header">
                    <div className="name">
                      <FontAwesomeIcon icon={faUser} />
                      <h2>{profile.firstName} <br /> {profile.lastName}</h2>
                    </div>
                    <div className="actions">
                      <button onClick={() => showEdit()}>Edit Profile</button>
                      <button onClick={() => passwordChange()}>Change Password</button>
                    </div>
                  </div>
                  <div className={showPop ? "adminUserDetails short": "details"}>
                    <div className="row">
                      <p className="title">First Name</p>
                      <p className="answer">{form.firstName}</p>
                    </div>
                    <div className="row">
                      <p className="title">Last Name</p>
                      <p className="answer">{form.lastName}</p>
                    </div>
                    <div className="row">
                      <p className="title">E-mail</p>
                      <p className="answer">{form.email}</p>
                    </div>
                    <div className="row">
                      <p className="title">Phone Number</p>
                      <p className="answer">{form.number}</p>
                    </div>
                    <div className="row">
                      <p className="title">Parking Lot</p>
                      <p className="answer">{form.parkingLot}</p>
                    </div>
                    <div className="row">
                      <p className="title">User ID</p>
                      <p className="answer">{form.userId}</p>
                    </div>
                    <div className="row">
                      <p className="title">Password</p>
                      <p className="answer">{form.password?.replace(/./g, '*')}</p>
                    </div>
                    <div className="row">
                      <p className="title">Alt Phone</p>
                      <p className="answer">{form.altPhone}</p>
                    </div>
                    <div className="row">
                      <p className="title">Extension</p>
                      <p className="answer">{form.ext}</p>
                    </div>
                    <div className="row">
                      <p className="title">Address</p>
                      <p className="answer">{form.address}</p>
                    </div>
                    <div className="row">
                      <p className="title">Address(2)</p>
                      <p className="answer">{form.addressTwo}</p>
                    </div>
                    <div className="row">
                      <p className="title">City</p>
                      <p className="answer">{form.city}</p>
                    </div>
                    <div className="row">
                      <p className="title">State</p>
                      <p className="answer">{form.state}</p>
                    </div>
                    <div className="row">
                      <p className="title">ZIP Code</p>
                      <p className="answer">{form.zip}</p>
                    </div>
                    <div className="row">
                      <p className="title">Access Levels</p>
                      <p className="answer">{form.accessLevels}</p>
                    </div>
                    <div className="row">
                      <p className="title">Status</p>
                      <p className="answer active">{form.status}</p>
                    </div>
                  </div>
                </div>
                
                  <div className={ showPop ? "background show" :"background"}>
                    {
                      passwordOrEdit === 'edit' &&
                      <div className="container">
                      <div className="head">
                        <h3>Edit Profile</h3>
                        <FontAwesomeIcon icon={faClose} onClick={() => closePop()}/>
                      </div>
                      <form method="post" className="create">
                        <div className="input">
                          <label htmlFor="user-id">User ID</label>
                          <input type="text" id='user-id' name='userId' value={form.userId} onChange={formChange}/>
                        </div>
                        <div className="inputs">
                          <div className="input">
                            <label htmlFor="user-firstname">First Name</label>
                            <input type="text" id='user-firstname' name='firstName' value={form.firstName} onChange={formChange}/>
                          </div>
                          <div className="input">
                            <label htmlFor="user-lastname">Last Name</label>
                            <input type="text" id='user-lastname' name='lastName' value={form.lastName} onChange={formChange}/>
                          </div>
                        </div>
                        <div className="inputs">
                          <div className="input">
                            <label htmlFor="user-email">Email</label>
                            <input type="email" id='user-email' name='email' value={form.email} onChange={formChange}/>
                          </div>
                          <div className="input">
                            <label htmlFor="user-password">Password</label>
                            <input type="password" id='user-password' name='password' disabled value={form.password}/>
                          </div>
                        </div>
                        <div className="inputs">
                          <div className="input">
                            <label htmlFor="user-phone">Phone</label>
                            <input type="tel" id='user-phone' name='number' value={form.number
                            } onChange={formChange}/>
                          </div>
                          <div className="input">
                          <label htmlFor="user-altphone">Alt. Phone</label>
                            <input type="tel" id='user-altphone' name='altPhone' value={form.altPhone} onChange={formChange}/>
                          </div>
                          <div className="input">
                            <label htmlFor="user-extension">Extension</label>
                            <input type="text" id='user-extension' name='ext' value={form.ext} onChange={formChange}/>
                          </div>
                        </div>
                        <div className="input">
                          <label htmlFor="user-address">Address</label>
                          <input type="text" id='user-address' name='address' value={form.address} onChange={formChange}/>
                        </div>
                        <div className="input">
                          <label htmlFor="user-addressTwo">Address Two</label>
                          <input type="text" id='user-addressTwo' name='addressTwo' value={form.addressTwo} onChange={formChange}/>
                        </div>
                        <div className="inputs">
                          <div className="input">
                            <label htmlFor="user-city">City</label>
                            <input type="text" id='user-city' name='city' value={form.city} onChange={formChange}/>
                          </div>
                          <div className="input">
                            <label htmlFor="user-state">State</label>
                            <select name="state" id="user-state" value={form.state} onChange={formChange}>
                              <option value="" disabled selected>Select State/Province</option>
                              {
                                states.map(state => {
                                  return (
                                    <option value={state.code}>{state.name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="inputs">
                          <div className="input">
                            <label htmlFor="user-zip">ZIP Code</label>
                            <input type="text" id='user-zip' name='zip' value={form.zip} onChange={formChange}/>
                          </div>
                          <div className="input">
                            <label htmlFor="user-accesslevels">Access Levels</label>
                            <select name="accessLevels" id="user-accesslevels" value={form.accessLevels} onChange={formChange}>
                              <option value="" disabled selected></option>
                              <option value="Administrator">Administrator</option>
                              <option value="User">User</option>
                              <option value="Superuser">Superuser</option>
                            </select>
                          </div>
                        </div>
                        <div className="inputs">
                          <div className="input">
                              <label htmlFor="user-parkinglot">Parking Lot</label>
                              <input type="text" id='user-parkinglot' name='parkingLot' disabled value={form.parkingLot} onChange={formChange}/>
                          </div>
                          <div className="input">
                              <label htmlFor="user-status">Status</label>
                              <select name="status" id="user-status" value={form.status} onChange={formChange}>
                                <option value="" disabled selected></option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </select>
                          </div>
                        </div>
                        <div className="btns">
                          <button type='submit'>Save</button>
                          <p className='goBack' onClick={() => closePop()}>Back to Profile</p>
                        </div>
                      </form>
                    </div>
                    }
                    {
                      passwordOrEdit === 'password' && 
                      <div className="container">
                      <div className="head">
                        <h3>Change Password</h3>
                        <FontAwesomeIcon icon={faClose} onClick={() => closePop()}/>
                      </div>
                      <form method="post" className="create">
                        <div className="input">
                          <label htmlFor="username">Username</label>
                          <input type="text" id='username' name='username' value={form.userId} disabled/>
                        </div>
                        <div className="input">
                          <label htmlFor="oldPassword">Old Password</label>
                          <input type="password" id='oldPassword' name='oldPassword' value={newPassword.oldPassword} onChange={passChange}/>
                        </div>
                        <div className="input">
                          <label htmlFor="newPassword">Type in New Password</label>
                          <input type="password" id='newPassword' name='recentPassword' value={newPassword.recentPassword} onChange={passChange}/>
                        </div>
                        <div className="input">
                          <label htmlFor="retypePassword">Retype New Password</label>
                          <input type="password" id='retypePassword' name='retypePassword' value={newPassword.retypePassword} onChange={passChange}/>
                        </div>
                        <div className="btns">
                          <button type='submit' onClick={changePassword}>Change Password</button>
                          <p className='goBack' onClick={() => closePop()}>Back to Profile</p>
                        </div>
                        { whichInp === 'old' || whichInp === 'new' || whichInp === 'all' ? <p className='error'>{changeGood}</p> : ''}
                      </form>
                    </div>
                    }
                  </div>
                
            </div>
        </div>
    </div>
  )
}

export default Profile
import React, { useContext } from 'react'
import '../../styles/Admin/allreports.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import * as Constants from '../../constants'
import { ShareContext } from '../../context/ShareContext'
import Loading from '../../components/Loading'

function ExternalReport() {
    const { isSidebarClosed, adminReport, loadReport } = useContext(ShareContext)

    const reports = adminReport?.reservations

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            <div className="report-body">
                <div className="head">
                    <h2>RESERVATIONS - EXTERNAL REPORT</h2>
                </div>
                {
                    loadReport && <div className={ loadReport ? 'congrats show': 'congrats'}>
                        <Loading message={'Loading Report'} />
                    </div>
                
                }
                
                    { reports?.map((rep, id) => {return (
                        <div className="tables" key={id}>
                            <div className="table-wrapper">
                                <table className='full-table'>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Date Range</th>
                                            <th>Duration</th>
                                            <th>Daily Rate</th>
                                            <th>Discount</th>
                                            <th>At Lot</th>
                                            <th>Service Fee</th>
                                            <th>Access Fee</th>
                                            <th>Tax</th>
                                            <th>Others</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan='13'>{rep.parkinglotname}</td>
                                        </tr>
                                        <tr className='record'>
                                            <td>{rep.reservationid}</td>
                                            <td>{Constants.formatDateTime(rep.arrivaldate)}</td>
                                            <td>{rep.personparking} <br /> {rep.numofpersons} person(s) <br /> {rep.carDetails}</td>
                                            <td>{Constants.formatDateTime(rep.arrivaldate)} <br /> to <br /> {Constants.formatDateTime(rep.returndate)}</td>
                                            <td>{rep.durationbasis}</td>
                                            <td>{rep.dailyrate}</td>
                                            <td>{rep.discount}</td>
                                            <td>{rep.payatlot}</td>
                                            <td>{rep.servicefee}</td>
                                            <td>{rep.accessfee}</td>
                                            <td>{rep.tax}</td>
                                            <td>{rep.othertaxes}</td>
                                            <td>{rep.reservationstatus}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-wrapper">
                                <table className='total-table'>
                                    <tbody>
                                        <tr className='total'>
                                            <td colSpan='5'>Total Count: <span></span></td>
                                            <td>Total Daily Rate: <span>{rep.dailyrate}</span></td>
                                            <td>Total Discount: <span>{rep.discount}</span></td>
                                            <td>Total Pay At Lot: <span>{rep.payatlot}</span></td>
                                            <td>Total Service Fee: <span>{rep.servicefee}</span></td>
                                            <td>Total Access Fee: <span>{rep.accessfee}</span></td>
                                            <td>Total Tax: <span>{rep.tax}</span></td>
                                            <td>Total Others: <span>{rep.totalothertax}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}) }
            </div>
        </div>
    </div>
  )
}

export default ExternalReport
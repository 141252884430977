export const questions = [
    {
        id: 1,
        question: 'Can I make a reservation for someone else?',
        answer: "Yes. Simply enter the person's name in the 'Person Parking' field when checking out. Both your name and their name will appear on your receipt."
    },
    {
        id: 2,
        question: 'How do I get to and from the airport?',
        answer: 'Free transportation on shuttle buses is provided to and from the airport. Please allow 10-15 minutes for check-in and transportation to your terminal. Upon your return, there are courtesy phones in the baggage area, or a phone number on your claim check to call for pick-up. At larger airports, shuttle buses constantly circle the airport. At a few airports you will be taken to the airport in your own car and returned via shuttle bus.'
    },
    {
        id: 3,
        question: 'What is the quoted price?',
        answer: 'The total price that appears on your reservation receipt is for your entire parking stay in accordance with the dates and times you entered (not the price per day). The “Pay Online” price is the amount of money that will be charged to your credit card; the equivalent of one days parking fee. The “Due at Lot” price is the amount you will pay to the parking lot. Both your reservation receipt and the email itinerary review indicate if tax is included - this varies by location. YOU MUST PRINT YOUR RECEIPT AND PRESENT IT TO THE CASHIER TO REDEEM THE RESERVATION AND RECEIVE THE QUOTED PRICE.'
    },
    {
        id: 4,
        question: 'How and when do I pay?',
        answer: 'You pay the total amount quoted on our web site (the "Pay Online" price) and the remainder, if any applies (the "Due at Lot" price) to the parking lot. Our parking affiliates accept cash and all major credit cards. Many of our facilities require full payment at the time of booking. However, a couple of our facilities only want the equivalent of one day paid at time of booking with the remaining balance being due at the parking facility. Our website will state this in the pay online price area.'
    },
    {
        id: 5,
        question: 'What if my flight is delayed or I arrive earlier or park longer than my reservation receipt indicates?',
        answer: 'Your total price will ultimately be based on the actual length of time you are parked. You will be charged the daily or hourly rate stated on your reservation receipt if you arrive earlier or stay longer than the dates and times you originally input. You are charged from the time you arrive at the parking lot until the time you return to the lot, not for the departure and arrival times on your airline ticket. Most generally if you are still within a 24 hour period, we will not charge additional monies. We understand that airport delays are possible.'
    },
    {
        id: 6,
        question: 'What If I drive an over-size vehicle?',
        answer: 'Oversize vehicles (vehicles that take up more than one space) may be subject to an additional charge.'
    },
    {
        id: 7,
        question: 'What if I get a flat tire, dead battery or have other trouble with my car?',
        answer: 'We will either assist you or help in locating assistance when necessary.'
    },
    {
        id: 8,
        question: 'How does the reservation service work once I get to the parking facility?',
        answer: 'On arrival, simply present your printed online receipt to the valet or attendant on duty.'
    },
    {
        id: 9,
        question: 'What are your hours of operation?',
        answer: 'Hours of operations depend on the actual location.'
    },
    {
        id: 10,
        question: 'How long is it to the airport from the parking facility?',
        answer: 'Shuttle schedules run every 15 to 30 minutes depending on location.'
    },
    {
        id: 11,
        question: 'Do you have a Corporate Program?',
        answer: 'Please contact us for more information. Certain arrangements can be made. We will be happy to work with you on custom corporate plans.'
    },
    {
        id: 12,
        question: 'What happens after I click "Book Reservation"?',
        answer: 'After clicking "Book Reservation," please be patient. It may take a minute or two to process your transaction. Once complete, your reservation confirmation and receipt will appear on your screen. We also send a copy of your receipt to the email address you provided. Your receipt contains all the information you will need to park including, your airport of departure, your chosen parking lot name, address, phone and directions, your parking lot arrival and return dates and times, your name and the name of the person parking, your price and parking rate, arrival and transportation information, You must present the parking lot with a copy of your receipt along with one form of personal identification to validate the offer and receive credit for your payment. Please note that the service fee is not refunded to you by the parking lot. If you do not present your receipt to the parking lot, you will not get credit for your online payment and this online payment will not be refunded at a later date. If you are unable to print your confirmation, please notify us (you can notify us by clicking here). We will arrange to either email a copy of your receipt to an alternate email address or fax or mail you a copy. To receive a copy by mail your departure date must be a minimum of 5 business days from the date your transaction was made.'
    }
]
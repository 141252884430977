import React, { useState, useContext } from 'react';
import '../../styles/Admin/manageairport.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faRotateRight, faSearch, faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter} from '@fortawesome/free-solid-svg-icons'
import { ShareContext } from '../../context/ShareContext'

function ManageAirport() {
    const { airportList, isSidebarClosed } = useContext(ShareContext);

    // console.log(airportList);

    const [expandForm, setExpandForm] = useState(false)
    const [openCreate, setOpenCreate] = useState(false);
    const [editData, setEditData] = useState({});
    const [openedEdit, setOpenedEdit] = useState(false)
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false)

    const [airportFormData, setAirportFormData] = useState({
        "airportcode": "",
        "airportshortname": "",
        "airportlongname": "",
        "airportcity": "",
        "airportstate": "",
        "airportaddress": "",
        "airportzip": ""
    })
    const [searchValue, setSearchValue] = useState('');
    const [filterCity, setFilterCity] = useState('')

    const [airportState, setAirportState] = useState(airportList)

    const changeForm = (e) => {
        setAirportFormData({...airportFormData, [e.target.name] : e.target.value})
    }

    const changeEdit = (e) => {
        setEditData({...editData, [e.target.name]: e.target.value})
    }

    const cities = airportState.map(airport => airport.airportcity)
    const newCities = [...new Set(cities)]

    const retrieve = (id) => {
        const theData = airportList.find(item =>  item.airportcode === id)

        return theData;
    }

    const closePopUp = () => {
        setOpenCreate(false) 
        setOpenedEdit(false)
    }

    const editClick = (id) => {
        setOpenCreate(true);
        setOpenedEdit(true)

        const idData = retrieve(id);

        setEditData(idData)
    }

    const searchBar = (e) => {
        setSearchValue(e.target.value)
    }

    const filterChange = (e) => {
        setFilterCity(e.target.value)
    }

    const filteredData = airportState.filter(item => {
        const searchMatch = item.airportshortname.toLowerCase().includes(searchValue.toLowerCase());
        const filterMatch = filterCity === '' || filterCity === item.airportcity
        return searchMatch && filterMatch
    })

  const openDeleteBox = (id) => {
    setIsDialogBoxOpen(true);

    const data = retrieve(id)

    setEditData(data)
    }
 
  const closeDialogBox = () => {
    setIsDialogBoxOpen(false);
  }

  const deleteIt = (id) => {
    const updatedArray = airportState.filter(obj => obj.airportcode !== id)
  
    setAirportState(updatedArray)
    setIsDialogBoxOpen(false)
  }

  const onNew = () => {
    setOpenedEdit(true)
    setOpenCreate(true)
    setEditData({
        airportFormData
    })
  }

  return (
    <div className="admin-body">
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            <div className="manage-airport">
                <div className="head">
                    <h1>Airports</h1>
                    <p>Add New and Modify Old Airports</p>
                </div>
                <div className="body-wrapper">
                    <div className="head-block">
                        <h3>Airports</h3>
                        <div className="head-icons">
                            <FontAwesomeIcon icon={faRotateRight} className='action-icon refresh'/>
                        </div>
                    </div>
                    <div className="adding-client">
                        <button onClick={() => onNew()}>Create New</button>
                        <div className="selection">
                            <label htmlFor="filter-lots">Select Airport City</label>
                            <select name="filterlots" id="filter-lots" value={filterCity} onChange={filterChange}>
                                <option value="">All Cities</option>
                                {
                                    newCities.map((city, index) => <option key={index} value={city}>{city}</option>)
                                }
                            </select>
                        </div>
                        <div className="search-input">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="text" placeholder='Search Airport by Name' value={searchValue} onChange={searchBar}/>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Airport Code</th>
                                    <th>Short Name</th>
                                    <th>Long Name</th>
                                    <th>Address</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData.map((airport, idx)=> { return (
                                        <tr key={idx}>
                                            <td>{airport.airportcode}</td>
                                            <td>{airport.airportshortname}</td>
                                            <td>{airport.airportlongname}</td>
                                            <td>{airport.airportaddress}</td>
                                            <td className='icons'>
                                                <ul>
                                                    <li onClick={() => editClick(airport.airportcode)}>Edit</li>
                                                    <li className='delete' onClick={() => openDeleteBox(airport.airportcode)}>Delete</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className={ openCreate ? "create-wrapper active" : "create-wrapper"}>
                        <div className="close-it" onClick={() => closePopUp()}>
                            <FontAwesomeIcon icon={faClose} />  
                        </div>
                        <div className={expandForm ? "create-account expanded": "create-account"}>
                            <div className="create-header">
                                <h3>{openedEdit ? 'Edit Airport' : 'Create New Airport'}</h3>
                                <FontAwesomeIcon icon={expandForm ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} className='close-create' onClick={() => setExpandForm(!expandForm)}/>
                            </div>
                            {
                                openedEdit &&
                                <form method='post'>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="airportCode">Airport Code</label>
                                        <input type="text" id='airportCode' value={editData.airportcode} name='airportcode' onChange={changeEdit} disabled />
                                    </div>
                                    <div className="input">
                                        <label htmlFor="shortName">Short Name</label>
                                        <input type="text" id='shortName' value={editData.airportshortname} name='airportshortname' onChange={changeEdit}/>
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="longName">Long Name</label>
                                    <input type="text" id='longName' value={editData.airportlongname} name='airportlongname' onChange={changeEdit}/>
                                </div>
                                <div className="input">
                                    <label htmlFor="airportaddress">Address 1</label>
                                    <input type="text" id='airportaddress' value={editData.airportaddress} name='airportaddress' onChange={changeEdit}/>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="airportcity">City</label>
                                        <input type="text" id='airportcity' value={editData.airportcity} name='airportcity' onChange={changeEdit}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="airportstate">State</label>
                                        <select id="airportstate" value={editData.airportstate} name='airportstate' onChange={changeEdit}>
                                            <option value="" selected disabled>Select State/Province</option>
                                            { airportList.map((state, idx) => <option key={idx} value={state.airportstate}>{state.airportstate}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="airportzip">ZIP Code</label>
                                    <input type="text" id='airportzip' value={editData.airportzip} name='airportzip' onChange={changeEdit}/>
                                </div>
                                <div className="btns">
                                    <button type='submit'>Save</button>
                                    <p className='goBack' onClick={() => closePopUp()}>Back to Airports</p>
                                </div>
                                </form> 
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={ isDialogBoxOpen ? "delete-wrapper show" : "delete-wrapper"}>
                <div className="dialog">
                    <div className="head">
                        <h4>Delete Airport</h4>
                        <FontAwesomeIcon icon={faClose} onClick={() => closeDialogBox()}/>
                    </div>
                <div className="main">
                    <h3>Are you sure you want to delete Airport: { editData.airportshortname }?</h3>
                    <p>This action will permanently delete this Airport. Please click 'Delete Airport' to continue or Cancel to abort!</p>
                    <div className="btns">
                        <button onClick={() => deleteIt(editData.airportcode)}>Delete Airport</button>
                        <p onClick={() => closeDialogBox()}>Cancel</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default ManageAirport
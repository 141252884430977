import React, { useContext } from 'react'
import { ShareContext } from '../../context/ShareContext';
import { Link } from 'react-router-dom';
import '../../styles/User/topbar.css'
import { useNavigate } from 'react-router-dom';

function TopBar() {
  const {isUserLoggedIn, setIsUserLoggedIn, userData} = useContext(ShareContext)

  const navigate = useNavigate();

  // console.log(customerDetails);

  const logOut = () => {
    // Clear token from local storage
    localStorage.removeItem('accessToken');
  
    // Clear other user-related data from local storage
    localStorage.removeItem('userData');
  
    // Set user logged in state to false
    setIsUserLoggedIn(false);
  
    // Navigate to the login page
    navigate('/reservation/login');
  };

  // console.log(userData);

  return isUserLoggedIn && userData.c!=null? (
    <div className="top-bar">
        <div className='name-details'>
          <p>Welcome <span>{userData.c?.firstname + ' ' + userData.c?.lastname}</span></p>
          <Link to='/user-profile'><button>Change Details</button></Link>
        </div>
      <div className="password-out">
        <button onClick={logOut}>Log Out</button>
      </div>
    </div>
  ) : (
    <div className="top-bar">
      <Link to='/' className='home-link'>Back to Home</Link>
      <div className="right-side">
        <p>You are Welcome!</p>
        <Link to='/reservation/login'><button>Log In</button></Link>
      </div>
    </div>
  );
  
}

export default TopBar
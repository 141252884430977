import { faFacebook, faGooglePlus, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import '../../styles/User/footer.css'
import { Link } from 'react-router-dom'
import AirportInc from '../../assets/logo.png';
import Outrageous from '../../assets/outrageouscabins.jpg';
import StayAmerica from '../../assets/ESH_one_line.jpg';
import ParkEZ from '../../assets/ParkEzLogo.png';
import AuthorizeLogo from '../../assets/authorize.png';
import { useLocation } from 'react-router-dom';
import moment from 'moment'

function Footer() {

    const location = useLocation();

    // Check if the current route contains "/admin"
    const isAdminRoute = location.pathname.includes('/admin');

    const routesWithoutFooter = ['/reservation/login', '/home/register', '/home/forgetpassword']

    const shouldHideFooter = isAdminRoute || routesWithoutFooter.includes(location.pathname);

  return shouldHideFooter ? null : (
    <footer className='show no-print'>
        <div className='top-footer'>
            <div className='left-side'>
                <Link to='/'>
                    <img src={AirportInc} alt='New Orleans Logo' />
                </Link>
                <ul>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/home/termofservice'>Terms of Service</Link></li>
                    <li><Link to='/home/privacy'>Privacy Policy</Link></li>
                    <li><Link to='/home/contact'>Contact Us</Link></li>
                    <li><Link to='/home/faq'>FAQ</Link></li>
       {/*              <li><a href="/pages/2015/articles.html">Articles</a></li> */}
                </ul>
                
                {/* <ul className='social-icons'>
                    <li><Link href='https://twitter.com/AirportPark_Inc' rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faTwitter} /></Link></li>
                    <li><Link href='https://web.facebook.com/pages/Airport-Parking-Inc/149759451747371?_rdc=1&_rdr' rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faFacebook} /></Link></li>
                    <li><Link href='https://play.google.com/store/apps/details?id=com.ionicframework.airportapp357995&pli=1' rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faGooglePlus} /></Link></li>
                </ul> */}
            </div>
            <div className='right-side'>
                <ul>
                    <li><Link to='https://www.outrageouscabins.com/' rel="noreferrer" target='_blank'><img src={Outrageous} alt='Sponsor Logo' /></Link></li>
                   
                   
                    <li><Link to='https://verify.authorize.net/anetseal/?pid=740af94d-0edf-42b6-9a34-fa9e9c40c488&rurl=https://www.neworleansparking.com/.com' rel="noreferrer" target='_blank'><img src={AuthorizeLogo} alt='Sponsor Logo' /></Link></li>   
                </ul>
            </div>
        </div>
        <div className='copyright'>
            <p>Copyright {2024} © New Orleans Parking - All Rights Reserved.</p>
        </div>
    </footer>
  )
}

export default Footer
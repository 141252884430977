import React, { useContext, useState, useEffect } from 'react'
import '../../styles/Admin/refund.css'
import { useParams } from 'react-router-dom'
import { ShareContext } from '../../context/ShareContext';
import Loading from '../../components/Loading';
import * as Constants from '../../constants';
import Sidebar from '../../components/Admin/Sidebar';
import Head from '../../components/Admin/Head';
import Expired from '../../components/Expired';

function Refund() {
    const {reservationId} = useParams() //this represent reservation


    const { token, isSidebarClosed } = useContext(ShareContext);

    const [receiptData, setReceiptData] = useState({})
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [isExpired, setIsExpired] = useState(false);


    useEffect(() => {
        setLoadingStatus(true);
        fetch(`${Constants.apiUrl}/reservation/receipt/${reservationId}`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Authorization': token,
          },
        })
          .then((resp) => resp.json())
          .then((data) => {
            
            // console.log(data);
            if(data.code==0){
                setReceiptData(data);
                // setNumofdays(data.numofdays);
            }
            else if(data.code==-10){
                //logOut(); //possible the token is not available via context
                setIsExpired(true)
            }
            setLoadingStatus(false);
          })
          .catch((error) => {
            console.log(error);
            
          });
      }, [reservationId]);

    //   console.log(isSidebarClosed);

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}
            <div className="full-body">
                <h2 className='top-head'>Refund</h2>
                <h3 className='name'>Refund for {receiptData?.customer?.firstname + ' ' + receiptData?.customer?.lastname} reservation at {receiptData?.parkinglot?.parkinglotname} from {Constants.formatDate(receiptData?.reservation?.arrivaldate)} to {Constants.formatDate(receiptData?.reservation?.returndate)}</h3>
                <div className="refund-body">
                {loadingStatus && <div className={ loadingStatus ? "congrats show" : "congrats"}>
                        <Loading message={'Loading Full Receipt'} /> 
                    </div>
                }
                    <p>You may decide to refund your customer part of the fee but not all <br /> Although not required, we would like to know the reason for the refund in the text box below.</p>
                    <div className="form-refund">
                        <div className="inputs">
                            <div className="input">
                                <label htmlFor="refund-amt">Refund Amount</label>
                                <input type="number" id='refund-amt' placeholder='How much are you requesting back?'/>
                                <p className="note">Maximum Refund Amount: ${receiptData?.reservation?.totalamount}</p>
                            </div>
                            <div className="input">
                                <label htmlFor="reason">Reason for cancelation</label>
                                <textarea name="" id="reason"></textarea>
                            </div>
                        </div>
                        <div className="check">
                            <input type="checkbox" id='check-ref' />
                            <label htmlFor="check-ref">Check here if you want the system to credit the customer through AuthorizeNet.</label>
                        </div>
                        <div className="input">
                            <input type="text" placeholder='Enter Last Four(4) Digits of Credit Card'/>
                        </div>
                        <button type='button'>Refund Reservation</button>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default Refund
import React, { useContext, useState, useEffect } from 'react'
import '../../styles/Admin/allreports.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import * as Constants from '../../constants'
import { ShareContext } from '../../context/ShareContext'
import Loading from '../../components/Loading'


function ReservationReport() {
    const { isSidebarClosed, adminReport,reportForm } = useContext(ShareContext)
    const [list, setList]=useState({});
    const [total, setTotal]=useState([]);

    const report=adminReport.reservations;

    const onRearrange=()=>{
        console.log('data rearrangement');
        var _list={};
        var fortotal=[];
    for (const re of report){
            var id=Constants.removeSpace(re.parkinglotid);
        var pak=_list[id];
       // var totalamt=re.payatlot>0? (re.totalamount - (re.servicefee   +re.tax + re.totalothertax + re.refundamt + re.payatlot)):(re.totalamount - (re.servicefee + re.accessfee + re.tax + re.totalothertax + re.refundamt + re.payatlot));
       var totalamt= (re.totalamount - (re.servicefee   +re.tax + re.totalothertax + re.refundamt + re.payatlot));
       var totalamtstr=totalamt+ (re.refundamt >0 ? ` Refund ${re.refundamt}`:'');
        if(pak==null){
            _list[id]={res:[{...re,totalamt,totalamtstr}]}; //adding first item
            
        }else{
            var pak=_list[id];
            pak.res=[...pak.res,{...re,totalamt,totalamtstr}];
            _list[id]={...pak};
        }

        fortotal.push({...re,totalamt,totalamtstr}) ;

        }
        console.log(_list) ;
        setList(_list);
        console.log('total list items')
        console.log(fortotal);
        setTotal(fortotal);
        //calculate total

    }
   
    useEffect(() => {
     onRearrange();
      }, [])
    
    

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            <div className="report-body">
                <div className="head">
                    <h2> RESERVATIONS - {reportForm.rt=='r1' && 'INTERNAL REPORT'}{reportForm.rt=='r2' && 'EXTERNAL REPORT'}{reportForm.rt=='rhotel' && 'HOTEL REPORT'} - {reportForm.reportType.toUpperCase()}</h2>
                </div>
               
                
                    
                        <div className="tables" >
                          {reportForm.reportType=='details' &&   <div className="table-wrapper">
                                <table className='full-table'>
                                {total?.length>0 && Object.values(list) .map((li, id) => {return (<>  <thead>
                                    <tr>
                                        <th> ID</th>
                                        <th> Date</th>
                                        <th>Name</th>
                                        <th>Date Range</th>
                                        <th>Duration</th>

                                        {reportForm.rt=='r2' &&
                                        <>
                                        <th>Daily Rate</th>
                                        <th> Discount</th>
                                        <th>At Lot</th>    
                                        <th>Service Fee</th>
                                        <th>Access Fee</th>
                                        </>
                                                
                                        } 
                                        {
                                            reportForm.rt=='rhotel' && <>
                                            <th>Total</th> 
                                            <th>At Lot</th>  
                                            <th>Access Fee</th>
                                            </>
                                    
                                        }
                                            <th> Tax</th> 
                                            <th>Others</th>                      
                                       
                                        { reportForm.rt=='rhotel' || reportForm.rt=='r2' &&
                                            <th>Status</th>
                                            
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan='13'>{li.res[0].parkinglotname}</td>
                                        </tr>

                                        { li.res.map((rep, idx) => {return ( 
                                        <tr className='record' key={idx}>
                                            <td>{rep.reservationid}</td>
                                            <td>{Constants.formatDateTime(rep.arrivaldate)}</td>
                                            <td>{rep.firstname} {rep.lastname}  <br /> {rep?.numofpersons} person(s) <br /> {rep.vesselname}</td>
                                            <td>{Constants.formatDateTime(rep.arrivaldate)} <br /> to <br /> {Constants.formatDateTime(rep.returndate)}</td>
                                            <td>{Constants.duration(rep.arrivaldate,rep.returndate)}</td>
                                            {reportForm.rt=='r2' &&
                                             <>
                                            <td>{Constants.formatCurrency (rep?.dailyrate)}</td>
                                            <td>{Constants.formatCurrency(rep?.discount)}</td>
                                            <td>{Constants.formatCurrency(rep?.payatlot)}</td>
                                            <td>{Constants.formatCurrency(rep?.servicefee)}</td>
                                            <td>{Constants.formatCurrency(rep?.accessfee)}</td>
                                            </>
                                    
                                            }
                                             {
                                            reportForm.rt=='rhotel' && <>
                                            <th>{Constants.formatCurrency(rep?.totalamount - (rep?.servicefee + rep?.accessfee + rep?.tax + rep?.totalothertax + rep?.refundamt))} {rep.refundamt >0 ? ` Refund ${Constants.formatCurrency(rep.refundamt)}`:''}</th> 
                                            <th>{Constants.formatCurrency(rep?.payatlot)}</th>  
                                            <th>{Constants.formatCurrency(rep?.accessfee)}</th>
                                            </>
                                    
                                        }
                                            <td>{Constants.formatCurrency(rep?.tax)}</td>
                                            <td>{Constants.formatCurrency(rep?.totalothertax)}</td>
                                            { reportForm.rt=='rhotel' || reportForm.rt=='r2' &&
                                            <td>{rep?.reservationstatus}</td>
                                        }
                                            
                                        </tr>)}) }

                                        <tr className='total'>
                                            <td colSpan='5'>Total : <span></span></td>
                                            {reportForm.rt=='r2' &&
                                             <>
                                            <td>Total Daily Rate: <span></span></td>
                                            <td>Total Discount: <span>{ Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.discount, 0)) }</span></td>
                                            <td>Total Pay At Lot: <span>{ Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.payatlot, 0)) }</span></td>
                                            <td>Total Service Fee: <span>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.servicefee, 0)) }</span></td>
                                            <td>Total Access Fee: <span>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.accessfee, 0)) }</span></td>
                                            </>
                                    
                                            }
                                             {
                                            reportForm.rt=='rhotel' && <>
                                            <th>{ Constants.formatCurrency(li.res.reduce((sum, item) => sum + (item?.totalamount - (item?.servicefee + item?.accessfee + item?.tax + item?.totalothertax + item?.refundamt)), 0))  } 
                                            {li.res.reduce((sum, item) => sum + item.refundamt, 0)>0?
                                             ` Refund: ${Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.refundamt, 0))}`:''}</th> 
                                            <th>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.payatlot, 0))}</th>  
                                            <th>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.accessfee, 0))}</th>
                                            </>
                                    
                                        }
                                            <td>Total Tax: <span>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.tax, 0)) }</span></td>
                                            <td>Total Others: <span>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.totalothertax, 0)) }</span></td>
                                            { reportForm.rt=='rhotel' || reportForm.rt=='r2' &&
                                            <td></td>
                                        }
                                        </tr>
                                    </tbody> 
                                    </>)}) }
                                    <tbody>
                                    {total?.length>0 && <tr className='finaltotal'>
                                            <td colSpan='5'> Overall Total: <span></span></td>
                                            {reportForm.rt=='r2' &&
                                             <>
                                            <td>Total Daily Rate: <span></span></td>
                                            <td>Total Discount: <span>{Constants.formatCurrency( total.reduce((sum, item) => sum + item.discount, 0)) }</span></td>
                                            <td>Total Pay At Lot: <span>{ Constants.formatCurrency(total.reduce((sum, item) => sum + item.payatlot, 0)) }</span></td>
                                            <td>Total Service Fee: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.servicefee, 0)) }</span></td>
                                            <td>Total Access Fee: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.accessfee, 0)) }</span></td>
                                            </>
                                    
                                            }
                                             {
                                            reportForm.rt=='rhotel' && <>
                                            <th>{ Constants.formatCurrency(total.reduce((sum, item) => sum + (item?.totalamount - (item?.servicefee + item?.accessfee + item?.tax + item?.totalothertax + item?.refundamt)), 0))  } 
                                            {total.reduce((sum, item) => sum + item.refundamt, 0)>0?
                                             ` Refund: ${Constants.formatCurrency(total.reduce((sum, item) => sum + item.refundamt, 0))}`:''}</th> 
                                            <th>{total.reduce((sum, item) => sum + item.payatlot, 0)}</th>  
                                            <th>{total.reduce((sum, item) => sum + item.accessfee, 0)}</th>
                                            </>
                                    
                                        }
                                            <td>Total Tax: <span>{total.reduce((sum, item) => sum + item.tax, 0) }</span></td>
                                            <td>Total Others: <span>{total.reduce((sum, item) => sum + item.totalothertax, 0) }</span></td>
                                            { reportForm.rt=='rhotel' || reportForm.rt=='r2' &&
                                            <td></td>
                                        }
                                        </tr>}   
                                    </tbody>
                                </table>
                            </div>}
                            {reportForm.reportType!='details' && <div className="table-wrapper">
                                <table className='total-table'>
                                    <tbody>
                                    <tr className='record' >
                                            <td  colSpan='5'> </td>
                                            
                                            {reportForm.rt=='r2' &&
                                             <>
                                            <td>Total Paid</td>
                                            <td> Discount</td>
                                            <td> Pay at Lot</td>
                                            <td>Service Fee</td>
                                            <td>Access Fee</td>
                                            </>
                                    
                                            }
                                             {
                                            reportForm.rt=='rhotel' && <>
                                            <th>Amount</th> 
                                            <th>Pay at Lot</th>  
                                            <th>Access Fee</th>
                                            </>
                                    
                                        }
                                            <td>Tax</td>
                                            <td>Other Taxes</td>
                                            { reportForm.rt=='rhotel' || reportForm.rt=='r2' &&
                                            <td></td>
                                        }
                                            
                                        </tr>
                                        {total?.length>0 && total.map((rep, idx) => {return ( 
                                        <tr className='record' key={idx}>
                                            <td  colSpan='5'> {rep.parkinglotname}</td>
                                            
                                            {reportForm.rt=='r2' &&
                                             <>
                                            <td>{Constants.formatCurrency(rep?.totalamount - (rep?.servicefee + rep?.accessfee + rep?.tax + rep?.totalothertax + rep?.refundamt+rep?.payatlot) )}</td>
                                            <td>{Constants.formatCurrency(rep?.discount)}</td>
                                            <td>{Constants.formatCurrency(rep?.payatlot)}</td>
                                            <td>{Constants.formatCurrency(rep?.servicefee)}</td>
                                            <td>{Constants.formatCurrency(rep?.accessfee)}</td>
                                            </>
                                    
                                            }
                                             {
                                            reportForm.rt=='rhotel' && <>
                                            <th>{Constants.formatCurrency(rep?.totalamount - (rep?.servicefee + rep?.accessfee + rep?.tax + rep?.totalothertax + rep?.refundamt))} {rep.refundamt >0 ? ` Refund ${Constants.formatCurrency(rep.refundamt)}`:''}</th> 
                                            <th>{Constants.formatCurrency(rep?.payatlot)}</th>  
                                            <th>{Constants.formatCurrency(rep?.accessfee)}</th>
                                            </>
                                    
                                        }
                                            <td>{Constants.formatCurrency(rep?.tax)}</td>
                                            <td>{Constants.formatCurrency(rep?.othertaxes)}</td>
                                            { reportForm.rt=='rhotel' || reportForm.rt=='r2' &&
                                            <td>{rep?.reservationstatus}</td>
                                        }
                                            
                                        </tr>)}) }
                                        {total?.length>0 && <tr className='finaltotal'>
                                            <td  colSpan='5'>Total: <span></span></td>
                                            {reportForm.rt=='r2' &&
                                             <>
                                             <td>Total Amount: <span>{Constants.formatCurrency( total.reduce((sum, item) => sum + (item.totalamount - (item.servicefee + item.accessfee + item.tax + item.totalothertax + item.refundamt +item.payatlot)), 0)) }</span>
                                            <br></br> Total Refund: <span>{Constants.formatCurrency( total.reduce((sum, item) => sum + item.refundamt, 0)) }</span>
                                             </td>
                                            <td>Total Discount: <span>{Constants.formatCurrency( total.reduce((sum, item) => sum + item.discount, 0)) }</span></td>
                                            <td>Total Pay At Lot: <span>{ Constants.formatCurrency(total.reduce((sum, item) => sum + item.payatlot, 0)) }</span></td>
                                            <td>Total Service Fee: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.servicefee, 0)) }</span></td>
                                            <td>Total Access Fee: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.accessfee, 0)) }</span></td>
                                            </>
                                    
                                            }
                                             {
                                            reportForm.rt=='rhotel' && <>
                                            <th>{Constants.formatCurrency( total.reduce((sum, item) => sum + (item.totalamount - (item.servicefee + item.accessfee + item.tax + item.totalothertax + item.refundamt )), 0)) } 
                                            {total.reduce((sum, item) => sum + item.refundamt, 0)>0?
                                             ` Refund: ${Constants.formatCurrency(total.reduce((sum, item) => sum + item.refundamt, 0))}`:''}</th> 
                                            <th>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.payatlot, 0))}</th>  
                                            <th>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.accessfee, 0))}</th>
                                            </>
                                    
                                        }
                                            <td>Total Tax: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.tax, 0) )}</span></td>
                                            <td>Total Others: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalothertax, 0) )}</span></td>
                                            { reportForm.rt=='rhotel' || reportForm.rt=='r2' &&
                                            <td></td>
                                        }
                                        </tr>}   
                                    </tbody>
                                </table>
                            </div> }
                        </div>

                    
            </div>
        </div>
    </div>
  )
}

export default ReservationReport
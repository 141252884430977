import React from 'react';
const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
// When it is, it will automatically load the TrustBox.
if (window.Trustpilot) {
  window.Trustpilot.loadFromElement(ref.current, true);
}
  }, []);
  return (
<div
  ref={ref} // We need a reference to this element to load the TrustBox in the effect.
 className="trustpilot-widget"  data-locale="en-US" data-template-id="539ad0ffdec7e10e686debd7" data-businessunit-id="54a69f2a0000ff00057c74e0" data-style-height="350px" data-style-width="100%" data-theme="light" data-stars="4,5" // Renamed this to className.
 // [ long list of data attributes...]
>
  <a href="https://www.trustpilot.com/review/neworleansparking.com" target="_blank" rel="noopener"> Trustpilot
  </a>
</div>
  );
};
export default TrustBox;
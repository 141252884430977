import React, { useState } from 'react'
import LoginImg from '../../assets/login.jpg';
import '../../styles/User/forgot.css'
import { Link } from 'react-router-dom';
import * as Constants from '../../constants';
import Loading from '../../components/Loading';

import ReactGA from 'react-ga';
function ForgotPassword() {

    const [email, setEmail] = useState('')
    const [msg, setMsg] =useState({code:0, title:''});
    const [loading, setLoading]=useState(false);


    const handleChange = (e) => {
        setEmail(e.target.value)
    } 

    const submit = async(e) => {
        e.preventDefault()
        setLoading(true);
        const resp = await fetch(`${Constants.apiUrl}/reservation/forgetpassword?email=${email}`, {
            method: 'POST',
            body: email,
            headers: {
              'Content-Type': 'application/json',
            //   'Authorization': '',
            },
        })
        const responseData = await resp.json();
          setLoading(false);
          setMsg({code:responseData.code, title:responseData.status})
          
        console.log(resp);
    }

    ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className='forgot-page'>
        <img src={LoginImg} alt="Logging In As USer" />
        <div className="form">
            <div className="container">
                <div className="text">
                    <p className='welcome'>Welcome to <span>New Orleans Parking</span></p>
                    <h2 className="bigText">Forgot Password</h2>
                </div>
                <div className="input">
                    <label htmlFor="email">E-mail</label>
                    <input type="email" id='email' name='email' value={email} onChange={handleChange}/>
                </div>
                <div className="btns">
                    <button onClick={submit}>Send Recovery Mail</button>
                    <Link to='/reservation/login'>Back to Login</Link>
                </div>
                <h3 style={{color:msg.code==0 ?'#2C5F2D':'red', margin:16}}>{msg.title}</h3>
            </div>
        </div>
        {loading && <div className={loading ? 'congrats show' : 'congrats'}>
                <Loading message='Forget password in progress..' />
              </div>}
    </div>
  )
}

export default ForgotPassword
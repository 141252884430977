import React, { useContext , useEffect, useState} from 'react'
import '../../styles/User/searchresult.css'
import TopBar from '../../components/User/TopBar'
import AirportForm from '../../components/User/AirportForm'
import { ShareContext } from '../../context/ShareContext';
import { Link } from 'react-router-dom';
import * as Constants from '../../constants';
import { useParams } from 'react-router-dom'
import ReactGA from 'react-ga';

function SearchResult() {

    const { formData, isFormSubmitted, searchData, setFormData , setSearchData, setIsFormSubmitted} = useContext(ShareContext)
    const {reservationId} = useParams();
    const mainResvId=reservationId==null?0:reservationId;
    const [loading, setLoading]=useState(false);
    const fetchurl=reservationId==null || reservationId==0?  `${Constants.apiUrl}/reservation/search`: `${Constants.apiUrl}/reservation/${reservationId}/search`;
//   console.log(searchData);
useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  if(searchData==null){
    //recall with 
    Reload();
  }else{
    console.log('search data ');
  }
  }, [])
   
  const Reload = async() => {
    setLoading(true);
   // e.preventDefault();
  
    
    
     //store in local storage
    var searchparam= localStorage.getItem('searchdata');
    
    if(searchparam==null){
            console.log('no searchparam data');
            return;
    }else{
        setFormData(JSON.parse(searchparam));
       // console.log(searchparam);
    }

    try {
        const resp = await fetch(fetchurl, {
          method: 'POST',
          body: searchparam,
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        const responseData = await resp.json();
        setSearchData(responseData)
       // console.log(responseData)
        if (responseData.code === 0) {
           
            setIsFormSubmitted(true)
        }else {
          console.log(responseData.status);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    
}


  return (
    <div className='search-result'>
        <TopBar />
        <div className="body">
            <AirportForm reservationId={reservationId==null?0:reservationId} />
            <div className="results">
                <div className="title">
                    <h2>Reservations</h2>
                   
                    {formData.rateType=='daily' && <div className="edit-text">
                        <h3>Search Results  {searchData?.numofdays} days, from {formData.startDate}  to {formData.endDate}</h3>
                        </div>} 

                        {(formData.rateType=='hourly' ) && <div className="edit-text">
                            <p>search for {formData.startDate} starting at hour {formData.startTime} to {formData.endTime}  </p>
                            
                        </div>} 
                </div>

                { isFormSubmitted ? <div className="cards">
                    { searchData?.parkings.map((item, idx) => {
                         const createMarkup = (content) => ({ __html: (content ?? '').replace(/<br\s*\/?>/g, '\n') });

                        return (
                            <div className="card" key={idx}>
                                <div className="left-note">
                                    <h3>{item.parkinglotname}</h3>
                                 {/*   {item.holidaydaysinavg.length>0 && 
                                    <p className="head-note" >Please note: Holiday rate of ${item.holidaydaysinavg[0].dailyrate} per day in affect from {Constants.formatDate(item.holidaydaysinavg[0].startdate) } to {Constants.formatDate(item.holidaydaysinavg[0].enddate) }</p>
                                   } */}
                                  {item.holidaydaysinavg?.map((holiday, ix)=>{
                                       return (<p className="head-note" key={ix}>Please note: Holiday rate of ${holiday.dailyrate} per day in affect from {Constants.formatDate(holiday.startdate) } to {Constants.formatDate(holiday.enddate) }</p>)
                                    })} 
                                    {/* <div className="general-info">
                                        <p dangerouslySetInnerHTML={createMarkup(item.shortsecuritystatement)} />
                                    </div>
                                    <div className="complementary-info">
                                        <p dangerouslySetInnerHTML={createMarkup(item.transportation)} />
                                    </div> */}
                                    <div>
                                        {item.address1}
                                    </div>
                                </div>
                                <div className="price">
                                <div className="top-price">
                                    {/* <div className="ticketing">
                                        <h4>TYPE</h4>
                                        <p>{item.parkingtype}</p>
                                    </div> */}
                                    <div className="ticketing">
                                        <h4>AVG. RATE</h4>
                                        <p>${item.avgrate.toFixed(2)}</p>
                                    </div>
                                </div>
                                 
                               {mainResvId===0 && <div className="mid-price">
                                    <p>Sub-Total = ${item.subtotal.toFixed(2)}</p>
                                    <p>Service Fee = ${item.servicefee.toFixed(2)}</p>
                                    <p>Sales Tax = ${item.tax.toFixed(2)}</p>
                                    {item.freedaysavings === 0 ? '' : <p>Free Day Savings = ${item.freedaysavings.toFixed(2)}</p> }
                                    <h3>Amount Total = ${item.onlinepay.toFixed(2)}</h3>
                                </div>} 
                              {mainResvId===0 && <div className="total-price">
                                    <h3>Amount Total = ${item.onlinepay.toFixed(2)}</h3>
                                    <p>Due at Lot = ${item.dueatlot.toFixed(2)}</p>
                                </div>}

                                {mainResvId>0 && <div className="mid-price">
                                <div> Already paid ${Constants.formatCurrency(item?.alreadypaid) } calculated payment ${Constants.formatCurrency(item?.onlinepay)} </div>
                                    <p>Sub-Total  = ${item.subtotal.toFixed(2)}</p>
                                    <p>Service Fee  = ${item.servicefee.toFixed(2)}</p>
                                    <p>Sales Tax = ${item.tax.toFixed(2)}</p>
                                    {item.freedaysavings === 0 ? '' : <p>Free Day Savings = ${item.freedaysavings.toFixed(2)}</p> }
                                    <h3>Amount Total = ${item.onlinepay.toFixed(2)}</h3>
                                </div>} 
                              {mainResvId>0 && <div className="total-price">
                                    {item?.oldstatus==='changelot' && <p>To Pay online ${item.totalamt.toFixed(2)} - {Constants.formatCurrency(item?.alreadypaid) } Already Paid  </p>}
                                    {item?.oldstatus==='extend' && <p>You previously paid for ({searchData?.numofdays- searchData?.currentdays})days @ ${Constants.formatCurrency(item?.alreadypaid)}. You are adding {searchData?.currentdays}day(s) at the avg rate specified above. </p>}
                                    {item?.oldstatus==='short_trip' && <p>To Pay online ${item.onlinepay.toFixed(2)} - {Constants.formatCurrency(item?.alreadypaid) } Already Paid  </p>}
                                    {item?.oldstatus==='shift' && <p>To Pay online ${item.onlinepay.toFixed(2)} - {Constants.formatCurrency(item?.alreadypaid) } Already Paid  </p>}
                                   
                                    <h3>Amount Total {item.booked} = {Constants.formatCurrency(item.finalpay)}</h3>
                                    <p>Due at Lot {item.blocked} = {Constants.formatCurrency(item.dueatlot)}</p>
                                </div>}  


                              {mainResvId==0 && <div className="btn">
                                    <Link to={mainResvId===0? `/reservation/checkout/${item.parkinglotid}`:`/reservation/checkout/${item.parkinglotid}/${mainResvId}` }>
                                        <button disabled={ item.booked === true || item.blocked === true}  className={item.booked === true || item.blocked === true ? 'cancel-btn' : 'reserve-btn'}>
                                            {item.booked === true || item.blocked === true ? 'UNAVAILABLE' : 'RESERVE'}
                                        </button>
                                    </Link>
                                </div>}  

                                {mainResvId>0 && <div className="btn">
                                    <Link to={mainResvId===0? `/reservation/checkout/${item.parkinglotid}`:`/reservation/checkout/${item.parkinglotid}/${mainResvId}` }>
                                        <button disabled={  false}  className={ false  ? 'cancel-btn' : 'reserve-btn'}>
                                            {false ? 'UNAVAILABLE' : 'RESERVE'}
                                        </button>
                                    </Link>
                                </div>}  

                                </div>
                            </div>
                        )
                    })}
                </div> : ''}
                
            </div>
        </div>
    </div>
  )
}

export default SearchResult
import React, { useState, useContext, useEffect } from 'react'
import '../../styles/Admin/admin.css'
import { Link } from 'react-router-dom'
import { faFacebook, faGooglePlus, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from '../../assets/logo.png';
import LogInImg from '../../assets/adminlogin.png';
import { ShareContext } from '../../context/ShareContext';
import { useNavigate, useParams } from 'react-router-dom'
import * as Constants from '../../constants';
import Loading from '../../components/Loading'

function Admin() {
    const {cred} = useParams();
    
   // console.log(`the param ${username} the password ${password}`)

    const {  setIsAdminLoggedIn, setAdminData, setAdminToken} = useContext(ShareContext);
    
    Constants.seo({title:'New Orleans Parking Reservations | Admin Login',
    metaDescription:'New Orleans Parking Reservations. Quick And Easy Long Term Offsite New Orleans Parking Reservations. 24-hour Video Surveillance. Convenient Shuttle Service.'})
    
    const [adminLoginData, setAdminLoginData] = useState({
        username: '',
        password: ''
    });
    const navigate = useNavigate()

    const [isInputEmpty, setIsInputEmpty] = useState(false)
    const [loginError, setLoginError] = useState('')
    const [loading, setLoading]=useState(false);

    const handleChange = (e) => {
        setAdminLoginData({...adminLoginData, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        if(cred!=null){
            //this measn something came from server
            //retrieve the long base64
            var data= JSON.parse(atob(cred)) ;
            console.log(data);
            if (data.code >= 0) {
                setAdminData(data);
                setIsInputEmpty(false)
                setIsAdminLoggedIn(true)
                var d='';
                if(data.page=='lotperformance')
                    d='lot-performance'


                navigate(`/admin/${data.page}`);
               // navigate('/admin/reservations');
                const { user } = data
    
                localStorage.setItem('adminDetails', JSON.stringify(user))
                
                const { token } = data;
    
                setAdminToken(token)
    
                localStorage.setItem('adminToken', token);
                localStorage.setItem('tokenType', 'admin');
                
    
              }else {
                setLoginError(data.status)
              }
        }
         }, [])

    // console.log(isAdminLoggedIn);

    const login = async(e) => {
        e.preventDefault();

        if (adminLoginData.username === "" || adminLoginData.password === '') {
            setIsInputEmpty(true)
        } else {
            setLoading(true);
            try{
                const response = await fetch(`${Constants.apiUrl}/parkingadmin/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': '',
                    },
                    body: JSON.stringify(adminLoginData)
                })
                
                const data = await response.json()
                setLoading(false);
                if (data.code >= 0) {
                    setAdminData(data);
                    setIsInputEmpty(false)
                    setIsAdminLoggedIn(true)
                    navigate(`/admin/${data.page}`);
                   // navigate('/admin/reservations');
                    const { user } = data
        
                    localStorage.setItem('adminDetails', JSON.stringify(user))
                    
                    const { token } = data;
        
                    setAdminToken(token)
        
                    localStorage.setItem('adminToken', token);
                    localStorage.setItem('tokenType', 'admin');
                    
        
                  }else {
                    setLoginError(data.status)
                  }
            } catch (error){
                setLoginError('Failed Login')
                setLoading(false);
            }

        }
    }

  return (
    <div className='admin-login'>
        <img src={LogInImg} alt="Log-In" className='main-img' loading='lazy'/>
        <div className="form-side">
            <div className="form-head">
                <Link to='/'><img src={Logo} alt="Link to home" /></Link>
            </div>
            <div className="form">
                <div className="heading">
                    <p>Welcome to <span>New Orleans Parking</span></p>
                    <h2>Log In</h2>
                </div>
                <form action="post">
                    <p className='error'>{loginError}</p>
                    <div className="input">
                        <label htmlFor="admin-username">Username</label>
                        <input type="text" id='admin-username' name='username' value={adminLoginData.username} onChange={handleChange} required/>
                    </div>
                    <div className="input">
                        <label htmlFor="admin-password">Password</label>
                        <input type="password" id='admin-password' name='password' value={adminLoginData.password} onChange={handleChange} required/>
                    </div>
                    <div className="check-remember">
                        <div className="checkbox">
                            <input type="checkbox" name="Remember Me" id="admin-check" />
                            <label htmlFor="admin-check">Remember Me</label>
                        </div>
                    </div>
                    <div className="btns">
                        { isInputEmpty ?  <p className="error">Please enter username and password</p> : null}
                        <button type='button' onClick={login}>Log In</button>
                        <p>Not a member yet? Contact Admin</p>
                    </div>
                </form>
                
                <ul className='social'>
                    <li><a href='https://twitter.com/AirportPark_Inc' rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faTwitter} /></a></li>
                    <li><a href='https://web.facebook.com/pages/Airport-Parking-Inc/149759451747371?_rdc=1&_rdr' rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faFacebook} /></a></li>
                    <li><a href='https://play.google.com/store/apps/details?id=com.ionicframework.airportapp357995&pli=1' rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faGooglePlus} /></a></li>
                </ul>

                <div className={ loading ? "congrats show" : "congrats"}>
                    <Loading message={'Logging in'}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Admin
import React, { useContext, useEffect, useState } from 'react'
import '../../styles/User/userprofile.css'
import TopBar from '../../components/User/TopBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { ShareContext } from '../../context/ShareContext';
import * as Constants from '../../constants';
import Expired from '../../components/Expired'
import ReactGA from 'react-ga';

const states = ['AB', 'AK', 'AL', 'AR', 'AZ', 'BC', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MB', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NB', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NL', 'NM', 'NS', 'NT', 'NU', 'NV', 'NY', 'OH', 'OK', 'ON', 'OR', 'PA', 'PE', 'PR', 'QC', 'RI', 'SC', 'SD', 'SK', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY', 'YT'];

function UserProfile() {
  const {userData, token} = useContext(ShareContext)

  const customerDetails = userData.c

  const [profile, setProfile] = useState('profile')
  const [isExpired, setIsExpired] = useState(false);

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    password: '',
    altPhone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
  })

  const [passwordReset, setPasswordReset] = useState({
    oldPassword: form.password,
    newPassword: '',
    retypeNewPassword: ''
  })

  useEffect(() => {
    if (customerDetails) {
        setForm({
            firstname: customerDetails.firstname || '',
            lastname: customerDetails.lastname || '',
            email: customerDetails.email || '',
            phone: customerDetails.phone || '',
            password: customerDetails.password || '',
            altPhone: customerDetails.altPhone || '',
            address1: customerDetails.address1 || '',
            address2: customerDetails.address2 || '',
            city: customerDetails.city || '',
            state: customerDetails.state || '', 
            zipcode: customerDetails.zipcode || ''
        })
    }
  }, [customerDetails])

  const formChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
  }

  const passwordChange = (e) => {
    setPasswordReset({...passwordReset, [e.target.name]: e.target.value})
  }

  const submitEdit = async(e) => {
    e.preventDefault();
    
    let newData = {...form};

    if (newData === form) {
        return null
    } else {
        try {
            const resp = await fetch(`${Constants.apiUrl}/reservation/customer/${customerDetails.email}`, {
              method: 'POST',
              body: JSON.stringify(newData),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token
              },
            });
            
            const responseData = await resp.json();

            console.log(responseData);

            if(responseData.code === 0){
                alert('Profile successfully updated');
                setForm({...form, newData})
            } else if (responseData.code === -10) {
                setIsExpired(true)
            } else(
                alert('Error', responseData.status)
            )
          } catch (error) {
            console.error(error);
          }
    }

  }

  const [passwordError, setPasswordError] = useState('')

  const resetSubmit = async(e) => {
    e.preventDefault();

    let newData = { oldpassword: passwordReset.oldPassword, newpassword: passwordReset.newPassword, userid: customerDetails.email}

    console.log(JSON.stringify(newData));

    if (passwordReset.oldPassword !== customerDetails.password) {
        setPasswordError('Old password does not match')
    } else if (passwordReset.newPassword !== passwordReset.retypeNewPassword){
        setPasswordError('New and Reset Password do not match')
    } else {
        try {
            const resp = await fetch(`${Constants.apiUrl}/reservation/changepassword`, {
              method: 'POST',
              body: JSON.stringify(newData),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token
              },
            });
            
            const responseData = await resp.json();

            console.log(responseData);


            if(responseData.code === 0){
                setPasswordError('Password successfully updated')
                setPasswordReset({
                    oldPassword: form.password,
                    newPassword: '',
                    retypeNewPassword: ''
                })
            } else if (responseData.code === -10) {
                setIsExpired(true)
            } else(
                setPasswordError(responseData.status)
            )
          } catch (error) {
            console.error(error);
          }
    }     
  }
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className='user-profile'>
        <TopBar />
        { isExpired && <div className={  'congrats show'}> <Expired mode='' /> </div>}
        

       { customerDetails && 
       <div className="body">
        {
            profile === 'profile' &&
            <div className="profile">
            <div className="header">
                <div className="name">
                    <FontAwesomeIcon icon={faUser} />
                    <h2>{form.firstname} <br /> {form.lastname}</h2>
                </div>
                <div className="actions">
                    <button onClick={() => setProfile('edit')}>Edit Profile</button>
                    <button onClick={() => setProfile('password')}>Change Password</button>
                </div>
            </div>
            <div className="details">
                <div className="row">
                    <p className="title">First Name</p>
                    <p className="answer">{form.firstname}</p>
                </div>
                <div className="row">
                    <p className="title">Last Name</p>
                    <p className="answer">{form.lastname}</p>
                </div>
                <div className="row">
                    <p className="title">E-mail</p>
                    <p className="answer">{form.email}</p>
                </div>
                <div className="row">
                    <p className="title">Phone Number</p>
                    <p className="answer">{form.phone}</p>
                </div>
                <div className="row">
                    <p className="title">Password</p>
                    <p className="answer">{form.password.replace(/./g, '*')}</p>
                </div>
                <div className="row">
                    <p className="title">Alt Phone</p>
                    <p className="answer">{form.altPhone}</p>
                </div>
                <div className="row">
                    <p className="title">Address</p>
                    <p className="answer">{form.address1}</p>
                </div>
                <div className="row">
                    <p className="title">Address(2)</p>
                    <p className="answer">{form.address2}</p>
                </div>
                <div className="row">
                    <p className="title">City</p>
                    <p className="answer">{form.city}</p>
                </div>
                <div className="row">
                    <p className="title">State</p>
                    <p className="answer">{form.state}</p>
                </div>
                <div className="row">
                    <p className="title">ZIP Code</p>
                    <p className="answer">{form.zipcode}</p>
                </div>
            </div>
            </div>
        } 
        {
            profile === 'edit' &&
            <div className="edit">
                <div className="header">
                    <div className="name">
                        <h2>{form.firstname} <br /> {form.lastname}</h2>
                    </div>
                    <div className="actions">
                        <button onClick={() => setProfile('profile')}>Back to Profile</button>
                        <button onClick={() => setProfile('password')}>Change Password</button>
                    </div>
                </div>
                <form className="create" onSubmit={submitEdit}>
                    <div className="inputs">
                        <div className="input">
                            <label htmlFor="user-firstname">First Name</label>
                            <input type="text" id='user-firstname' name='firstName' value={form.firstName} onChange={formChange}/>
                        </div>
                        <div className="input">
                            <label htmlFor="user-lastname">Last Name</label>
                            <input type="text" id='user-lastname' name='lastName' value={form.lastName} onChange={formChange}/>
                        </div>
                    </div>
                    <div className="inputs">
                        <div className="input">
                            <label htmlFor="user-email">Email</label>
                            <input type="email" id='user-email' name='email' value={form.email} onChange={formChange}/>
                        </div>
                        <div className="input">
                            <label htmlFor="user-password">Password</label>
                            <input type="password" id='user-password' name='password' disabled value={form.password}/>
                        </div>
                    </div>
                    <div className="inputs">
                        <div className="input">
                            <label htmlFor="user-phone">Phone</label>
                            <input type="tel" id='user-phone' name='phone' value={form.phone
                            } onChange={formChange}/>
                        </div>
                        <div className="input">
                          <label htmlFor="user-altphone">Alt. Phone</label>
                            <input type="tel" id='user-altphone' name='altPhone' value={form.altPhone} onChange={formChange}/>
                        </div>
                    </div>
                    <div className="input">
                        <label htmlFor="user-address">Address</label>
                        <input type="text" id='user-address' name='address1' value={form.address1} onChange={formChange}/>
                    </div>
                    <div className="input">
                        <label htmlFor="user-addressTwo">Address Two</label>
                        <input type="text" id='user-addressTwo' name='address2' value={form.address2} onChange={formChange}/>
                    </div>
                    <div className="inputs">
                        <div className="input">
                            <label htmlFor="user-city">City</label>
                            <input type="text" id='user-city' name='city' value={form.city} onChange={formChange}/>
                        </div>
                        <div className="input">
                            <label htmlFor="user-state">State</label>
                            <select name="state" id="user-state" value={form.state} onChange={formChange}>
                              <option value="" disabled>Select State/Province</option>
                              {
                                states.map((state, idx) => {
                                  return (
                                    <option key={idx} value={state}>{state}</option>
                                  )
                                })
                              }
                            </select>
                        </div>
                    </div>
                    <div className="input">
                        <label htmlFor="user-zip">ZIP Code</label>
                        <input type="text" id='user-zip' name='zip' value={form.zip} onChange={formChange}/>
                    </div>
                    <div className="btns">
                        <button type='submit'>Save</button>
                        <p className='goBack' onClick={() => setProfile('profile')}>Back to Profile</p>
                    </div>
                </form>
            </div>
        }  
        {
            profile === 'password' &&
            <div className="edit">
                <div className="header">
                    <div className="name">
                        <h2>{customerDetails.firstname} <br /> {customerDetails.lastname}</h2>
                    </div>
                    <div className="actions">
                        <button onClick={() => {
                            setProfile('profile')
                            setPasswordReset({
                                oldPassword: '',
                                newPassword: '',
                                retypeNewPassword: ''
                            })
                            }}>Back to Profile</button>
                        <button onClick={() => {
                            setProfile('edit')
                            setPasswordReset({
                                oldPassword: '',
                                newPassword: '',
                                retypeNewPassword: ''
                            })
                    }}>Edit Details</button>
                    </div>
                </div>
                <form className="create" onSubmit={resetSubmit}>
                    <div className="input">
                        <label htmlFor="oldPassword">Old Password</label>
                        <input type="password" value={passwordReset.oldPassword} id='oldPassword' name='oldPassword' onChange={passwordChange}/>
                    </div>
                    <div className="input">
                        <label htmlFor="newPassword">New Password</label>
                        <input type="password" id='newPassword' value={passwordReset.newPassword} name='newPassword' onChange={passwordChange}/>
                    </div>
                    <div className="input">
                        <label htmlFor="retypeNewPassword">Retype New Password</label>
                        <input type="password" id='retypeNewPassword' value={passwordReset.retypeNewPassword} name='retypeNewPassword' onChange={passwordChange}/>
                    </div>
                    <p className='passwordError'>{passwordError}</p>
                    <p className="note">New Password should be at least eight(8) characters and should contain letters, numbers and special characters</p>
                    <div className="btns">
                        <button type='submit'>Save</button>
                        <p className='goBack' onClick={() => {
                            setProfile('profile')
                            setPasswordReset({
                                oldPassword: '',
                                newPassword: '',
                                retypeNewPassword: ''
                            })
                    }}>Back to Profile</p>
                    </div>
                </form>
            </div>
        }
        </div>
        }
    </div>
  )
}

export default UserProfile
import React, { useContext, useState, useEffect } from 'react'
import '../../styles/User/tag.css'
import { useParams } from 'react-router-dom'
import { ShareContext } from '../../context/ShareContext';
import * as Constants from '../../constants';
import Loading from '../../components/Loading';
import Sidebar from '../../components/Admin/Sidebar';
import Head from '../../components/Admin/Head';
import Expired from '../../components/Expired'

function UPrintTag() {
    const {reservationId} = useParams() //this represent reservation

    // console.log(reservationId);

    const { token, isSidebarClosed } = useContext(ShareContext);

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [receiptData, setReceiptData] = useState({})
  const [isExpired, setIsExpired] = useState(false);


    // console.log(receiptData);

    useEffect(() => {
        setLoadingStatus(true);
        fetch(`${Constants.apiUrl}/reservation/receipt/${reservationId}`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Authorization': token,
          },
        })
          .then((resp) => resp.json())
          .then((data) => {
            
            console.log(data);
            if(data.code==0){
                setReceiptData(data);
                // setNumofdays(data.numofdays);
            }
            else if(data.code==-10){
                //logOut(); //possible the token is not available via context
                setIsExpired(true)
            }
            setLoadingStatus(false);
          })
          .catch((error) => {
            console.log(error);
            
          });
      }, [reservationId]);

  return (
    <div className='admin-body2'>
        {loadingStatus && <div className={ loadingStatus ? "congrats show" : "congrats"}>
                <Loading message={'Loading Full Receipt'} /> 
            </div>
        }
       {/*  <Sidebar /> */}
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
       {receiptData!=null && <div className="full-body">
                  <div className="all">
                    <h2 className='top-head'>Reservation Tag</h2>
                    <div className="tag-body">
                        <h3>{receiptData?.parkinglot?.parkinglotname +' of parking with ' + receiptData?.reservation?.carCount + ' car(s) at $' + receiptData?.reservation?.dailyrate + '/day/car.'  } </h3>
                        <h4>Parking Reservation for {receiptData?.customer?.firstname + ' ' + receiptData?.customer?.lastname}</h4>
                        <div className="barcode">
                        {(receiptData?.parkinglot?.generatebarcode == "Yes" && receiptData?.parkinglot?.barcodetype != "qrcode") && 
                        <img src={"http://www.barcodesinc.com/generator/image.php?code="+receiptData?.reservation?.barcodenum+"&style=196&type=C128C&width=150&height=80&xres=1&font=3"} />
                      }
                      {(receiptData?.parkinglot?.isAmano) &&  
                          <img alt="" style={{height:100, width:100, alignSelf:'center', margin:20}}  src={`https://chart.googleapis.com/chart?cht=qr&chl=AW${receiptData?.reservation?.transactionid}&chs=160x160&chld=L|0`} />
                      }
                      </div>
                        <div className="dates">
                            <p className='date'>{Constants.formatDate(receiptData?.reservation?.arrivaldate ) }</p>
                            <p>to</p>
                            <p className='date'>{Constants.formatDate(receiptData?.reservation?.returndate )}</p>
                        </div>
                        <div style={{textAlign:'center'}}>Plate Number: {receiptData?.reservation?.plateNumber}</div>
                        <button className='no-print' onClick={() => window.print()}>Print Tag</button>
                        
                    </div>
                  </div>
                </div>        } 
            
          
        </div>
    </div>
  )
}

export default UPrintTag
import React, {useEffect, useMemo, useState, useContext, useRef} from 'react'
import '../../styles/User/home.css'
import '../../styles/User/map.css'
import { Link } from 'react-router-dom'
import { ShareContext } from '../../context/ShareContext';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faBed, faCircleXmark, faCar, faMap } from '@fortawesome/free-solid-svg-icons'
import Bus from '../../assets/bus-cover.png'
import Plane from '../../assets/plane-cover.png';
import WaitArea from '../../assets/waiting-area.png';
import AirportForm from '../../components/User/AirportForm';
import Benefit1 from '../../assets/why-1.png';
import Benefit2 from '../../assets/why-2.png';
import Benefit3 from '../../assets/why-3.png';
import Benefit4 from '../../assets/why-4.png';
import OutrageousCabins from '../../assets/outrageouscabins-2.jpg';
import TrustBox from '../../components/trustbox';
import * as Constants from '../../constants';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';




function Home() {
    const navigate = useNavigate();
    const { searchData, isFormSubmitted, setIsFormSubmitted, setFormData, setSearchData, formData} = useContext(ShareContext);
    
   // const { airportList } = useContext(ShareContext)
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-90.074620);
    const [lat, setLat] = useState(29.951760);
    const [zoom, setZoom] = useState(9);
    const [loading, setLoading] =useState(false);
    const [places,setPlaces]=useState([]);
    const [lots, setLots]=useState([]);

    const [parkinglots, setParking] = useState({
        "type": "FeatureCollection",
        "features": []
    });


    Constants.seo({title:'New Orleans Parking Reservations | Quick And Easy Cruise Parking',
    metaDescription:'Find convenient parking solutions in New Orleans, including cruise and downtown parking. Reserve your spot online for a hassle-free experience'})

    const benefits = [
        {
            id: 1,
            pic: Benefit1,
            text: 'We offer the lowest New Orleans Port parking fees, saving you 40-70% off downtown and cruise parking',
        },
        {
            id: 2,
            pic: Benefit2,
            text: 'We offer complimentary shuttles to and from your New Orleans port terminal.',
        },
        {
            id: 3,
            pic: Benefit3,
            text: 'Your reservations are guaranteed at our privately owned facilities',
        },
        {
            id: 4,
            pic: Benefit4,
            text: 'Our parking facilities are safe and secure',
        }
    ]

   
    const [cancelAlert, setCancelAlert] = useState('alert-message')

  

    const { ref: whyRef, inView: isWhyInView } = useInView();
    const { ref: mainRef, inView: isMainInView } = useInView();
    const { ref: facilitiesRef, inView: isFacilitiesInView } = useInView()
    const { ref: placeRef, inView: isPlaceInView } = useInView();

    useEffect(() => {
          ReactGA.pageview(window.location.pathname + window.location.search); 
      });




    
    const currentDate = useMemo(() => {
        return new Date();
    }, []) 

    // Add a week (7 days) to the current date
    const nextWeek = new Date(currentDate);
    nextWeek.setDate(currentDate.getDate() + 7);

    // Format the next week date as a string in 'YYYY-MM-DD' format
    const formattedNextWeek = nextWeek.toISOString().split('T')[0];

    const data = useMemo(() => {
        return {
            "airportName": 'New Orleans Int Airport',
            "airportCode": 'MSY',
            "startDate": currentDate.toISOString().split('T')[0],
            "startTime": "12:00",
            "endDate": formattedNextWeek,
            "endTime": '12:00',
            "startdatetime":"",
            "enddatetime":"",
            "carCount": "1",
            "discountcode":"",
            "selectedlot":"",
            "corporatecode":"",
        }
    } , [ currentDate, formattedNextWeek]);

    data.startdatetime = data.startDate + ' ' + data.startTime;
    data.enddatetime = data.endDate + ' ' + data.endTime;

 

    useEffect(() => {
        setIsFormSubmitted(true);
        if(formData==null){
            setFormData(data); //so when back navigation is used, its not refreshed
        }
       
        setLoading(true);

        //  searchParking();
          loadPlaces();
          loadLots();

    }, [setIsFormSubmitted, data])

 const searchParking = async () => {
            try {

             // await  onOpenContent();
              const resp = await fetch(`${Constants.apiUrl}/reservation/search`, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                  'Content-Type': 'application/json',
                },
              });
          
              const responseData = await resp.json();
              setSearchData(responseData);
            //  setParking(responseData.parkings)

            //const the parkinglots object
            var features=[];
            responseData.parkings.forEach(item => {
                var feature = {
                "type": "Feature",
                "geometry": {
            "type": "Point",
                    "coordinates": [
                            item.longitude,
                            item.latitude
                    ]
                },
                "properties": {
                    "phoneFormatted": item.parkinglotphone,
                    "phone": item.parkinglotphone,
                    "address": item.address1+' '+ Constants.NullableStr (item.address2 ),
                    "parkinglotid": item.parkinglotid,
                    "city": "New Orleans",
                    "country": "United States",
                    //"crossStreet": "at 15th St NW",
                    //   "postalCode": "20005",
                    "state": "N.O",
                    "avg": Constants.formatCurrency(item.avgrate) ,
                    "parkinglotname": item.parkinglotname,
                    "parkingtype": item.parkingtype,
                    "payonline": Constants.formatCurrency(item.onlinepay),
                    "dueatlot": Constants.formatCurrency(item.dueatlot),
                    "buttonmsg": (item.booked == false && item.blocked == false)? "RESERVE" : ((item.booked == true)?"SOLD OUT":"UNAVAILABLE")
                     
                    }
            };
            features.push(feature);
            });
            
            setParking({...parkinglots,features });

            //end construct ***************
          
              if (responseData.code === 0) {
                setIsFormSubmitted(true);
              } else {
                console.log(responseData.status);
              }
                setLoading(false);
            } catch (error) {
              console.error(error);
                setLoading(false);
            }
          };

const loadPlaces=()=>{
    fetch(`${Constants.apiUrl}/reservation/cms/specialplaces`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        }
      })
      .then((resp) => resp.json())
      .then((data) =>  {
        console.log(data);
        setPlaces(data);
        
      })
      .catch((error) => {
        console.log(error);
        
      })
}
const loadLots=()=>{
    fetch(`${Constants.apiUrl}/reservation/cms/parkinglots`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        }
      })
      .then((resp) => resp.json())
      .then((data) =>  {
       
        setLots(data);
        
      })
      .catch((error) => {
        console.log(error);
        
      })
}
const onChangeTab=(d)=>{
        
    if(d=='cruise'){
        setFormData({...formData,reservationtype:d, rateOption:'daily', rateType:'daily'});
        navigate('/reservation/type/cruise');
    }else if(d=='hotel'){
        setFormData({...formData,reservationtype:d, rateOption:'daily', rateType:'daily'});
        navigate('/reservation/type/hotel');
    }else if(d=='downtown'){
        setFormData({...formData,reservationtype:d, rateOption:'hourly',rateType:'hourly'});
        navigate('/reservation/type/downtown');
    }
}
  return (
    <main>
       

        <div className={ isMainInView ? "main-box show" : "main-box"} ref={mainRef}>
           
           
            <div className="form">
            <h1 style={{color: '#fff', fontSize:'2.5em'}}>Where Do You Need to Park?</h1>
                <div class="col-md-12" style={{marginTop:24}}>
                   
                    <p style={{color:'white', fontSize:16, backgroundColor:'#00000080', padding:16, borderRadius:10}}>New Orleans Parking helps you save time and money on your cruise and hotel parking.</p>
                </div>
                <div className='transground'>
                 <div className='gentabs'>
                <div className='gentab'  onClick={()=>onChangeTab('cruise')}> <FontAwesomeIcon icon={faCar} /> Cruise Parking</div>
                <div className='gentab' onClick={()=>onChangeTab('hotel')}><FontAwesomeIcon icon={faBed} /> Hotel Parking</div>
                <div className='gentab' onClick={()=>onChangeTab('downtown')}><FontAwesomeIcon icon={faCar} /> Downtown Parking</div>
            </div>
                </div>
               
            </div>
        </div>
       
        <div  className={ isPlaceInView ? "why-place show" : "why-place"} ref={placeRef}>
            
            <div className="places">
                { places.map(place => {
                    return(
                        <div className="place" key={place.recordid}>
                            <h2>{place.title}</h2>
                            <div>
                            <Link to={`/places/${place.availableLink==null?place.recordid:place.availableLink}`}>
                            <img src={`${Constants.baseUrl}/resources/home/${place.imgPath}`} alt="Benefits of New Orleans Parking" loading='lazy'/> 
                            </Link>
                               
                            </div>
                            

                            <p>{place.subTitle}</p>
                        </div>
                    )
                })}
            </div>
        </div>

        <div className={ isWhyInView ? "why-airportinc show" : "why-airportinc"} ref={whyRef}>
            <h1>Why you should park with us?</h1>
            <div className="items">
                { benefits.map(benefit => {
                    return(
                        <div className="item" key={benefit.id}>
                            <img src={benefit.pic} alt="Benefits of New Orleans Parking" height={300} loading='lazy'/>
                            <p>{benefit.text}</p>
                        </div>
                    )
                })}
            </div>
        </div>
        <div className={ isFacilitiesInView ? "facilities show" : "facilities"} ref={facilitiesRef}>
           
            <div className="sponsors-ratings" >
                <div className="sponsors">
                <div className='googlemap'><div id="google-maps-display" className='display'><iframe className='iframe' frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=new+orleans,+la&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="googlemaps-made" href="https://kbj9qpmy.com/hrn" id="authmaps-data">New Orleans</a></div>
                   {/*  <h3>Looking for a getaway vacation?</h3>
                    <p>Check out Outrageous Cabins in Sevierville Tennessee today! </p>
                    <Link to="https://www.outrageouscabins.com/" target="_blank" rel='noreferrer'>
                        <img src={OutrageousCabins} alt="Outrageous Cabins" style={{height:200,width:200}} loading='lazy'/>
                    </Link> */}
                </div>
                <div className="ratings">
                {/* <div class="trustpilot-widget" data-locale="en-US" data-template-id="539ad0ffdec7e10e686debd7" data-businessunit-id="54a69f2a0000ff00057c74e0" data-style-height="350px" data-style-width="100%" data-theme="light" data-stars="4,5"></div> */}
                <TrustBox />
                </div>
                <div style={{flex:1, padding:24, alignItems:'center'}}>
                <h3>Facilities Where You Can Find Us:</h3>
                <ul style={{margin:10}}>
                {lots?.map((lot, id) => { return (
                    
                     <li style={{margin:16}}> <Link to={`/reservation/lot/${lot.parkinglotid}`}>{lot.parkinglotname}</Link></li>
                 )})}
                   
                </ul>
                </div>
            </div>
        </div>
        <div className={cancelAlert}>
            <h3>Customer service hours: 8 AM to 8 PM Monday - Sunday</h3>
            <div className="terms-home">
                <Link to='/terms-of-use'>See Cancellation Policy in Terms of Use</Link>
                <p>| $5.00 service fee is not refundable</p>
            </div>
            <FontAwesomeIcon icon={faCircleXmark} className='cancel-alert' onClick={() => setCancelAlert('alert-message cancel')}/>
        </div>
    </main>
  )
}

export default Home
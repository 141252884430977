import React, {useContext, useState,useEffect} from 'react'
import '../../styles/User/modifyreserve.css'
import TopBar from '../../components/User/TopBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-regular-svg-icons'
import { ShareContext } from '../../context/ShareContext';
import { useParams } from 'react-router-dom'
import { timeArray } from '../../data/timeArray';
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/Loading'
import * as Constants from '../../constants';
import Expired from '../../components/Expired'
import ReactGA from 'react-ga';
import ApiAlert from '../../components/ApiAlert';

function CancelReservation() {
    const {reservationId} = useParams()
    const navigate = useNavigate()
// console.log('reservationId vslue: '+reservationId);
    const {formData,  setFormData, airportList, token, setSearchData, setIsFormSubmitted, setReservationId} = useContext(ShareContext);
    const [item,setItem]=useState({});
    const [loadingStatus, setLoadingStatus] =useState(true);
    const [numofdays, setNumofdays]=useState(0);
    const [isExpired, setIsExpired] = useState(false);
    const [reason, setReason] =useState('');
    const [alert, setAlert] =useState({open:false, title:'', subtitle:''})
    const [isLoading, setIsLoading]= useState(false);

   // console.log('all caried forward reservations');
   // console.log(reservations);
  //  const item = reservations.find(reserve => reserve.transactionid === parseInt(transactionId));
     

    

    const [updateReserve, setUpdateReserve] = useState({
        "airportName": '',
        "airportCode": "",
        "startDate": '',
        "startTime": '',
        "endDate": '',
        "endTime": '',
        "startdatetime":"",
        "enddatetime":"",
        "carCount": 1,
        "discountcode":"",
        "selectedlot":"",
        "corporatecode":"", 
    })

    const initValues=(resv)=>{
        setItem(resv);
        var fd={
            "airportName": resv.airport,
            "airportCode": resv.airportcode,
            "startDate": Constants.dateOnly(resv.arrivaldate) ,
            "startTime": resv.arrivaltime,
            "endDate": Constants.dateOnly(resv.returndate),
            "endTime": resv.returntime,
            "startdatetime":resv.arrivaldate ,
            "enddatetime":resv.returndate ,
            "carCount": resv.carCount,
            "discountcode":"",
            "selectedlot":"",
            "corporatecode":"", 
            "carDetails":resv.carDetails,
            "plateNumber":resv.plateNumber,
            "personparking":resv.Personparking,
            "vesselname": resv.vesselname
        };
        setUpdateReserve(fd);
        // console.log('at initial the form value');
        // console.log(fd);
    }
    useEffect(() => {
        setLoadingStatus(true);
        fetch(`${Constants.apiUrl}/reservation/${reservationId}`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Authorization': token,
          },
        })
          .then((resp) => resp.json())
          .then((data) => {
            
            // console.log(data);
            if(data.code==0){
                initValues(data.reservation);
                setNumofdays(data.numofdays);
            }
            else if(data.code==-10){
                //logOut(); //possible the token is not available via context
                setIsExpired(true)
            }
            setLoadingStatus(false);
          })
          .catch((error) => {
            console.log(error);
            
          });
      }, [reservationId]);

    const onOk=()=>{
        setAlert({open:false})
        navigate('/reservation')
    }

    const submit = async(e) => {
        e.preventDefault();
    

        var dd={reason}
      setIsLoading(true)
      //  console.log(JSON.stringify(dd));
        try {
            const resp = await fetch(`${Constants.apiUrl}/reservation/cancel/${item.reservationid}?reason=${reason}`, {
              method: 'POST',
              body: reason,
              headers: {
                'Content-Type': 'application/json',
                'Authentication': token
              },
            });
            
            const responseData = await resp.json();
            setIsLoading(false);
            console.log(responseData);
            setSearchData(responseData)
  
            if (responseData.code === 0) {
                //navigate('/reservation/search/'+item.reservationid)
                setAlert({open:true, title:'Cancel Reservation Request', subtitle:"Cancellation successful. We hope to see you again soon. Our system will keep getting better.", onOk:onOk})

            }else if (responseData.code === -10) {
                setIsExpired(true)
            }else {
              console.log(responseData.status);
              setAlert({open:true, title:'Cancel Reservation Request', subtitle:responseData.status, onOk:onOk})

            }
          } catch (error) {
            setIsLoading(true);
            console.error(error);
          }
    }




ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className='modify-reservations'>
        <TopBar />
        { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}

       {loadingStatus && <div className={ loadingStatus ? "congrats show" : "congrats"}>
                <Loading message='Loading Reservation' />
            </div>}
      {!loadingStatus && <div className="body">
            <h1>Cancel your reservation</h1>
            <p>You may cancel reservation, however the payment is not refundable within 48 hours of your scheduled arrival of  
            {Constants.formatDateTime(item?.arrivaldate)} </p>
            <p>We would like the reason for the cancelation in the text box below, in order to better our customer's needs.</p>
            <div className="form-receipt">
                <form onSubmit={submit}>
                   
                    <div className="input">
                        <label htmlFor="modify-arrivalDate">Reason for cancellation</label>
                        <textarea style={{width:'90%', padding:8, height:100}} onChange={(e)=> setReason(e.target.value)}  required>

                        </textarea>
                        
                    </div>
                   
                    <button className="save-btn" type='submit' >Cancel Reservation <FontAwesomeIcon icon={faSave} /></button>
                </form>
                <div className="receipt">
                    <div className="header">
                        <h3>Reservation Details</h3>
                        <p>{item?.parkinglotname} - {numofdays} days of parking with {item?.carCount} car at ${item?.dailyrate}/day/car</p>
                    </div>
                    <div className="receipt-details">
                        <div className="each-detail">
                            <p className="title">Check In</p>
                            <p className="title-info">{Constants.formatDateTime(item?.arrivaldate)}</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Check Out</p>
                            <p className="title-info">{Constants.formatDateTime(item?.returndate)}</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Sub Total</p>
                            <p className="title-info">${Constants.formatCurrency(item?.totalpaid) }</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Taxes & Fees</p>
                            <p className="title-info">${Constants.formatCurrency(item?.tax) }</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Total</p>
                            <p className="title-info total">${Constants.formatCurrency(item?.totalpaid)}</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Paid Online</p>
                            <p className="title-info paid-online">${Constants.formatCurrency(item?.totalpaid)}</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Due at Lot</p>
                            <p className="title-info due">${Constants.formatCurrency(item?.payatlot)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        {isLoading && <div className={ "congrats show" }>
                <Loading message='Processing' />
            </div>}    
        {alert.open && <div className={ "congrats show" }>
                <ApiAlert {...alert} />  
            </div> } 
    </div>
  )
}

export default CancelReservation
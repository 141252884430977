import React, { useContext, useState, useRef,useEffect }  from 'react';
import '../../styles/Admin/managediscount.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faRotateRight, faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter} from '@fortawesome/free-solid-svg-icons'
import { ShareContext } from '../../context/ShareContext';
import { useNavigate } from 'react-router-dom';
import * as Constants from '../../constants';
import Loading from '../../components/Loading';
import Expired from '../../components/Expired';

function ManageDiscount() {
    const { adminToken,isSidebarClosed } = useContext(ShareContext);
    const [expandForm, setExpandForm] = useState(false)
    const [openCreate, setOpenCreate] = useState(false);
    const [toggleView, setToggleView] = useState(true);
    const [whichForm, setWhichForm] = useState('create');
    const [isLoading, setIsLoading]= useState(false);
    const [parkinglots, setParkinglots]=useState([]);
    

    const globalObj = {
        id: '',
        description: '',
        numOfBookedDays: '',
        numOfFreeDays: '',
        maxFreeDays: '',
        parkingLot: ''
    }

    const discountObj = {
        code: '',
        description: '',
        discountType: '',
        discountAmount: '',
        startDate: '',
        endDate: '',
        minReservation: ''
    }

    const onParkinglots=()=>{
        
        fetch(`${Constants.apiUrl}/parkingadmin/parkinglots`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
          
          })
          .then((resp) => resp.json())
          .then((data) =>  {
            
            if(data.code==0){
                setParkinglots(data.lots)
            }else if (data.code==-10){
              setIsExpired(true);
          }
            // console.log(jresp);
            
          })
          .catch((error) => {
            
            console.log(error);
          })
      }
    const [globalForm, setGlobalForm] = useState(globalObj)
    const [discountForm, setDiscountForm] = useState(discountObj)
    const [editData, setEditData] = useState({});
    const [editDisc, setEditDisc] = useState({})
    const [discTable, setDiscTable] = useState();
    const [globalTable, setGlobalTable] = useState();
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false)
    const [isExpired, setIsExpired] = useState(false);
    const [discounts, setDiscounts]= useState([]);

    const getGlobalRow = (id) => {
        const data = globalTable.find(item => item.id === id)

        return data
    }

    const getDiscountRow = (id) => {
        const data = discTable.find(item => item.code === id)

        return data
    }

    const editGlobal = (id) => {
        setWhichForm('edit');
        setOpenCreate(true)

        const neededData = getGlobalRow(id)
        setEditData(neededData)
    }

    const editDiscount = (id) => {
        setWhichForm('edit');
        setOpenCreate(true)

        const neededData = getDiscountRow(id)
        setEditDisc(neededData)
    }

    const editChange = (e) => {
        setEditData({...editData, [e.target.name]:e.target.value})
    }

    const editDiscChange = (e) => {
        setEditDisc({...editDisc, [e.target.name]: e.target.value})
    }

    const createGlobalChange = (e) => {
        setGlobalForm({...globalForm, [e.target.name]: e.target.value})
    }

    const createDiscountChange = (e) => {
        setDiscountForm({...discountForm, [e.target.name]: e.target.value})
    }

    const closePopUp = () => {
        setOpenCreate(false) 
        setGlobalForm(globalObj)
        setDiscountForm(discountObj)
    }

    const openCreateForm = () => {
        setOpenCreate(true)
        setWhichForm('create')
    }

    // Helper function to add ordinal suffix to the day
    function addOrdinalSuffix(day) {
        if (day >= 11 && day <= 13) {
          return `${day}th`;
        }
      
        switch (day % 10) {
          case 1:
            return `${day}st`;
          case 2:
            return `${day}nd`;
          case 3:
            return `${day}rd`;
          default:
            return `${day}th`;
        }
      }

    function convertToHumanReadableDate(htmlDateFormat) {
        const dateObject = new Date(htmlDateFormat);
      
        const day = dateObject.getDate();
        const monthIndex = dateObject.getMonth();
        const year = dateObject.getFullYear();
      
        const months = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
      
        const monthName = months[monthIndex];
      
        // Add ordinal suffix to the day
        const dayWithSuffix = addOrdinalSuffix(day);
      
        // Construct the human-readable date format
        const humanReadableDate = `${dayWithSuffix} ${monthName}, ${year}`;
      
        return humanReadableDate;
      }

      const openDeleteBox = (id) => {
        setIsDialogBoxOpen(true);
    
        let data = '';

        if(!toggleView){
            data = getGlobalRow(id)
        } else if (toggleView){
            data = getDiscountRow(id)
        }
    
        setEditData(data)
    }

    const closeDialogBox = () => {
        setIsDialogBoxOpen(false);
    }

    const deleteGlobal = (id) => {
        const updatedArray = globalTable.filter(item => item.id !== id);
        setGlobalTable(updatedArray)
      
        setIsDialogBoxOpen(false)
    }

    const deleteDisc = (id) => {
        const updatedArray = discTable.filter(item => item.code !== id)
        setDiscTable(updatedArray)

        setIsDialogBoxOpen(false)
    }

    const onLoadDiscount=()=>{
        setIsLoading(true);
        fetch(`${Constants.apiUrl}/parkingadmin/discounts`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
          
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
            setIsLoading(false);
            if(jresp.code==0){
                console.log(jresp);
                setDiscounts(jresp.dc);
            }else if (jresp.code==-10){
                setIsExpired(true);
            }else{
                alert(jresp.status)
            }
            
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          })
      }

      useEffect(() => {
        //Runs only on the first render
       // var sToken=localStorage.getItem("accessToken");
       onLoadDiscount();
       onParkinglots();
      }, []);

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
          { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}
            <div className="manage-discount">
                <div className="head">
                    <h1>Discount Management</h1>
                    <p>General Discount</p>
                </div>
                {isLoading && <div className={isLoading ? 'congrats show' : 'congrats'}>
                    <Loading message={'Loading Discounts'} />    
                </div>}
                <div className="body-wrapper">
                    <div className="head-block">
                        <h3>{ toggleView ? 'Discounts' : 'Global Discount'}</h3>
                        <div className="head-icons">
                            <FontAwesomeIcon icon={faRotateRight} className='action-icon refresh'/>
                        </div>
                    </div>
                    <div className="adding-client">
                        <button className='global' onClick={() => openCreateForm()}>{ toggleView ? 'New Discount' : 'New Global Discount'}</button>
                        <button className='manage' onClick={() => setToggleView(!toggleView)}>{ toggleView ? 'Manage Global Discount' : 'Manage Discount'}</button>
                    </div>
                    <div className="table-wrapper">
                        {
                            toggleView ? <table>
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Description</th>
                                    <th>Discount Type</th>
                                    <th>Discount Amount</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Minimum Reservation</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                  discounts.length>0 &&  discounts?.map((row, idx) => { return(
                                        <tr key={idx}>
                                            <td>{row.discountid}</td>
                                            <td>{row.description}.</td>
                                            <td>{row.discounttype}</td>
                                            <td>${row.discountamount}</td>
                                            <td>{Constants.formatDate(row.startDate)}</td>
                                            <td>{Constants.formatDate(row.endDate)}</td>
                                            <td>{row.minReservation}</td>
                                            <td>
                                                <ul>
                                                    <li onClick={() => editDiscount(row.discountid)}>Edit</li>
                                                    <li className='delete' onClick={() => openDeleteBox(row.discountid)}>Delete</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </table> : 
                        <table>
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Days Booked</th>
                                    <th>Days Free</th>
                                    <th>Max Free Days</th>
                                    <th>Parking Lot</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    globalTable?.map((row, idx) => { return( 
                                        <tr key={idx}>
                                            <td>{row.description}.</td>
                                            <td>{row.numOfBookedDays}</td>
                                            <td>{row.numOfFreeDays}</td>
                                            <td>{row.maxFreeDays}</td>
                                            <td>{row.parkingLot}</td>
                                            <td>
                                                <ul>
                                                    <li onClick={() => editGlobal(row.id)}>Edit</li>
                                                    <li className='delete' onClick={() => openDeleteBox(row.id)}>Delete</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </table>
                        }
                    </div>
                    <div className={ openCreate ? "create-wrapper active" : "create-wrapper"}>
                        <div className="close-it" onClick={() => closePopUp()}>
                            <FontAwesomeIcon icon={faClose} />  
                        </div>
                        { whichForm === 'create' &&<div className={expandForm ? "create-account expanded": "create-account"}>
                            <div className="create-header">
                                <h3>{ toggleView ? 'New Discount' : 'New Global Discount'}</h3>
                                <FontAwesomeIcon icon={expandForm ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} className='close-create' onClick={() => setExpandForm(!expandForm)}/>
                            </div>
                            {
                                toggleView ? 
                                <form method='post'>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="discountCode">Discount Code</label>
                                        <input type="text" id='discountCode' name='code' value={discountForm.discountid} onChange={createDiscountChange} required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="discountType">Discount Type</label>
                                        <input type="text" id='discountType' name='discountType' value={discountForm.discounttype} onChange={createDiscountChange} required/>
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="description">Description</label>
                                    <input type="text" id='description' name='description' value={discountForm.description} onChange={createDiscountChange} required/>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="discountAmount">Discount Amount</label>
                                        <input type="number" id='discountamount' name='discountamount' value={discountForm.discountamount} onChange={createDiscountChange} required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="minReservation">Minimum Reservation</label>
                                        <input type="number" id='minimumreservation' name='minimumreservation' value={discountForm.minimumreservation} onChange={createDiscountChange} required/>
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="startDate">Start Date</label>
                                        <input type="date" id='startDate' name='startdate' value={discountForm.startdate} onChange={createDiscountChange} required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="endDate">End Date</label>
                                        <input type="date" id='endDate' name='enddate' value={discountForm.enddate} onChange={createDiscountChange} required/>
                                    </div>
                                </div>
                                <div className="btns">
                                    <button type='submit'>Save Discount</button>
                                    <p className="goBack" onClick={() => closePopUp()}>Back to Discounts</p>
                                </div>
                            </form> : 
                            <form method='post'>
                                <div className="input">
                                    <label htmlFor="description">Description</label>
                                    <input type="text" id='description' name='description' value={globalForm.description} onChange={createGlobalChange} required/>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="bookedDays">Number of Booked Days</label>
                                        <input type="number" id='bookedDays' name='numOfBookedDays' value={globalForm.numOfBookedDays} onChange={createGlobalChange} required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="freeDays">Number of Free Days</label>
                                        <input type="number" id='freeDays' name='numOfFreeDays' value={globalForm.numOfFreeDays} onChange={createGlobalChange} required/>
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="maxFreeDays">Maximum Free Days</label>
                                        <input type="number" id='maxFreeDays' name='maxFreeDays' value={globalForm.maxFreeDays} onChange={createGlobalChange} required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="parkingLot">Parking Lot</label>
                                        <select name="parkingLot" id="parkingLot" value={globalForm.parkingLot} onChange={createGlobalChange}>
                                            <option value="All">All Parking Lot</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="btns">
                                    <button type='submit'>Save Global Discount</button>
                                    <p className="goBack" onClick={() => closePopUp()}>Back to Global</p>
                                </div>
                            </form>
                            }
                        </div>}
                        { whichForm === 'edit' &&<div className={expandForm ? "create-account expanded": "create-account"}>
                            <div className="create-header">
                                <h3>{ toggleView ? 'Edit Discount' : 'Edit Global Discount'}</h3>
                                <FontAwesomeIcon icon={expandForm ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} className='close-create' onClick={() => setExpandForm(!expandForm)}/>
                            </div>
                            {
                                toggleView ? 
                                <form method='post'>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="discountCode">Discount Code</label>
                                        <input type="text" id='discountCode' name='code' value={editDisc.code} onChange={editDiscChange} required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="discountType">Discount Type</label>
                                        <input type="text" id='discountType' name='discountType' value={editDisc.discountType} onChange={editDiscChange} required/>
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="discountDescription">Description</label>
                                    <input type="text" id='discountDescription' name='description' value={editDisc.description} onChange={editDiscChange} required/>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="discountAmount">Discount Amount</label>
                                        <input type="number" id='discountAmount' name='discountAmount' value={editDisc.discountAmount} onChange={editDiscChange} required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="minReservation">Minimum Reservation</label>
                                        <input type="number" id='minReservation' name='minReservation' value={editDisc.minReservation} onChange={editDiscChange} required/>
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="startDate">Start Date</label>
                                        <input type="date" id='startDate' name='startDate' value={editDisc.startDate} onChange={editDiscChange} required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="endDate">End Date</label>
                                        <input type="date" id='endDate' name='endDate' value={editDisc.endDate} onChange={editDiscChange} required/>
                                    </div>
                                </div>
                                <div className="btns">
                                    <button type='submit'>Save Discount</button>
                                    <p className="goBack" onClick={() => closePopUp()}>Back to Discount</p>
                                </div>
                            </form> : 
                            <form method='post'>
                                <div className="input">
                                    <label htmlFor="description">Description</label>
                                    <input type="text" id='description' name='description' value={editData.description} onChange={editChange} required/>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="bookedDays">Number of Booked Days</label>
                                        <input type="number" id='bookedDays' name='numOfbookedDays' onChange={editChange} value={editData.numOfBookedDays}  required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="freeDays">Number of Free Days</label>
                                        <input type="number" id='freeDays' name='numOffreeDays' onChange={editChange} value={editData.numOfFreeDays}  required/>
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="maxFreeDays">Maximum Free Days</label>
                                        <input type="number" id='maxFreeDays' name='maxFreeDays' onChange={editChange} value={editData.maxFreeDays}  required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="parkingLot">Parking Lot</label>
                                        <select name="parkingLot" id="parkingLot" onChange={editChange} value={editData.parkingLot}>
                                            <option value="All">All Parking Lot</option>
                                            {
                                            parkinglots?.map((lot, idx) => <option key={idx} value={lot.parkinglotid}>{lot.parkinglotname}</option>)
                                        }
                                        </select>
                                    </div>
                                </div>
                                <div className="btns">
                                    <button type='submit'>Save Global Discount</button>
                                    <p className="goBack" onClick={() => closePopUp()}>Back to Global</p>
                                </div>
                            </form>
                            }
                        </div>}
                    </div>
                </div>
            </div>
            { toggleView ? <div className={ isDialogBoxOpen ? "delete-wrapper show" : "delete-wrapper"}>
                <div className="dialog">
                    <div className="head">
                        <h4>Delete Discount</h4>
                        <FontAwesomeIcon icon={faClose} onClick={() => closeDialogBox()}/>
                    </div>
                    <div className="main">
                        <h3>Are you sure you want to delete this Discount?</h3>
                        <p>This action will permanently delete this Discount. Please click 'Delete Discount' to continue or Cancel to abort!</p>
                        <div className="btns">
                            <button onClick={() => deleteDisc(editData.code)}>Delete Discount</button> 
                            <p onClick={() => closeDialogBox()}>Cancel</p>
                        </div>
                    </div>
                </div>
            </div> :
            <div className={ isDialogBoxOpen ? "delete-wrapper show" : "delete-wrapper"}>
                <div className="dialog">
                    <div className="head">
                        <h4>Delete Global Discount</h4>
                        <FontAwesomeIcon icon={faClose} onClick={() => closeDialogBox()}/>
                    </div>
                    <div className="main">
                        <h3>Are you sure you want to delete this Global Discount ?</h3>
                        <p>This action will permanently delete this Global Discount. Please click 'Delete Global Discount' to continue or Cancel to abort!</p>
                        <div className="btns">
                           <button onClick={() => deleteGlobal(editData.id)}>Delete Global Discount</button>
                            <p onClick={() => closeDialogBox()}>Cancel</p>
                        </div>
                    </div>
                </div>
            </div>}
        </div>

    </div>
  )
}

export default ManageDiscount
import React, {useEffect,  useState, useContext} from 'react'
import '../../styles/User/directions.css'
import { airportCodes } from '../../data/airportCodes'
import TopBar from '../../components/User/TopBar'
import * as Constants from '../../constants';
import ReactGA from 'react-ga';
import { useParams, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function ArticlePage() {
    const {url}=useParams();
    const [lots, setLots]=useState([]);
    const [selectedLots, setSelectedLots]=useState([]);
    const [article, setArticle]=useState({});
    const [html, setHtml]=useState({});

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search); 
        
        loadArticle();
    },[url]);
 

        const loadArticle=()=>{

          var finalurl= `${Constants.apiUrl}/reservation/cms/articles/${url}`;

            fetch(finalurl, {
                method: 'GET',
                headers: {
                  'Content-type': 'application/json',
                }
              })
              .then((resp) => resp.json())
              .then((data) =>  {
               
                if(data!=null){
                  setArticle(data);
                    Constants.seo({title:data.title, metaDescription:data.metaDesc});
                    setHtml(Constants.b64decode(data.articleHtml) )
                    
                }
                    
                
              })
              .catch((error) => {
                console.log(error);
                
              })
            }


  return (
    <div className='directions'>
        <TopBar />
        <div className="main-page">
          <h1>{article.title}</h1>
          <h3>{article.subTitle}</h3>
        <div dangerouslySetInnerHTML={{__html: html }}></div>
        
        </div>
    </div>
  )
}

export default ArticlePage

import React from 'react'
/* import LoadingImg from '../assets/loading.gif'; */
import '../styles/loading.css'
import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

function ApiAlert(props) {
  //mode =admin/customer
 // const navpath= mode =='admin'?'/admin': '/login';
  // const navigate = useNavigate()
  // navigate(navpath);
  return (
    <div className='load-container' style={{width:600}}>
        <h2>{props.title}</h2>
        <h2 className='small-head' style={{margin:16, padding:16}}>{props.subtitle}</h2>
       {/*  <img src={LoadingImg} width='200px' alt="Loading" /> */}
       <button onClick={props.onOk} className='specialbutton2'>Ok</button>
       

    </div>
  )
}

export default ApiAlert
import React, { useState, useContext } from 'react'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import '../../styles/Admin/submittedtickets.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { ShareContext } from '../../context/ShareContext'

function SubmittedTickets() {
    const { isSidebarClosed } = useContext(ShareContext)

    const reportObj = {
        airport: '',
        parkingLot: '',
        client: '',
        email: '',
        category: '',
        reservationId: '',
        comment: '',
        status: '',
        submittedDate: ''
    }
    
    const fakeReports = [
        // {
        //     airport: 'ATL',
        //     parkingLot: 'ATLCrowne',
        //     client: 'John Doe',
        //     email: 'johndoe@yahoo.com',
        //     category: 'Cancellations',
        //     reservationId: 'RNA35284T8U',
        //     comment: 'Bill once system implied not completed with card. Used another card was accepted. Please cancel refund full amount.',
        //     status: 'Open',
        //     submittedDate: '2020-05-10',
        //     submittedTime: '09:15 AM'
        // },
        // {
        //     airport: 'ATL',
        //     parkingLot: 'ATLCrowne',
        //     client: 'John Una',
        //     email: 'johndoe@yahoo.com',
        //     category: 'Cancellations',
        //     reservationId: 'RNA35284T8U',
        //     comment: 'Bill once system implied not completed with card. Used another card was accepted. Please cancel refund full amount.',
        //     status: 'Open',
        //     submittedDate: '2020-05-10',
        //     submittedTime: '09:15 AM'
        // },
        // {
        //     airport: 'ATL',
        //     parkingLot: 'ATLCrowne',
        //     client: 'John Rasak',
        //     email: 'johndoe@yahoo.com',
        //     category: 'Cancellations',
        //     reservationId: 'RNA35284T8U',
        //     comment: 'Bill once system implied not completed with card. Used another card was accepted. Please cancel refund full amount.',
        //     status: 'Open',
        //     submittedDate: '2020-05-10',
        //     submittedTime: '09:15 AM'
        // },
        // {
        //     airport: 'ATL',
        //     parkingLot: 'ATLCrowne',
        //     client: 'Gloria Doe',
        //     email: 'johndoe@yahoo.com',
        //     category: 'Cancellations',
        //     reservationId: 'RNA35284T8U',
        //     comment: 'Bill once system implied not completed with card. Used another card was accepted. Please cancel refund full amount.',
        //     status: 'Open',
        //     submittedDate: '2020-05-10',
        //     submittedTime: '09:15 AM'
        // }
    ]

    const [reports, setReports] = useState(fakeReports)
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false)
    const [rowData, setRowData] = useState(reportObj)

    const [action, setAction] = useState('') ;

    const openEditBox = (id) => {
        setAction('edit')
        setIsDialogBoxOpen(true);

        const mainReport = reports.find(item => item.client === id)

        setRowData(mainReport)
    }

    const openDeleteBox = (id) => {
        setAction('delete')
        setIsDialogBoxOpen(true);

        const mainReport = reports.find(item => item.client === id)

        setRowData(mainReport)
    }

    const deleteIt = (id) => {
        const updatedArray = reports.filter(obj => obj.client !== id)

        setReports(updatedArray)
        setIsDialogBoxOpen(false)
    }

    const closeDialogBox = () => {
        setIsDialogBoxOpen(false)
    }

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            <div className="report-web">
                <h2>Submitted Tickets</h2>
                <div className="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th>Airport</th>
                                <th>Parking Lot</th>
                                <th>Client</th>
                                <th>E-mail</th>
                                <th>Category</th>
                                <th>Reservation ID</th>
                                <th>Comment</th>
                                <th>Status</th>
                                <th>Submitted Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reports.map((report, idx) => { return(
                                    <tr key={idx}>
                                        <td>{report.airport}</td>
                                        <td>{report.parkingLot}</td>
                                        <td>{report.client}</td>
                                        <td>{report.email}</td>
                                        <td>{report.category}</td>
                                        <td>{report.reservationId}</td>
                                        <td>{report.comment}</td>
                                        <td>{report.status}</td>
                                        <td>{report.submittedDate} <br /> {report.submittedTime}</td>
                                        <td>
                                            <ul>
                                                <li onClick={() => openEditBox(report.client)}>Reply</li>
                                                <li className='delete' onClick={() => openDeleteBox(report.client)}>Delete</li>
                                            </ul>
                                        </td>
                                    </tr>
                                )})
                            }
                        </tbody>
                    </table>
                </div>
                <div className={ isDialogBoxOpen ? "delete-wrapper show" : "delete-wrapper"}>
                    { action === 'delete' && <div className="dialog">
                        <div className="head">
                            <h4>Delete Ticket</h4>
                            <FontAwesomeIcon icon={faClose} onClick={() => closeDialogBox()}/>
                        </div>
                        <div className="main">
                            <h3>Are you sure you want to delete this { rowData.client }'s ticket?</h3>
                            <p>This action will permanently delete this Ticket. Please click 'Delete Ticket' to continue or Cancel to abort!</p>
                            <div className="btns">
                                <button onClick={() => deleteIt(rowData.client)}>Delete Ticket</button>
                                <p onClick={() => closeDialogBox()}>Cancel</p>
                            </div>
                        </div>
                    </div>}
                    {
                        action === 'edit' && <div className="dialog">
                            <div className="head">
                                <h4>Edit Ticket</h4>
                                <FontAwesomeIcon icon={faClose} onClick={() => closeDialogBox()}/>
                            </div>
                            <form>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="airport">Departure Airport</label>
                                        <input type="text" disabled id='airport' value={rowData.airport} name='airport'/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="client">Client</label>
                                        <input type="text" disabled id='client' value={rowData.client} name='client'/>
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" disabled id='email' value={rowData.email} name='email'/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="category">Category</label>
                                        <input type="text" disabled id='category' value={rowData.category} name='category'/>
                                    </div>
                                </div>
                                <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="parkingLot">Parking Lot</label>
                                        <input type="text" disabled id='parkingLot' value={rowData.parkingLot} name='parkingLot'/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="reservationId">Reservation ID</label>
                                        <input type="text" disabled id='reservationId' value={rowData.reservationId} name='reservationId'/>
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="comment">Comments / Questions</label>
                                    <textarea name="comment" id="comment" value={rowData.comment} disabled></textarea>
                                </div>
                                <div className="input">
                                    <label htmlFor="status">Status</label>
                                    <select name="status" id="status" value={rowData.status}>
                                        <option value="Open">Open</option>
                                        <option value="Close">Close</option>
                                        <option value="Defer">Defer</option>
                                    </select>
                                </div>
                                <div className="input">
                                    <label htmlFor="adminComment">Admin Comments</label>
                                    <textarea name="adminComment" id="adminComment" ></textarea>
                                </div>
                                <button type='submit'>Save Ticket</button>
                            </form>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default SubmittedTickets
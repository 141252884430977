import React, {useEffect, useMemo, useState, useContext, useRef} from 'react'
import '../../styles/User/home.css'
import '../../styles/User/map.css'
import { Link } from 'react-router-dom'
import { ShareContext } from '../../context/ShareContext';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faCircleXmark, faLocationDot, faMap } from '@fortawesome/free-solid-svg-icons'
import Bus from '../../assets/bus-cover.png'
import Plane from '../../assets/plane-cover.png';
import WaitArea from '../../assets/waiting-area.png';
import AirportForm from '../../components/User/AirportForm';
import Benefit1 from '../../assets/why-1.png';
import Benefit2 from '../../assets/why-2.png';
import Benefit3 from '../../assets/why-3.png';
import Benefit4 from '../../assets/why-4.png';
import OutrageousCabins from '../../assets/outrageouscabins-2.jpg';
import TrustBox from '../../components/trustbox';
import * as Constants from '../../constants';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';


import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = Constants.MAPBOX_ACCESS_TOKEN;


function LandingPage() {
    const navigate = useNavigate();
    const { searchData, isFormSubmitted, setIsFormSubmitted, setFormData, setSearchData, formData} = useContext(ShareContext);
    
   // const { airportList } = useContext(ShareContext)
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-90.074620);
    const [lat, setLat] = useState(29.951760);
    const [zoom, setZoom] = useState(9);
    const [loading, setLoading] =useState(false);

    const [parkinglots, setParking] = useState({
        "type": "FeatureCollection",
        "features": []
    });


   
    const benefits = [
        {
            id: 1,
            pic: Benefit1,
            title:'Prime Location:',
            text: ' Our parking lots are strategically located near the Erato Street and Julia Street cruise terminals, making it easy for you to park and embark on your cruise without any hassle. Enjoy a stress-free walk or take advantage of our complimentary shuttle service to the terminal.',
        },
        {
            id: 2,
            pic: Benefit2,
            title:'Affordable Rates: ',
            text: 'We understand that travel expenses can add up quickly. That’s why we offer competitive daily rates, ensuring you get the best value for your money. Whether you’re going on a short getaway or an extended cruise, our pricing is designed to fit your budget.',
        },
        {
            id: 3,
            pic: Benefit3,
            title:'Secure Parking: ',
            text: 'Your vehicle’s safety is our top priority. Our parking facilities are well-lit, fenced, and monitored 24/7 by security personnel and surveillance cameras. You can relax and enjoy your cruise knowing your car is in good hands.',
        },
        {
            id: 4,
            pic: Benefit4,
            title:'Easy Online Reservations: ',
            text: 'Skip the stress of finding a parking spot on the day of your departure. With our easy-to-use online reservation system, you can book your parking space in advance. Simply select your dates, reserve your spot, and arrive with confidence.',
        }
        ,
        {
            id: 5,
            pic: Benefit1,
            title:'Exceptional Shuttle Service: ',
            text: '  Our friendly and professional staff are here to assist you every step of the way. From helping with luggage to providing directions, we’re committed to making your parking experience as smooth and pleasant as possible.',
        }
    ]

   
    const [cancelAlert, setCancelAlert] = useState('alert-message')

  

    const { ref: whyRef, inView: isWhyInView } = useInView();
    const { ref: mainRef, inView: isMainInView } = useInView();
    const { ref: facilitiesRef, inView: isFacilitiesInView } = useInView()

    useEffect(() => {
        Constants.seo({title:'New Orleans Parking Reservations | Quick And Easy Cruise Parking',
            metaDescription:'Find convenient parking solutions in New Orleans, including cruise and downtown parking. Reserve your spot online for a hassle-free experience'})
        
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/streets-v12',
          center: [lng, lat],
          zoom: zoom
        });
      });


   useEffect(() => {

    try{
        map.current.on('load', function (e) {
            
        map.current.addSource('places', {
                    type: 'geojson',
                    data: parkinglots
                });
            });
    }catch{

    }
    

    parkinglots?.features.forEach(function (marker) {
        // Create a div element for the marker
        var el = document.createElement('div');
        // Add a class called 'marker' to each div
        el.className = 'marker';
        // By default the image for your custom marker will be anchored
        // by its center. Adjust the position accordingly
        // Create the custom markers, set their position, and add to map
        new mapboxgl.Marker(el, { offset: [0, -23] })
            .setLngLat(marker.geometry.coordinates)
            .addTo(map.current);
        el.addEventListener('click', function (e) {
          
            flyToStore(marker);
            // 2. Close all other popups and display popup for clicked store
            createPopUp(marker);
            // 3. Highlight listing in sidebar (and remove highlight for all other listings)
            var listing = document.getElementById(marker.properties.parkinglotid);
           // $(listing).addClass('active');
        });
    });
       
    }, [parkinglots])

    const flyToStore=(currentFeature)=> {
        map.current.flyTo({
            center: currentFeature.geometry.coordinates,
            zoom: 16
        });
    }
    const createPopUp = (currentFeature) => {
        var popUps = document.getElementsByClassName('mapboxgl-popup');
        // Check if there is already a popup on the map and if so, remove it
        if (popUps[0]) popUps[0].remove();
    
        var popup = new mapboxgl.Popup({ closeOnClick: false })
            .setLngLat(currentFeature.geometry.coordinates)
            .setHTML('<div class="mapdiv"><div class="title">' + currentFeature.properties.parkinglotname + '</div>' +
            '<p class="address">' + currentFeature.properties.address + '</p>' +
            '<p class="otherps">Avg: <strong>' + currentFeature.properties.avg + '</strong> | Type: <strong>' + currentFeature.properties.parkingtype + '</strong></p>' +
            '<p class="otherps">Pay Online: <strong>' + currentFeature.properties.payonline + '</strong> | Due At Lot: <strong>' +
             currentFeature.properties.dueatlot + "</strong></p> <div> <button type='button' class='btn' onclick='callbutton(" + '"' + currentFeature.properties.parkinglotid + '","' + currentFeature.properties.buttonmsg + '"' + ")' >" + currentFeature.properties.buttonmsg + "</button></div></div>")
            .addTo(map.current);
    }

    const callMap = (parkinglotid) => {

        for (var i = 0; i < parkinglots.features.length; i++) {
            if (parkinglots.features[i].properties.parkinglotid == parkinglotid) {
                var clickedListing = parkinglots.features[i];
                // 1. Fly to the point associated with the clicked link
                flyToStore(clickedListing);
                // 2. Close all other popups and display popup for clicked store
                createPopUp(clickedListing);
                // 3. Highlight listing in sidebar (and remove highlight for all other listings)
    
                var activeItem = document.getElementsByClassName('active');
                if (activeItem[0]) {
                    activeItem[0].remove('active');
                }
            }
        }
    }

    const callbutton=(parkinglotid) =>{
        navigate(`/reservation/checkout/${parkinglotid}`);
    }

    ReactGA.pageview(window.location.pathname + window.location.search);
    
    const currentDate = useMemo(() => {
        return new Date();
    }, []) 

    // Add a week (7 days) to the current date
    const nextWeek = new Date(currentDate);
    nextWeek.setDate(currentDate.getDate() + 7);

    // Format the next week date as a string in 'YYYY-MM-DD' format
    const formattedNextWeek = nextWeek.toISOString().split('T')[0];

    const data = useMemo(() => {
        return {
            "reservationtype":"cruise",
            "airportName": 'New Orleans Int Airport',
            "airportCode": 'MSY',
            "startDate": currentDate.toISOString().split('T')[0],
            "startTime": "12:00",
            "endDate": formattedNextWeek,
            "endTime": '12:00',
            "startdatetime":"",
            "enddatetime":"",
            "carCount": "1",
            "discountcode":"",
            "selectedlot":"",
            "corporatecode":"",
            "rateType":'daily',
            "rateOption":'both',
        }
    } , [ currentDate, formattedNextWeek]);

    data.startdatetime = data.startDate + ' ' + data.startTime;
    data.enddatetime = data.endDate + ' ' + data.endTime;

 

    useEffect(() => {
        setIsFormSubmitted(true);
        setFormData(data);
        setLoading(true);

       

          searchParking();
    }, [setIsFormSubmitted, data])

 const searchParking = async () => {
            try {

             // await  onOpenContent();
              const resp = await fetch(`${Constants.apiUrl}/reservation/search-map`, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                  'Content-Type': 'application/json',
                },
              });
          
              const responseData = await resp.json();
              setSearchData(responseData);
            //  setParking(responseData.parkings)

            //const the parkinglots object
            var features=[];
            responseData.parkings.forEach(item => {
                var feature = {
                "type": "Feature",
                "geometry": {
            "type": "Point",
                    "coordinates": [
                            item.longitude,
                            item.latitude
                    ]
                },
                "properties": {
                    "phoneFormatted": item.parkinglotphone,
                    "phone": item.parkinglotphone,
                    "address": item.address1+' '+ Constants.NullableStr (item.address2 ),
                    "parkinglotid": item.parkinglotid,
                    "city": "New Orleans",
                    "country": "United States",
                    //"crossStreet": "at 15th St NW",
                    //   "postalCode": "20005",
                    "state": "N.O",
                    "avg": Constants.formatCurrency(item.avgrate) ,
                    "parkinglotname": item.parkinglotname,
                    "parkingtype": item.parkingtype,
                    "payonline": Constants.formatCurrency(item.onlinepay),
                    "dueatlot": Constants.formatCurrency(item.dueatlot),
                    "buttonmsg": (item.booked == false && item.blocked == false)? "RESERVE" : ((item.booked == true)?"SOLD OUT":"UNAVAILABLE")
                     
                    }
            };
            features.push(feature);
            });
            
            setParking({...parkinglots,features });

            //end construct ***************
          
              if (responseData.code === 0) {
                setIsFormSubmitted(true);
              } else {
                console.log(responseData.status);
              }
                setLoading(false);
            } catch (error) {
              console.error(error);
                setLoading(false);
            }
          };



  return (
    <main>
        

        <div className={ isMainInView ? "main-box show" : "main-box"} ref={mainRef}>
           
            <div className="images-info">
            
              <div className='map-reserve'>
            <div style={{flex:3}}>
            <div ref={mapContainer} className="map-container" />
            </div>
            <div style={{flex:1, overflow:'auto',maxHeight: '75vh', minHeight:600}}> 
            <div style={{background:'#097969', color:'white', padding:16}}>Search from <strong>2024-03-01 12:00</strong>  to <strong>2024-03-05 12:00</strong> </div>
            { isFormSubmitted ? <div className="cards" style={{backgroundColor:'#ffffff99'}}>
                    { searchData?.parkings?.map((item, idx) => {
                         const createMarkup = (content) => ({ __html: (content ?? '').replace(/<br\s*\/?>/g, '\n') });

                        return (
                            <div className="card" key={idx}  style={{backgroundColor:'#ffffff99', borderBottomStyle:'dashed', borderBottomColor:'black',borderBottomWidth:1}}>
                                <div className="left-note">
                                    <h3>{item.parkinglotname}</h3>
                                    {item.holidaydaysinavg?.map((holiday, ix)=>{
                                       return (<p className="head-note" key={ix}>Please note: Holiday rate of ${holiday.dailyrate} per day in affect from {Constants.formatDate(holiday.startdate) } to {Constants.formatDate(holiday.enddate) }</p>)
                                    })} 
                                   {/*  <div className="general-info">
                                        <p dangerouslySetInnerHTML={createMarkup(item.shortsecuritystatement)} />
                                    </div> */}
                                    {/* <div className="complementary-info">
                                        <p dangerouslySetInnerHTML={createMarkup(item.transportation)} />
                                    </div> */}
                                </div>
                                <div className="price">
                                    <div className="mid-price">
                                        <p className="ticketing">
                                            <h4>TYPE</h4>
                                            <span>{item.parkingtype}</span>
                                        </p>
                                        <p className="ticketing">
                                            <h4>AVG. RATE</h4>
                                            <span style={{fontWeight:'bold'}}>{Constants.formatCurrency(item.avgrate)}</span>
                                        </p>
                                    </div>
                                    <div className="mid-price">
                                        <p><h4>{Constants.formatCurrency(item.subtotal) }</h4><span>Sub-Total</span></p>
                                        <p><h4>{Constants.formatCurrency(item.servicefee)}</h4><span>Service Fee</span> </p>
                                        <p><h4>{Constants.formatCurrency(item.tax)}</h4><span>Sales Tax</span> </p>
                                        {item.freedaysavings === 0 ? '' : <p><h4>{Constants.formatCurrency(item.freedaysavings)}</h4><span>Free Day Savings</span> </p> }
                                        
                                    </div>
                                    <div className="total-price">
                                        <h3>Amount Total = ${item.onlinepay.toFixed(2)}</h3>
                                        <p>Due at Lot = ${item.dueatlot.toFixed(2)}</p>
                                    </div>
                                    <div className="btn">
                                        <Link to={`/reservation/checkout/${item.parkinglotid}`}>
                                            <button disabled={item.booked === true || item.blocked === true}  className={item.booked === true || item.blocked === true ? 'cancel-btn' : 'reserve-btn'}>
                                                {item.booked === true || item.blocked === true ? 'UNAVAILABLE' : 'RESERVE'}
                                            </button>
                                            
                                        </Link>
                                        {item.latitude!=null && <button type="button" onClick={()=> callMap(item.parkinglotid)}   className='map-btn'><i className="marker"></i> See on Map</button>}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div> : ''}
            </div>
        </div>  
              
            </div>
            <div className="form">
            
                <div class="col-md-12">
                    
                    <p>New Orleans Parking helps you save time and money on your airport and seaport parking.</p>
                </div>
                <AirportForm  reservationId={0} />
                <div class="col-md-12 intro">
           
        </div>
            </div>
        </div>
       


        <div className={ isWhyInView ? "why-airportinc show" : "why-airportinc"} ref={whyRef}>
            <h1>Why Choose Us?</h1>
            <div className="items">
                { benefits?.map(benefit => {
                    return(
                        <div className="item" key={benefit.id}>
                            <img src={benefit.pic} alt="Benefits of New Orleans Parking" loading='lazy'/>
                            <h3>{benefit.title}</h3>
                            <p>{benefit.text}</p>
                        </div>
                    )
                })}
            </div>
        </div>
        <div style={{ maxWidth:'80%', marginLeft:'auto', marginRight:'auto',marginTop:16, marginBottom:16,  padding:24}}>
            <h2 style={{textAlign:'center'}}>How It Works</h2>
            <p style={{fontSize:16, textAlign:'center'}}>Use our online reservation system to book your parking space in advance. Follow the easy directions to our parking facility, where our staff will assist you with parking and luggage. Take advantage of our complimentary shuttle service to the cruise terminal, or enjoy a short walk if you prefer. Relax and enjoy your cruise, knowing your vehicle is safe and secure. Upon your return, our shuttle will bring you back to the parking lot, where your car will be waiting for you.</p>
        </div>
        <div className={ isFacilitiesInView ? "facilities show" : "facilities"} ref={facilitiesRef}>
           
            <div className="sponsors-ratings" >
                <div className="sponsors">
                    <h3>Looking for a getaway vacation?</h3>
                    <p>Check out Outrageous Cabins in Sevierville Tennessee today! </p>
                    <Link to="https://www.outrageouscabins.com/" target="_blank" rel='noreferrer'>
                        <img src={OutrageousCabins} alt="Outrageous Cabins" loading='lazy'/>
                    </Link>
                </div>
                <div className="ratings">
                {/* <div class="trustpilot-widget" data-locale="en-US" data-template-id="539ad0ffdec7e10e686debd7" data-businessunit-id="54a69f2a0000ff00057c74e0" data-style-height="350px" data-style-width="100%" data-theme="light" data-stars="4,5"></div> */}
                <TrustBox />
                </div>
                <div style={{flex:1, padding:24, alignItems:'center'}}>
                <h3>Facilities Where You Can Find Us:</h3>
                <ul style={{margin:10}}>
                    <li>Commerce Garage</li>
                    <li>Clarke Garage</li>
                    <li>Fulton Garage Covered</li>
                    <li>Fulton Garage Overnight</li>
                    <li>Fulton Garage Roof Top</li>
                    <li>Howard Garage</li>
                    <li>Rampart Street Garage</li>
                    <li>UniPark Garage</li>
                    <li>Villerie Lot Parking</li>
                </ul>
                </div>
            </div>
        </div>
        <div className={cancelAlert}>
            <h3>Customer service hours: 8 AM to 6 PM Monday - Sunday</h3>
            <div className="terms-home">
                <Link to='/terms-of-use'>See Cancellation Policy in Terms of Use</Link>
                <p>| $5.00 service fee is not refundable</p>
            </div>
            <FontAwesomeIcon icon={faCircleXmark} className='cancel-alert' onClick={() => setCancelAlert('alert-message cancel')}/>
        </div>
    </main>
  )
}

export default LandingPage
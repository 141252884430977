import React, { useState, useContext, useEffect } from 'react'
import '../../styles/Admin/admin.css'
import { Link } from 'react-router-dom'
import { faFacebook, faGooglePlus, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from '../../assets/logo.png';
import LogInImg from '../../assets/adminlogin.png';
import { ShareContext } from '../../context/ShareContext';
import { useNavigate, useParams } from 'react-router-dom'
import * as Constants from '../../constants';
import Loading from '../../components/Loading'
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import Expired from '../../components/Expired'
import ApiAlert from '../../components/ApiAlert'


function PasswordReset() {
    const {cred} = useParams();
    
   // console.log(`the param ${username} the password ${password}`)

    const {  setIsAdminLoggedIn, setAdminData, adminToken} = useContext(ShareContext);
    
    Constants.seo({title:'Airport Parking Reservations | Admin Login',
    metaDescription:'Airport Parking Reservations. Quick And Easy Long Term Offsite Airport Parking Reservations. 24-hour Video Surveillance. Convenient Shuttle Service.'})
    
    const [adminLoginData, setAdminLoginData] = useState({
        username: '',
        password: ''
    });
    const navigate = useNavigate()

    const [isInputEmpty, setIsInputEmpty] = useState(false)
    const [loginError, setLoginError] = useState('')
    const [loading, setLoading]=useState(false);
    const [passobj, setPassObj]=useState({});
    const [isExpired, setIsExpired] = useState(false);
    const [alert, setAlert] =useState({open:false, title:'', subtitle:''});

    const handleChange = (e) => {
        setPassObj({...passobj, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        
         }, [])

    // console.log(isAdminLoggedIn);
    const logOut = () => {
        // Clear token from local storage
        localStorage.removeItem('adminToken');
      
        // Clear other user-related data from local storage
        localStorage.removeItem('adminDetails');
      
        // Set user logged in state to false
        setIsAdminLoggedIn(false);
      
        // Navigate to the login page
        navigate('/admin');
      };
    const onCompleted=()=>{
        //after the alert message, then it should 
        setAlert({open:false});
        logOut();
    }
    const onOk=()=>{
        //after the alert message, then it should 
        setAlert({open:false});
        
    }

    const onReset = async(e) => {
        e.preventDefault();

        if (passobj.currentpass === "" || passobj.newpassword === '' || passobj.newpasswordc === '') {
            setIsInputEmpty(true)
        } else {
            setLoading(true);
            try{
                const response = await fetch(`${Constants.apiUrl}/parkingadmin/changepasswordreset`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': adminToken,
                    },
                    body: JSON.stringify(passobj)
                })
                
                const data = await response.json()
                setLoading(false);
                if (data.code == 0) {
                    setAlert({open:true, title:'Password Reset', subtitle:"Password reset completed. Please login with the new password.", onOk:onCompleted})
        
                  }else if(data.code==-10){
                    setIsExpired(true);
                  }
                  else {
                    setLoginError(data.status)
                   
                  }
            } catch (error){
                setLoginError('Failed Login')
                setLoading(false);
            }

        }
    }

    const onGA=()=>{
      //  ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.event({
            category:  'LocalCall',
            action:'Reservation Test',
            value:24.5,
            label:'on reservation 4'
          });

          TagManager.dataLayer({
            dataLayer: {
              event: 'pageview',
              pagePath: window.location.pathname + window.location.search,
              pageTitle: 'Testing Administrator',
            },
          });

          ReactGA.ga("trackerGlobal.require", "ecommerce");
          ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            id: 'Abctest',
            revenue: 20,
            category: 'booking',
            name: "Test Res"
          });

          ReactGA.ga(`sales.ecommerce:addTransaction`, {
            id: 1222,
            affiliation: "Affiliation",
            revenue: 15
          });

    }

  return (
    <div className='admin-login'>
        <img src={LogInImg} alt="Log-In" className='main-img' loading='lazy'/>
        <div className="form-side">
            <div className="form-head">
                <Link to='/'><img src={Logo} alt="Link to home" /></Link>
            </div>
            <div className="form">
                <div className="heading">
                    <p>We request that you change your password.</p>
                    <h2>Password reset</h2>
                </div>
                <form action="post">
                    <p className='error'>{loginError}</p>
                    <div className="input">
                        <label htmlFor="admin-username">The current password</label>
                        <input type="password" id='admin-username' name='currentpassword' value={passobj.currentpassword} onChange={handleChange} required/>
                    </div>
                    <div className="input">
                        <label htmlFor="admin-password">Password</label>
                        <input type="password" id='admin-password' name='newpassword' value={passobj.newpassword} onChange={handleChange} required/>
                    </div>
                    <div className="input">
                        <label htmlFor="admin-password">Confirm Password</label>
                        <input type="password" id='admin-password' name='newpasswordc' value={passobj.newpasswordc} onChange={handleChange} required/>
                    </div>
                   { passobj.newpass!=passobj.newpassc && <p style={{color:'red', fontWeight:500}}>*The Password on confirm password must be same. its case sensitive</p> } 
                    <div className="btns">
                        { isInputEmpty ?  <p className="error">Please enter old password and new password</p> : null}
                        <button type='button' onClick={onReset}>Reset Password</button>
                        
                    </div>
                </form>
                
                <ul className='social'>
                    <li><a href='https://twitter.com/AirportPark_Inc' rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faTwitter} /></a></li>
                    <li><a href='https://web.facebook.com/pages/Airport-Parking-Inc/149759451747371?_rdc=1&_rdr' rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faFacebook} /></a></li>
                    <li><a href='https://play.google.com/store/apps/details?id=com.ionicframework.airportapp357995&pli=1' rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faGooglePlus} /></a></li>
                </ul>

                <div className={ loading ? "congrats show" : "congrats"}>
                    <Loading message={'Resetting password'}/>
                </div>
                { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}
                {alert.open && <div className={ "congrats show" }>
                <ApiAlert {...alert} />  
            </div> } 
            </div>
        </div>
    </div>
  )
}

export default PasswordReset
import React, { useContext, useState, useEffect } from 'react'
import '../../styles/Admin/othertaxes.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { ShareContext } from '../../context/ShareContext'
import * as Constants from '../../constants';

function ReservationRefund() {
    const { isSidebarClosed, adminReport,reportForm } = useContext(ShareContext)
    const [list, setList]=useState({});
    const [total, setTotal]=useState([]);

    const report=adminReport.reservations;

    const onRearrange=()=>{
        console.log('data rearrangement');
        var _list={};
        var fortotal=[];
    for (const re of report){
            var id=Constants.removeSpace(re.parkinglotid);
        var pak=_list[id];
       // var totalamt=re.payatlot>0? (re.totalamount - (re.servicefee   +re.tax + re.totalothertax + re.refundamt + re.payatlot)):(re.totalamount - (re.servicefee + re.accessfee + re.tax + re.totalothertax + re.refundamt + re.payatlot));
       var totalamt= (re.totalamount - (re.servicefee   +re.tax + re.totalothertax + re.refundamt + re.payatlot));
       var totalamtstr=totalamt+ (re.refundamt >0 ? ` Refund ${re.refundamt}`:'');
        if(pak==null){
            _list[id]={res:[{...re,totalamt,totalamtstr}]}; //adding first item
            
        }else{
            var pak=_list[id];
            pak.res=[...pak.res,{...re,totalamt,totalamtstr}];
            _list[id]={...pak};
        }

        fortotal.push({...re,totalamt,totalamtstr}) ;

        }
        console.log(_list) ;
        setList(_list);
        console.log('total list items')
        console.log(fortotal);
        setTotal(fortotal);
        //calculate total

    }
   
    useEffect(() => {
     onRearrange();
      }, [])

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            {reportForm.reportType=='details' &&  <div className="report-body">
                     <div className="head">
                        <h2>RESERVATIONS - REFUND REPORT</h2>
                        
                    </div>
                     {total?.length>0 && Object.values(list) .map((li, id) => {return ( <> 
                    <div className="head">
                        
                        <h3>{li.res[0].parkinglotname}</h3>
                    </div>
                    <div className="table-wrapper">
                        <table className='full-table'>
                            <thead>
                                <tr>
                                <th>ID</th>
                                <th>Arrival Date</th>
                                <th>Name</th>
                                <th>Date Range</th>
                                <th>Duration</th>
                                <th> Total</th> 
                               
                                <th>Refund</th> 
                               
                                </tr>
                            </thead>
                            <tbody>
                            { li.res.map((item, idx) => {return ( 
                               <tr className='record' key={idx}>
                                    <td><strong>{item.transactionid} </strong><br /> {item.carCount} cars(s)</td>
                                    <td>{item.arrivaldate}  </td>
                                    <td>{item.firstname}  {item.lastname}  <br />{item.numofpersons } persons <br/> {item.vesselname}</td>
                                    <td>{Constants.formatDateTime(item.arrivaldate)} to {Constants.formatDateTime(item.returndate)}</td>
                                    <td>{Constants.duration(item.arrivaldate,item.returndate)}</td>
                                    <td>{Constants.formatCurrency (item.totalamount)} </td>
                             
                                    <td>{Constants.formatCurrency(item.refundamt)}</td>
                                    
                                   
                                </tr> )}) }
                            </tbody>
                        </table>
                    </div>
                    <div className="table-wrapper">
                        <table className='total-table'>
                            <tbody>
                                <tr className='total'>
                                    <td colSpan={5}>Record Count: <span>{li.res.length} | Cars {li.res.reduce((sum, item) => sum + item.carCount, 0)} | Persons {li.res.reduce((sum, item) => sum + item.numofpersons, 0)}</span></td>
                                    <td>Total: <span>{ Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.totalamt, 0))  } 
                                           </span></td>
                          
                                    <td>Total Refund: <span>{Constants.formatCurrency(li.res.reduce((sum, item) => sum + item.refundamt, 0)) }</span></td>
                                   
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </> )}) }
                    <div className="table-wrapper">
                        <table className='total-table'>
                            <tbody>
                                <tr className='total'>
                                    <td colSpan={5}>Record Count: <span>{total.length} | Cars {total.reduce((sum, item) => sum + item.carCount, 0)} | Persons {total.reduce((sum, item) => sum + item.numofpersons, 0)}</span></td>
                                    <td>Total: <span>{ Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalamt, 0))  } 
                                            </span></td>
                               
                                    <td>Total Refund: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.refundamt, 0)) }</span></td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>


                     

                </div> } 
            {reportForm.reportType!='details' && <div className="report-body">
                <div className="head">
                    <h2>RESERVATIONS - REFUND REPORT</h2>
                </div>
                <div className="all-taxes">
                    { total.map((item, idx) => {return ( 
                    <div className="tax" key={idx}>
                        <h3>{item.parkinglotname}</h3>
                        <div className="container">
                            <div className="row">
                                <p className="title">Total</p>
                                <p className="answer">{Constants.formatCurrency(item.totalamount)}</p>
                            </div>
                            <div className="row">
                                <p className="title">Tax</p>
                                <p className="answer">{Constants.formatCurrency(item.refundamt)}</p>
                            </div>
                        </div>
                    </div>
                    )}) }
                   
                </div>
                <div className="table-wrapper">
                    <table>
                        <tbody>
                            <tr className='total'>
                                <td>Total : <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalamount, 0))}</span></td>
                                <td>Total Refund Fee: <span> {Constants.formatCurrency(total.reduce((sum, item) => sum + item.refundamt, 0))}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>} 
        </div>
    </div>
  )
}

export default ReservationRefund
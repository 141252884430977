import React, { useContext, useState, useEffect } from 'react'
import '../../styles/Admin/othertaxes.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { ShareContext } from '../../context/ShareContext'
import * as Constants from '../../constants';


function CommisionReport() {
    const { isSidebarClosed, adminReport,reportForm } = useContext(ShareContext)
    const [list, setList]=useState({});
    const [total, setTotal]=useState([]);

    const report=adminReport.reservations;

   

  return (
    <div className='admin-body'>
    <Sidebar />
    <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
        <Head />
         <div className="report-body">
                 <div className="head">
                    <h2>RESERVATIONS - COMMISSION REPORT</h2>
                    
                </div>
                
                
                <div className="table-wrapper">
                    <table className='full-table'>
                        <thead>
                        <tr>
                            <th> Agency Name</th>
                            <th> Commision </th>
                            <th> Parking Lot </th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        { report.map((item, idx) => {return ( 
                           <tr className='record' key={idx}>
                                <td>{item.agencyname}</td>
                                <td>{item.commission}% </td>
                                <td>{item.parkinglotid} </td>
                                
                                <td></td>
                               
                            </tr> )}) }
                        </tbody>
                    </table>
                </div>
                <div className="table-wrapper">
                    <table className='total-table'>
                        <tbody>
                            <tr className='total'>
                                <td ></td>
                               
                                <td>Total Commision: <span>{(report.reduce((sum, item) => sum + item.commission, 0)) }</span></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
               
                <div className="table-wrapper">
                    <table className='total-table'>
                        <tbody>
                            <tr className='total'>
                                <td colSpan={5}>Record Count: <span>{total.length} | Cars {total.reduce((sum, item) => sum + item.carCount, 0)} | Persons {total.reduce((sum, item) => sum + item.numofpersons, 0)}</span></td>
                                <td>Total: <span>{ Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalamt, 0))  } 
                                        {total.reduce((sum, item) => sum + item.refundamt, 0)>0?
                                         ` Refund: ${Constants.formatCurrency(total.reduce((sum, item) => sum + item.refundamt, 0))}`:''}</span></td>
                           
                                <td>Total Tax: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.tax, 0)) }</span></td>
                                <td>Total Others: <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalothertax, 0)) }</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                 

            </div> 

       {reportForm.reportType=='noshow am' && <div className="report-body">
            <div className="head">
                <h2>RESERVATIONS - COMMISSION REPORT</h2>
            </div>
            <div className="all-taxes">
                { total.map((item, idx) => {return ( 
                <div className="tax" key={idx}>
                    <h3>{item.parkinglotname}</h3>
                    <div className="container">
                        <div className="row">
                            <p className="title">Total</p>
                            <p className="answer">{item.totalamount}</p>
                        </div>
                        <div className="row">
                            <p className="title">Tax</p>
                            <p className="answer">{item.tax}</p>
                        </div>
                    </div>
                </div>
                )}) }
               
            </div>
            <div className="table-wrapper">
                <table>
                    <tbody>
                        <tr className='total'>
                            <td>Total : <span>{Constants.formatCurrency(total.reduce((sum, item) => sum + item.totalamount, 0))}</span></td>
                            <td>Total Tax Fee: <span> {Constants.formatCurrency(total.reduce((sum, item) => sum + item.tax, 0))}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>} 
    </div>
</div>
  )
}

export default CommisionReport
import React, { useContext, useState, useRef,useEffect } from 'react'
import '../../styles/User/checkout.css'
import { useParams } from 'react-router-dom'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { Link } from 'react-router-dom';
import Card from '../../assets/card.jpg';
import { ShareContext } from '../../context/ShareContext';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import * as Constants from '../../constants';
import moment from "moment";
import Expired from '../../components/Expired';

function AdminCheckout() {
    const navigate = useNavigate();

    var isConfirm =false; //this is to be sure only one booking request will surfice
    const checkName = useParams();
    const {selectedlotId}=useParams();
    const {reservationId} = useParams();
    const mainResvId=reservationId==null?0:reservationId;
    const fetchurl_confirm=mainResvId==0?`${Constants.apiUrl}/reservation/confirm`: `${Constants.apiUrl}/reservation/confirm/${reservationId}`;
    const fetchurl_discount=reservationId==null || reservationId==0?`${Constants.apiUrl}/reservation/search/${selectedlotId}`: `${Constants.apiUrl}/reservation/${reservationId}/search/${selectedlotId}`;

    const { formData, searchData, isUserLoggedIn, 
         setReceiptDetails,setIsAdminLoggedIn, 
          adminToken,isAdminLoggedIn } = useContext(ShareContext);

        // console.log(adminToken);

    const [loginView, setLoginView]=useState("login");
    const [isLogin, setIsLogin]=useState(false);
    const [loginWith, setLoginWith]=useState("password");
    const[finalError, setFinalError] =useState('');
    const[confirmData, setConfirmData] =useState({parkinglot:{},customer:{}, reservation:{}});
    const [isLoading, setIsLoading]= useState(false);
    const [isExpired, setIsExpired] = useState(false)

    const [loginData, setLoginData]=useState({email:'',password:''});
    const [signupData,setSignupData]=useState({
        "firstname": '',
        "lastname": '',
        "mi": '',
        "email": '',
        "password": '',
        "password2": '',
        "phone": '',
        "zipcode": '',
        "address1": '',
        "address2": '',
        "city": '',
        "state": '',
    });
    const states = ['AB', 'AK', 'AL', 'AR', 'AZ', 'BC', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MB', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NB', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NL', 'NM', 'NS', 'NT', 'NU', 'NV', 'NY', 'OH', 'OK', 'ON', 'OR', 'PA', 'PE', 'PR', 'QC', 'RI', 'SC', 'SD', 'SK', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY', 'YT'];

    const validyrs=()=>{
        var yrs=[];
        var cyear=parseInt(moment().format('YYYY')) ;
        for (let index = 0; index < 20; index++) {
            
            yrs.push(cyear+index);
        }
        return yrs;
    }

    var useToken ="";
    useEffect(() => {
        //Runs only on the first render
       // var sToken=localStorage.getItem("accessToken");
       
      }, []);

   

    const [data, setData] =useState(searchData?.parkings?.find(item => item.parkinglotid === selectedlotId) || '') ;

    const formatDate = (inputDate) => {
        // Split the input date into year, month, and day
        const [year, month, day] = inputDate.split('-');
    
        // Create a Date object using the extracted values
        const dateObject = new Date(year, month - 1, day);
    
        // Array of month names
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
        // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const dayOfWeek = dateObject.getDay();
    
        // Array of day names
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
        // Get the actual day and month names
        const actualDay = daysOfWeek[dayOfWeek];
        const actualMonth = monthNames[parseInt(month, 10) - 1];
    
        // Assemble the formatted date string
        const formattedDate = `${actualDay} ${actualMonth} ${day}, ${year}`;
    
        return formattedDate;
    };

    const [showCode, setShowCode] = useState(false);
    const [showCvv, setShowCvv] = useState(false);
    const [paymentDone, setPaymentDone] = useState(false)

    const [cardDetails, setCardDetails] = useState({
        "card": "",
        "month": "01",
        "year": moment().format("YYYY"),
        "ccv": "",
        "reservationId": 0
    })

    const cardChange = (e) => {
        setCardDetails({...cardDetails, [e.target.name]: e.target.value})
    }



    const onDiscount=async()=>{
        //call and replace the parkinglot item
        console.log("on discount call");
       // e.preventDefault();
       // console.log(JSON.stringify(reservationDetails.searchReservation));
        onResearch(reservationDetails.searchReservation);
    }

    const onResearch=async(searchReservation)=>{
        //call and replace the parkinglot item
 var dddata={...searchReservation,endDateTime: searchReservation.endDate+" "+searchReservation.endTime};
        console.log("making a re- search again to find out what is bringing");
        console.log(dddata);
       
        setIsLoading(true);
       // e.preventDefault();
        console.log(JSON.stringify(dddata));
        try {
            const resp = await fetch(fetchurl_discount, {
              method: 'POST',
              body: JSON.stringify(dddata),
              headers: {
                'Content-Type': 'application/json',
              },
            });
            
            const responseData = await resp.json();
           
            console.log(responseData)
            if (responseData.code === 0) {
               setData(responseData.parking);
            }else if(responseData.code === -10){
                setIsExpired(true)
            }else {
              console.log(responseData.status);
            }
            setIsLoading(false);
          } catch (error) {
            console.error(error);
            setIsLoading(false);
          }
    }

    const onCheckOutProcess=async(e)=>{
        e.preventDefault();
        //for account registration
        //afterlogin, 
        console.log(`value for user  ${isUserLoggedIn}`);
        console.log(`value for admin  ${isAdminLoggedIn}`);
    
       /*  if(!isUserLoggedIn ){
            if(loginView=="login")
                await loginForm();
 
            if(loginView=="register")
                await registerForm();
        } */
        
        /* if(e.target.checkValidity()){ // use can also use e.target.reportValidity
         // submitForm
         console.log('form validation check '+e.target.checkValidity());
         return;
       } */
        if (!checkboxRef.current.checked) {
            setShowError(true);
            return; 
          } else {
            setShowError(false);
        }
       /*  console.log('Token on system');
        console.log(useToken);
        console.log('Token with context');
        console.log(useToken);
        if(useToken==''){
            //login not possible
            setFinalError("The Login expired, We need you to relogin");
            console.log('Login must have failed');
            setIsUserLoggedIn(false);
            return;
        } */

        if(isConfirm)
            return;  

           // var dddata={...searchReservation,endDateTime: searchReservation.endDate+" "+searchReservation.endTime}
         var rd=  {...reservationDetails, searchReservation: { ...reservationDetails.searchReservation,endDateTime: reservationDetails.searchReservation.endDate+" "+reservationDetails.searchReservation.endTime } }
           const checkoutData = {
            ...cardDetails,
            ...rd, 
        }
        console.log(JSON.stringify(checkoutData));
        
        if (!showError) {
            setIsLoading(true);
            fetch(fetchurl_confirm, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': adminToken
                },
                body: JSON.stringify(checkoutData)
            })

            .then(resp => resp.json())
            .then(allData => { 
                setIsLoading(false);
                isConfirm=true;
                console.log(allData);
                if (allData.code === 0) {
                setReceiptDetails(allData); //this is a member of context
                setConfirmData(allData); //this is only use local
                setPaymentDone(true)
                setTransactionId(allData.reservation.transactionid)
            }else if(allData.code === -10){
                console.log('failed login, also expecting to see login view')
                setFinalError(allData.status);
                logOut();
            }else{
                setFinalError(allData.status);
                //alert(allData.status)
            }
        })
            .catch(error => {setIsLoading(false);console.error(error)}) 
        }

    }

    const logOut = () => {
        //setAdminToken('');
        // Clear token from local storage
        localStorage.removeItem('adminToken');
      
        // Clear other user-related data from local storage
        localStorage.removeItem('adminDetails');
      
        // Set user logged in state to false
        setIsAdminLoggedIn(false);

      };
//console.log('formdata received');
//console.log(formData);
    const [reservationDetails, setReservationDetails] = useState({
        "searchReservation": {
           
            "airportName": formData.airportName,
            "airportCode": formData.airportCode,
            "startDate": formData.startDate,
            "startTime": formData.startTime,
            "endDate": formData.endDate,
            "endTime": formData.endTime,
            "startDateTime": formData.startdatetime,
            "endDateTime": formData.enddateTime,
            "corporateCode": "",
            "discountCode": "",
            "selectedLot": data?.parkinglotid,
            "parkingLotName": data?.parkinglotname,
            "parkinglotId": data?.parkinglotid,
            "corporateDiscount": 0,
            "tax": 0,
            "reservationID": mainResvId, //formally 0
            "customerName": "",
            "iscompleted": true,
            "carCount": formData.carCount,
            "carDetails": formData.carDetails,
            "parkingperson": formData.parkingperson,
            "plateNumber": formData.plateNumber,
            "numofpersons": 1,
            "vesselname":formData.vesselname,
            "reservationtype":formData.reservationtype,
                "rateType":formData.rateType,
          },
    })

    const reservationChange = (e) => {
        setReservationDetails({...reservationDetails, searchReservation: { ...reservationDetails.searchReservation, [e.target.name]: e.target.value } })
        // console.log ("property change "+ e.target.name);
        if(e.target.name=='carCount'){
            onResearch({ ...reservationDetails.searchReservation, [e.target.name]: e.target.value }); //only for car count
        }
    }

   /*  useEffect(() => {
        console.log('calling the update data')
        onDiscount();
      }, [reservationDetails.searchReservation.carCount]);
       */

    const checkboxRef = useRef(null);

    const [showError, setShowError] = useState(false);
    const [transactionId, setTransactionId] = useState('')
    const [errorMessage, setErrorMessage]= useState('');

   
    
    const signupChange = (e) => {
        setSignupData({...signupData, [e.target.name]: e.target.value})
    }
   
 

  return (
    <div className='checkout'>
        <Head />
        { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}
        <div className="body">
            <div className="left-side">
                <div className="heading">
                    <h1>Secure Reservation</h1>
                    <p>Your reservation is just 2 steps away</p>
                </div>
            

                   
                    
                <div className="parking-form">
                    <form action="post" onSubmit={onCheckOutProcess}>
                      {/* {(!isUserLoggedIn && loginView=='login') &&  <div className="firstPart">
                            <div className="title">
                                <h2>Log In</h2>
                            </div>
                            <div className="input">
                                <label htmlFor="log-in-email">Email</label>
                                <input type="email" id='log-in-email' name='email' required value={loginData.email} onChange={(e) => setLoginData({...loginData,email: e.target.value})} />
                            </div>
                          {loginWith=='password' && <div className="input">
                            <label htmlFor="log-in-password">Password</label>
                            <input type="password" id='log-in-password' name='password' required value={loginData.password} onChange={(e) => setLoginData({...loginData,password:e.target.value,lastname:''})} />
                            </div>}  
                          {loginWith=='lastname' && <div className="input">
                                <label htmlFor="last-name">Last Name</label>
                                <input type="text" id='last-name' name='lastname' value={loginData.lastname} onChange={(e) => setLoginData({...loginData,lastname:e.target.value,password:''})} required/>
                            </div>}
                            <div className="btns-log">
                                <button className='log-in-btn' onClick={() => loginForm()}>Log In</button>
                                <p onClick={()=> setLoginWith(loginWith=='password'?'lastname':'password')} className='specialbutton'>
                                    {loginWith=='password'?'Login With e-mail and lastname':'Login With e-mail and password'}</p>
                            </div>  
                            <p className='error'>{errorMessage}</p>
                        </div>}
                        {!isUserLoggedIn && loginView!='login' && <div className="firstPart">
                            <div className="title">
                                <h2>Register</h2>
                            </div>
                            <div className="inputs">
                                    <div className="input">
                                        <label htmlFor="first-name">First Name</label>
                                        <input type="text" id='first-name' name='firstname' value={signupData.firstname} onChange={signupChange} required/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="last-name">Last Name</label>
                                        <input type="text" id='last-name' name='lastname' value={signupData.lastname} onChange={signupChange} required/>
                                    </div>
                                </div>
                                <div className="inputs">
                                   
                                   <div className= "input">
                                       <label htmlFor="signup-email">Your Email</label>
                                       <input type="email" id='signup-email' name='email' value={signupData.email} onChange={signupChange} required/>
                                   </div>
                               </div>
                               <div className="inputs">
                                   <div className="input">
                                       <label htmlFor="create-password">Create your Password</label>
                                       <input type="password" id='create-password' name='password' value={signupData.password} onChange={signupChange} required/>
                                   </div>
                                   
                               </div>
                               <div className="inputs">
                                   <div className="input">
                                       <label htmlFor="phone">Phone Number</label>
                                       <input type="tel" id='phone' name='phone' value={signupData.phone} onChange={signupChange} required />
                                   </div>
                                   <div className="input">
                                       <label htmlFor="zip-code">ZIP Code</label>
                                       <input type="text" id='zip-code' name='zipcode' value={signupData.zipcode} onChange={signupChange} required/>
                                   </div>
                               </div>
                               <div className="input">
                                   <label htmlFor="address-one">Address</label>
                                   <input type="text" id='address-one' name='address1' value={signupData.address1} onChange={signupChange} required/>
                               </div>
                               <div className="inputs">
                        <div className="input">
                            <label htmlFor="city">City</label>
                            <input type="text" id='city' name='city' value={signupData.city} onChange={signupChange} required/>
                        </div>
                        <div className="input">
                            <label htmlFor="states">State</label>
                            <select name="state" id="states" value={signupData.state} onChange={signupChange} required>
                                { states.map((state, idx) => {
                                    return(
                                        <option key={idx} value={state}>{state}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="btns-log">
                            <button className='log-in-btn' onClick={() => registerForm()}>Register</button>
                        </div>
                    </div>
                            <p className='error'>{errorMessage}</p>
                        </div> } */}
                        <div className="firstPart">
                            <div className="title">
                                <h2>Parking Details</h2>
                            </div>
                          {/*   <div className="input">
                                <label htmlFor="checkout-phone">Phone Number</label>
                                <input type="tel" id='checkout-phone'name='phone' required value={reservationDetails.searchReservation.phone} onChange={reservationChange}/>
                            </div> */}
                            <div className="input">
                                <label htmlFor="airline-name">Name of Airline or Cruise Ship</label>
                                <input type="text" id='airline-name' name='vesselname' required value={reservationDetails.searchReservation.vesselname} onChange={reservationChange} />
                            </div>
                            <div className="input">
                                <label htmlFor="person-parking">Person Parking</label>
                                <input type="text" id='person-parking' name='parkingperson' required value={reservationDetails.searchReservation.parkingperson} onChange={reservationChange}/>
                            </div>
                            <div className="input">
                                <label htmlFor="people-travelling">Number of People Travelling</label>
                                <select name="numofpersons" id="people-travelling" required value={reservationDetails.searchReservation.numofpersons} onChange={reservationChange}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </select>
                            </div>
                            <div className="input">
                                <label htmlFor="car-number">Number of Car(s)</label>
                                <select name="carCount" id="car-number" required value={reservationDetails.searchReservation.carCount} onChange={reservationChange}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                            <div className="input">
                                <label htmlFor="plate-num">Car(s) Plate Number</label>
                                <input type="text" id='plate-num' required value={reservationDetails.searchReservation.plateNumber} onChange={reservationChange} name='plateNumber'/>
                                <p className="smallnote">Note: If your number of cars are more than one, please input all plate numbers and separate them with a comma</p>
                            </div>
                            <div className="input">
                                <label htmlFor="car-details">Car Details</label>
                                <textarea name="carDetails" id="car-details" required value={reservationDetails.searchReservation.carDetails} onChange={reservationChange}></textarea>
                            </div>
                        </div>
                      {(mainResvId==0 || data?.finalpay.toFixed(2)>0) && <div className="secondpart">
                            <div className="title">
                                <h2>Payment Details</h2>
                            </div>
                            <div className="credit-input">
                                <div className="image">
                                    <label htmlFor="credit-card">Credit Card Number</label>
                                    <img src={Card} alt="Allowed Cards" />
                                </div>
                                <input type="text" id='credit-card' name='card' required value={cardDetails.card} onChange={cardChange}/>
                            </div>
                            <div className="numInps exp-date">
                                <label htmlFor="exp-date">Expiration Date</label>
                                <div className="inputs">
                                    <select name="month" id="exp-date" value={cardDetails.month} onChange={cardChange}>
                                        <option value="01">01 - January</option>
                                        <option value="02">02 - February</option>
                                        <option value="03">03 - March</option>
                                        <option value="04">04 - April</option>
                                        <option value="05">05 - May</option>
                                        <option value="06">06 - June</option>
                                        <option value="07">07 - July</option>
                                        <option value="08">08 - August</option>
                                        <option value="09">09 - September</option>
                                        <option value="10">10 - October</option>
                                        <option value="11">11 - November</option>
                                        <option value="12">12 - December</option>
                                    </select>
                                    <p>/</p>
                                    <select name="year" id="exp-date" required value={cardDetails.year} onChange={cardChange}>
                                        {validyrs().map((item, idx) =>{
                                            <option value={item}>{item}</option>
                                        })}
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                        <option value="2027">2027</option>
                                        <option value="2028">2028</option>
                                        <option value="2029">2029</option>
                                        <option value="2030">2030</option>
                                        <option value="2031">2031</option>
                                        <option value="2032">2032</option>
                                        <option value="2033">2033</option>
                                        <option value="2034">2034</option>
                                        <option value="2032">2035</option>
                                        <option value="2033">2036</option>
                                        <option value="2034">2037</option>
                                    </select>
                                </div>
                            </div>
                            <div className="numInps back-num">
                                <div className="label">
                                    <label htmlFor="back-num">Card Security Code</label>
                                    <p onClick={() => setShowCvv(!showCvv)}>{ showCvv ? 'Close Dialog' :'What is this?'}</p>
                                </div>
                                { showCvv && <p className='explainCvv'>The Card Security Code (CSC), is typically a three or four-digit number located on the card itself. Its purpose is to provide an extra layer of security during online or card-not-present transactions.</p>}
                                <input type="text" id='back-num' name='ccv' required value={cardDetails.ccv} onChange={cardChange}/>
                            </div>
                            <div className="checked">
                                <input type="checkbox" id='checkout-check' ref={checkboxRef}/>
                                <label htmlFor="checkout-check">By checking off this box I acknowledge that I have read and agreed to the terms of use.</label>
                            </div>
                        </div>}  
                        {(mainResvId>0 && data?.finalpay.toFixed(2)<0) && <div className="secondpart">
                            <div className="title">
                                <h2>Refund Details</h2>
                            </div>
                            <div className="credit-input">
                                <div className="image">
                                    <label htmlFor="credit-card">Credit Card Number(For refund (use same card you use to purchase))</label>
                                    <img src={Card} alt="Allowed Cards" />
                                </div>
                                <input type="text" id='credit-card' name='card' required value={cardDetails.card} onChange={cardChange}/>
                            </div>
                           
                            <div className="checked">
                                <input type="checkbox" id='checkout-check' ref={checkboxRef}/>
                                <label htmlFor="checkout-check">By checking off this box I acknowledge that I have read and agreed to the terms of use.</label>
                            </div>
                        </div>} 
                        {(mainResvId>0 &&   data?.finalpay.toFixed(2)==0) && <div className="secondpart">
                            <div className="title">
                                <h2>Payment info is not required</h2>
                            </div>
                           
                           
                            <div className="checked">
                                <input type="checkbox" id='checkout-check' ref={checkboxRef}/>
                                <label htmlFor="checkout-check">By checking off this box I acknowledge that I have read and agreed to the terms of use.</label>
                            </div>
                        </div>} 

                        <div className="terms">
                            <h2>TERMS OF USE</h2>
                            <p>This page describes the terms and conditions applicable to your use of the services offered through this website. Read this document carefully! If you are unable or unwilling to abide by the terms and conditions stated in this document, DO NOT USE this website or any of the services provided herein. By using this website and its services, you unconditionally agree to be bound by the terms and conditions set forth herein. <br />
                            NAMES
                            The owner/operator of this website is referred to in these Terms of Use as the Webmaster. The owner and the operator of the parking facility where you elect to book a reservation are referred to in this Terms of Use collectively as the Operator. <br />
                            PARKING & OTHER CHARGES
                            The advertised rates on the website at any given time are the then-applicable parking charges only for the parking facility you choose. The final amount billed to you may include a service fee to the Webmaster plus applicable taxes and credit/debit card transaction fees. You agree to pay all such charges and fees. Please also keep in mind that each parking facility and each state may charge different rates and fees. <br />
                            WHEN YOU PAY
                            Each lot is handled differently. Most lots require you to pay your entire reservation costs in full at the time of booking. However, some may require you to pay for one day only, plus the reservation fee with the remaining balance being due at the time you arrive at the parking facility. Payment is made on our website using a credit card at the time of booking. Credit card processing will occur in real-time and any card processing fees will be added to the quoted rate that you must pay. <br />
                            BOOKING CONFIRMATION NOTICE
                            Immediately upon purchasing your reservation, you will be emailed a booking confirmation. Please print your booking confirmation and present it to the designated representative at the parking facility upon your arrival. Some parking facilities may you require you to fill in the make and model of your vehicle and the license plate number and issuing state on the booking confirmation. <br />
                            WHERE THE PARKING FACILITIES ARE LOCATED
                            All parking facilities are located within close proximity to the airport. Directions will be contained in the booking confirmation that is emailed to you immediately upon booking your reservation. <br />
                            ACCESS TO PARKING FACILITIES
                            All parking facilities will generally be accessible 24 hours a day, 365 days per year. However, you will only be able to check-in and have use of the shuttle during each parking facilities particular hours of operation. Hours of Operation for lot accessibility and shuttle accessibility vary per parking facility. Parking facilities will not accept your vehicle if it is a commercial, recreational or other oversized vehicle that cannot fit into a single parking stall. All of the parking facilities are "park at your own risk". Neither Webmaster nor Operator will be responsible for your vehicle or any items left inside your vehicle. <br />
                            TRANSPORTATION TO THE AIRPORT
                            Transportation to and from the airport is provided by each parking facility, free of charge. Shuttle hours of operation and frequency vary from facility to facility. Please allow at least 30 minutes of additional time when arriving at the parking facility to facilitate getting to the airport on time. Most airports recommend arriving at your terminal at least 2 hours earlier than your scheduled flight. Please know that you can check in, in advance, by calling the facilities front desk if you wish to use the parking facility, but not the shuttle service. <br />
                            CHANGING YOUR RESERVATION
                            Your charges reflect the arrival and departure dates indicated when you made your reservation. If your reservation should change, such changes may be subject to parking availability and additional charges may apply. If you need to make any changes, you will need to contact us. <br />
                            MAKING A RESERVATION FOR SOMEONE ELSE
                            The Webmaster does allow you to make a reservation for someone other than yourself. You will only need to put the name of the person parking the car on the reservation form where it says "Person Parking Car" on the payment page. <br />
                            PARKING SPACE SUBSTITUTIONS
                            On rare occasions, a parking facility may have to change a parking option. If an upgrade occurs, then you will not be assessed any additional charges. If a service reduction occurs (such as changes in parking from indoor to outdoor, covered to uncovered, etc.), then rates will be adjusted according to the level of parking service provided. <br />
                            ADDITIONAL CHARGES FOR STAYING LONGER THAN RESERVED
                            You hereby give to the Webmaster your expressed authority, without further notice to or communication with you, to charge to the credit card you used to book your reservation the additional parking fees you owe to Operator for each day beyond the final date of your reservation you use the parking facility (Additional Fees). The daily additional fee rate is that shown on the website at the time you book your reservation. <br />
                            OPERATOR'S RIGHT TO REMOVE YOUR VEHICLE FROM THE PARKING FACILITY
                            You agree that Operator has the right, at your sole cost and expense, to have your vehicle removed from the Parking Facility in the event you leave your vehicle in the parking facility for more than ten (10) days beyond the final date of your reservation as booked by you with the Webmaster. In such event, your vehicle will be moved to the property of the nearest available vehicle towing company. You agree that you are solely responsible for any and all towing and storage fees that are charged by the towing company, in addition to the Additional Fees you owe the Operator. <br />
                            CANCELLATION POLICY
                            Reservations must be cancelled at least 48 hours prior to the date and time you are scheduled to arrive at the parking facility. If you cancel less than 48 hours from your reserved time at the parking facility, you will forfeit all monies paid. If you make a reservation within 48 hours of your arrival date and time at the parking facility and cancel the reservation, you will forfeit all monies paid. If you cancel more than 48 hours prior to your reserved time at the parking facility, a full refund, less the Webmaster's service fee(s) will be credited back to your credit or debit card within seventy-two (72) hours thereafter. The Webmaster's services may include, but are not limited to, the $5.00 Customer Service fee and any Credit/Debit Card Transaction fees that may have been paid at the time you paid for your reservation.
                            If you wish to cancel a reservation, you could do any of the following:
                            Logon to your New Orleans Parking account. Locate the specific reservation and click the cancel button located to the right of it. You will be required to enter the last four digits of the credit card you used to make the reservation.
                            Call our customer service number at 865-366-7014 and provide our customer service representative with your transaction ID and request a cancellation.
                            Take the following steps to e-mail your cancellation request: <br />
                            Step 1: Open a copy of your e-mail confirmation. <br />
                            Step 2: Remove the information in the Subject Line, and then type in "Cancel". <br />
                            Step 3: Forward (not "reply") a copy of your modified e-mail confirmation to  Services@Neworleansparking.com <br />
                            VOLUNTARY ASSUMPTION OF RISK
                            This website functions as an information source to connect travelers in need of airport or cruise port parking with privately owned parking facilities. Webmaster requires the parking facility operators to make every good faith effort to honor your reservation. In the event of unforeseen situations, including but not limited to, Acts of God, War, or other conditions such as, severe weather conditions, your reservation could be cancelled. Although Webmaster attempts to affiliate with only reputable, licensed and insured companies, Webmaster has no control over the quality or safety of any parking facility.
                            You freely, knowingly, and voluntarily assume and are solely responsible for all risks of any kind associated with: <br /> (i) using this website; <br /> (ii) dealing with the Operator; <br /> (iii) dealing with any other third parties with whom you may come into contact with as a result of or incident to your use of this website; <br />(iv) being on the Operator's property; <br /> (v) parking at the parking facility; and (vi) using any shuttle service. You also park at your own risk and are solely responsible for your vehicle and any items left inside your vehicle. <br />
                            Although the Webmaster makes every effort to provide accurate and clear information on the website, the material on this website may contain inaccuracies or typographical errors. Neither the Webmaster nor the Operator makes any representations about the accuracy, completeness, reliability, or timeliness of the website or the information provide therein. Changes are made to this website periodically and may be made at any time without notice. Your use of this website and the information posted on this website and the services provided through this website are at your own risk. <br />
                            DISCLAIMER OF ALL WARRANTIES
                            This website and the information posted on this website are provided on an "as is" basis without any express or implied warranties of any kind. To the fullest extent permitted by law, the Webmaster disclaims all warranties, whether expressed or implied, including but not limited to the warranty of non-infringement, fitness for a particular use or purpose and merchantability. The Webmaster makes no warranties about the accuracy, completeness, reliability, or timeliness of the information, services, software, text, graphics, links, or transactions with Operators.
                            The Webmaster does not warrant that this website will operate without error. The Webmaster does not warrant that the website or its server is free of computer viruses or any other harmful mechanisms. If your use of this website or the mechanisms within this website results in the need for servicing or replacing equipment or data, the Webmaster is not responsible for such actions or any resulting expenses, such losses, costs and/or expenses being subject to the indemnity provisions set forth above. <br />
                            DISCLAIMER OF ACTUAL, INCIDENTAL, CONSEQUENTIAL, AND OTHER DAMAGES
                            In no event shall the Webmaster, the Operator, or any third parties mentioned on this website be liable for any damages whatsoever including, but not limited to, incidental damages, consequential damages, loss of profits, loss of income, damages resulting from lost data, business interruption damages, or any other kinds of damage resulting from or incident to the use or inability to use this website and/or any information posted on this website, whether based on warranty, contract, tort, or any other legal provision or principle. <br />
                            INDEMNITY IN FAVOR OF WEBMASTER AND OPERATOR
                            You agree to protect, defend, indemnify and hold the Webmaster and the Operator, and their respective directors, officers, employees, agents and representatives completely harmless from and against any and all liabilities, losses, suits, claims, judgments, fines or demands of any kind arising by reason of injury to or death of any person, or damage to any property, including reasonable attorney's fees, court costs, expenses, and expert witness fees, of any nature whatsoever arising out of or incident to or in any manner associated with: <br />(i) any reservations made hereunder;  <br />(ii) any occurrences or incidents at or related to the parking facility; <br /> (iii) the use or occupancy of the parking facility; <br /> (iv) any occurrences or incidents in, on or related to the use of any shuttle service; and <br /> (v) the use or occupancy of any shuttle; regardless of where the injury, death, or damage may occur. <br />
                            You further agree to protect, defend, indemnify hold the Webmaster and the Operator, and their respective directors, officers, employees, agents and representatives completely harmless from and against any and all travel-related costs, expenses, losses, claims, fines, or demands of any kind associated with missed flights, rescheduling flights, lodging, meals, transportation, inconvenience, mental anguish, or other direct or indirect costs, losses or expenses in any manner associated with or incident to: <br /> (i) any reservations made hereunder; <br /> (ii) any occurrences or incidents at or related to the parking facility; <br /> (iii) the use or occupancy of the parking facility; <br /> (iv) any occurrences or incidents in, on or related to the use of any shuttle service; <br /> (v) the use or occupancy of any shuttle; <br /> and (vi) any occurrences or incidents in, on or related to the storage of your vehicle in any municipal garage to which your car was relocated; regardless of when or where the cost, lost or expense may occur. <br />
                            COPYRIGHTS AND TRADEMARKS
                            New Orleans Parking. New Orleans Parking is a trade name. All rights are reserved. Their graphics, logos, trade names, and other published material may not be used without their prior written consent. Intellectual property owned, controlled, acquired, stored, distributed or licensed by them or by third-parties who have licensed the same to them are protected by U.S. and International copyright laws. <br />
                            WEBSITE USE
                            Users are strictly prohibited from violating or attempting to violate the security of this website, including, but not limited to: <br /> (i) accessing data not intended for such user; <br /> (ii) attempting to interfere with service to any user, host or network; <br /> (iii) submitting a virus to this website; <br />(iv) overloading, flooding, spamming, or crashing; <br /> (v) attempting to access other parties accounts without authorization; <br /> (vi) attempting to probe, scan or test the vulnerability of a system or network; <br /> (vii) attempting to breach security or authentication measures without prior authorization; or <br /> (viii) attempting to access, alter, corrupt or delete programming. Any of the foregoing, as well as any other violations of this website or any part or system thereof, may result in civil and/or criminal action. <br />
                            CONCLUSIVE EVIDENCE OF YOUR ACCEPTANCE
                            You making a reservation on this website to park at operator's parking facility shall be conclusive evidence, as against you, that you understand and accept all of these Terms of Use and agree to be fully bound hereby. <br />
                            SEVERABILITY
                            The provisions of these Terms of Use are intended to be severable. If for any reason any provision of these Terms of Use shall be held invalid or unenforceable in whole or in part in any jurisdiction, such provision shall, as to such jurisdiction, be ineffective solely to the extent such part is invalid or unenforceable, the remainder of these Terms of Use to remain in full force and effect. However, if any provision is unenforceable or invalid in one particular jurisdiction, such unenforceability or invalidity will not affect in any manner the validity or enforceability thereof in any other jurisdiction or the remaining provisions hereof in any jurisdiction. <br />
                            HEADINGS FOR CONVENIENCE
                            The captions to the paragraphs or subparagraphs of these Terms of Use and the title of the exhibit itself are solely for the convenience of the parties, are not a part of the Terms of Use, and shall not be used for the interpretation of, or determination of the validity of these Terms of Use or any provision hereof. <br />
                            JURISDICTION AND VENUE
                            You understand and agree that any and all disputes between you, the Webmaster, and/or the Operator arising out of or associated with: <br /> (i) any reservations made hereunder; <br /> (ii) any occurrences or incidents at or related to the parking facility; <br /> (iii) the use or occupancy of the parking facility; <br /> (iv) any occurrences or incidents in, on or related to the use of any shuttle; and <br /> (v) the use or occupancy of any shuttle shall be subject to jurisdiction only in the state in which the parking facility is located, and all parties freely and voluntarily consent to the jurisdiction of the state and federal courts of such state, and only those courts, for all such disputes. You, the Webmaster and the Operator further understand and agree that the venue for any such dispute shall be proper only in the county or district in which the parking facility is located, and each party freely and voluntarily consents to venue in that county, and only in that county, for all such disputes. <br />
                            WAIVER OF RIGHT TO JURY TRIAL
                            You, the Webmaster and the Operator expressly, freely, knowingly and voluntarily waive their respective rights to trial by jury of any action or proceeding between or among them or their successors arising out of or relating to these Terms of Use. <br />
                            ACKNOWLEDGEMENT
                            These Terms of Use, including all documents and information referenced herein, represent the entire understanding and agreement among you, the Webmaster and the Operator, and supersede any other offers, promises, representations or statements not contained herein. You agree to be bound by these Terms of Use by your use of this website. You acknowledge that these Terms of Use are subject to change without notice to or prior consent from you; however, any such changes after you make a reservation shall not be effective as to that particular reservation.</p>
                        </div>
                        <p className="terms-note">
                            By clicking the "Book Reservation" button, I authorize Neworleansparking.com to charge my credit card in the amount of <span>{mainResvId==0? Constants.formatCurrency(data?.onlinepay):Constants.formatCurrency(data?.finalpay)}</span>
                        </p>
                        <div className="submit-btn">
                            <button type='submit' >Book Reservation</button>
                            {showError && <p>Please click the checkbox before we continue</p>}
                            <p className='error'>{finalError}</p>
                        </div>
                    </form>
                </div>
            </div>
            <div className="right-side">
                <h2>Your Reservation Details</h2>

                <div className="discount-code">
                    <p onClick={() => setShowCode(!showCode)}>Do you have a discount code?</p>

                    <div className={ showCode ? "discount-inputs show" : "discount-inputs"}>
                        <input type="text" placeholder='Discount Code' value={reservationDetails.searchReservation.discountCode} onChange={reservationChange} name='discountCode' />
                        <input type="text" placeholder='Corporate Code' value={reservationDetails.searchReservation.corporateCodeCode} onChange={reservationChange} name='corporateCode' />
                        <button onClick={onDiscount}>Apply</button>
                    </div>
                </div>

                <div className="ticket">
                    <div className="header">
                        <h3>{formData.airportName} | {data?.parkinglotname}</h3>
                        <div className="edit-text">
                            <p>{searchData?.numofdays} days of parking with {reservationDetails.searchReservation.carCount} car(s) at ${data?.avgrate}/day/car</p>
                            <Link to={`/reservation/search/${reservationDetails.searchReservation.reservationID}`}>
                                <button>Edit</button>
                            </Link>
                        </div>
                    </div>
                  {mainResvId==0 && <div className="details">
                        <div className="each-detail">
                            <p className="title">Check In</p>
                            <p className="title-info">{formatDate(formData.startDate)}  {formData.startTime}</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Check Out</p>
                            <p className="title-info">{formatDate(formData.endDate)}  {formData.endTime} </p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Sub Total</p>
                            <p className="title-info">{Constants.formatCurrency(data?.subtotal) }</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Taxes & Fees</p>
                            <p className="title-info">{Constants.formatCurrency(data?.tax + data?.othertax + data?.servicefee + data?.accessfee ) }</p>
                        </div>
                       
                        <div className="each-detail">
                            <p className="title">Total</p>
                            <p className="title-info total">{Constants.formatCurrency(data?.totalamt) }</p>
                        </div>
                        {
                            data?.freedaysavings === 0 ? '' : <div className="each-detail">
                            <p className="title">Free Day Savings</p>
                            <p className="title-info total">{Constants.formatCurrency(data?.freedaysavings)}</p>
                        </div>
                        }
                        <div className="each-detail">
                            <p className="title">Paid Online</p>
                            <p className="title-info paid-online">{Constants.formatCurrency(data?.onlinepay)}</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Due at Lot</p>
                            <p className="title-info due">{Constants.formatCurrency(data?.dueatlot)}</p>
                        </div>
                    </div>}  
                    {mainResvId>0 && <div className="details">
                        <div className="each-detail">
                            <p className="title">Check In</p>
                            <p className="title-info">{formatDate(formData.startDate)}  {formData.startTime}</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Check Out</p>
                            <p className="title-info">{formatDate(formData.endDate)}  {formData.endTime} </p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Sub Total</p>
                            <p className="title-info">{Constants.formatCurrency(data?.subtotal) }</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Taxes & Fees</p>
                            <p className="title-info">{Constants.formatCurrency(data?.tax + data?.othertax + data?.servicefee + data?.accessfee ) }</p>
                        </div>
                       
                        <div className="each-detail">
                            <p className="title">Total</p>
                            <p className="title-info total">{Constants.formatCurrency(data?.onlinepay) }</p>
                        </div>
                        {
                            data?.freedaysavings === 0 ? '' : <div className="each-detail">
                            <p className="title">Free Day Savings</p>
                            <p className="title-info total">{Constants.formatCurrency(data?.freedaysavings)}</p>
                        </div>
                        }
                        <p className="title-info due">To Pay online ${data.onlinepay.toFixed(2)} - {Constants.formatCurrency(data?.alreadypaid) } Already Paid  </p>
                          {/* {data?.oldstatus=='changelot' && <p className="title-info due">To Pay online ${data.totalamt.toFixed(2)} - {Constants.formatCurrency(data?.alreadypaid) } Already Paid  </p>}
                                    {data?.oldstatus=='extend' && <p className="title-info due">You previously paid for ({searchData.numofdays- searchData.currentdays})days @ {Constants.formatCurrency(data?.alreadypaid)}. You are adding {searchData.currentdays}day(s) at the avg rate specified above. </p>}
                                    {data?.oldstatus=='short_trip' && <p className="title-info due">To Pay online ${data.totalamt.toFixed(2)} - {Constants.formatCurrency(data?.alreadypaid) } Already Paid  </p>}
                                    {data?.oldstatus=='shift' && <p className="title-info due">To Pay online ${data.totalamt.toFixed(2)} - {Constants.formatCurrency(data?.alreadypaid) } Already Paid  </p>}
                                    */}
                        
                        <div className="each-detail">
                            <p className="title">Paid Online</p>
                            <p className="title-info paid-online">{ Constants.formatCurrency(data?.finalpay)}</p>
                        </div>
                        <div className="each-detail">
                            <p className="title">Due at Lot</p>
                            <p className="title-info due">{Constants.formatCurrency((data?.alreadydue+ data?.dueatlot) )}</p>
                        </div>
                    </div>}   
                </div>
            </div>
         {isLoading &&  <div className={ "congrats show" }>
                <Loading message='Processing' />
            </div>}  

          {paymentDone && <div className={ paymentDone ? "success show" : "success"}>
                <div className="container">
                    <h2>Congratulations! Your payment was successful</h2>
                    <p className='main-note'>You can print your receipt now or head back to your reservations list. <br /> <span>Note: Your receipt is always available on your profile</span></p>
                    <div className="receipt-details">
                        <div className="barcode">
                            {data?.generatebarcode == "Yes"  &&  
                            <img src={"http://www.barcodesinc.com/generator/image.php?code="+confirmData.reservation.barcodenum+"&style=196&type=C128C&width=150&height=80&xres=1&font=3"} />}
                            {(data?.isAmano) &&  
                            <img alt="" style={{height:100, width:100, alignSelf:'center', margin:20}}  src={`https://chart.googleapis.com/chart?cht=qr&chl=${Constants.PREFIX}${transactionId}&chs=160x160&chld=L|0`} />
                            }
                         
                        </div>
                        <div className="row">
                            <p className="title">Reservation ID</p>
                            <p className="answer">{Constants.PREFIX}{transactionId}</p>
                        </div>
                        <div className="row">
                            <p className="title">Customer Name</p>
                            <p className="answer">{ confirmData.customer.firstname + ' ' + confirmData.customer.lastname}</p>
                        </div>
                        <div className="row">
                            <p className="title">Lot</p>
                            <p className="answer">{data?.parkinglotname}</p>
                        </div>
                        <div className="row">
                            <p className="title">Arrival</p>
                            <p className="answer">{formatDate(formData.startDate)}  {formData.startTime}</p>
                        </div>
                        <div className="row">
                            <p className="title">Departure</p>
                            <p className="answer">{formatDate(formData.endDate)}  {formData.endTime}</p>
                        </div>
                        <div className="row">
                            <p className="title">Paid Online</p>
                            <p className="answer">{Constants.formatCurrency(data?.onlinepay)}</p>
                        </div>
                        <div className="row">
                            <p className="title">Total</p>
                            <p className="answer">{Constants.formatCurrency(data?.onlinepay)}</p>
                        </div>
                        <div className="row">
                            <p className="title">Person Parking</p>
                            <p className="answer">{reservationDetails.searchReservation.parkingperson}</p>
                        </div>
                        <div className="row">
                            <p className="title">Number of Persons</p>
                            <p className="answer">{reservationDetails.searchReservation.numofpersons}</p>
                        </div>
                    </div>
                    <div className="down">
                        <button onClick={() =>  window.print()}>Print</button>
                        <div className="btns">
                            <button onClick={() => navigate(`/admin/full-receipt/${confirmData.reservation.reservationid}`)}>View Full Receipt</button>
                            <button onClick={() => navigate('/admin/reservations')}>Back to Reservations</button>
                        </div>
                    </div>
                </div>
            </div>}  
        </div>
    </div>
  )
}

export default AdminCheckout
import React, { useContext, useEffect, useState } from 'react'
import { ShareContext } from '../../context/ShareContext';
import { Link } from 'react-router-dom';
import '../../styles/User/reservation.css'
import TopBar from '../../components/User/TopBar'
import AirportForm from '../../components/User/AirportForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import * as Constants from '../../constants';
import LoadingImg from '../../assets/loading.gif';
import Expired from '../../components/Expired';
import ReactGA from 'react-ga';

function Reservation() {
      const { isUserLoggedIn, token, reservations, setReservations, setIsUserLoggedIn } = useContext(ShareContext);
      const [loadingStatus, setLoadingStatus] = useState(false);
      const navigate = useNavigate();
      const logOut = () => {
        // Clear token from local storage
        localStorage.removeItem('accessToken');
        // Clear other user-related data from local storage
        localStorage.removeItem('userData');
        // Set user logged in state to false
        setIsUserLoggedIn(false);
        // Navigate to the login page
        navigate('/reservation/login');
      };

  const [isExpired, setIsExpired] = useState(false);

    
      useEffect(() => {

        fetch(`${Constants.apiUrl}/reservation`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Authorization': token,
          },
        })
          .then((resp) => resp.json())
          .then((data) => {
            setLoadingStatus(true);
            // console.log(data);
            if(data.code==0){
                setReservations(data.reservations);
            }
            else if(data.code==-10)
            setIsExpired(true)
            // logOut();
          })
          .catch((error) => {
            console.log(error);
            setLoadingStatus(false);
          });
      }, [token, setReservations]);
    
      const changeDate = (dateInp) => {
        const date = new Date(dateInp);
    
        const formmattedDate = new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(date);
    
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
    
        const finalFormat = `${formmattedDate} ${formattedTime}`;
    
        return finalFormat;
      };
      ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className='reservations'>
        <TopBar />
      

        { isUserLoggedIn ? 
        <div className="main-page">
            <div className="heading-logged">
                <h1 className='title'>Reservations</h1>
                <Link to='/reservation/upcoming-reservations'>See Upcoming Reservations</Link>
            </div>
            <div className="body-loggedIn">
                <AirportForm  reservationId={0}/>
                <div className="table-container">
                {loadingStatus ? (
                    <table>
                        <thead>
                        <tr>
                            <th>Parking Information</th>
                            <th>Your Payments</th>
                            <th>Stay Duration</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {reservations && reservations.map((row, idx) => (
                            <tr key={idx}>
                            <td>
                                <h2 className='reserve-airport'>{row.parkinglotname}</h2>
                            </td>
                            <td>
                                <p className="payments">Paid Online: <span>{Constants.formatCurrency(row.totalpaid) }</span></p>
                                <p className="payments">At Lot: <span>{Constants.formatCurrency(row.payatlot)  }</span></p>
                                <h3 className="total">Total Amount: <span>{Constants.formatCurrency(row.totalpaid)  }</span></h3>
                            </td>
                            <td>
                                <p className="dates">From: <span>{changeDate(row.arrivaldate)}</span></p>
                                <p className="dates">To: <span>{changeDate(row.returndate)}</span></p>
                            </td>
                            <td className={row.reservationstatus === 'Canceled' ? 'status closed' : 'status open'}>{row.reservationstatus}</td>
                            {
                            row.reservationstatus === 'Canceled' ? <td></td> : 
                            <td>
                                <Link to={`/reservation/modify/${row.reservationid}`}>
                                    <button className={row.reservationstatus === 'Closed' ? 'extend' : 'modify'}>Modify <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                </Link> 
                                <Link to={`/reservation/full-receipt/${row.reservationid}`} target='_blank'>
                                                            <button className='extend'>Print Receipt</button>
                                                        </Link>
                                                        <Link to={`/reservation/receipt-tag/${row.reservationid}`} target='_blank'>
                                                            <button className='extend'>Print Tag</button>
                                                        </Link>
                              {row.reservationstatus === 'Open' && <Link to={`/reservation/cancel/${row.reservationid}`} > 
                                    <button className={'cancel'}>Cancel <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                </Link> }  
                            </td>
                            }
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    ) : <div className="loads">
                            <img src={LoadingImg} height={50} alt='Sponsor Logo' />
                            <p>Loading your Reservations</p>
                        </div>
                 }
                </div>
            </div>
        </div> :
            <div className="main-page">
                <div className="heading">
                    <h1>Reservations</h1>
                    <p>This page highlights the three easy steps to make a reservation on <span>New Orleans Parking</span>. Please Log in to see your reservations</p>
                </div>
                <div className="steps">
                    <div className="step">
                        <p className="number">1</p>
                        <div className="step-info">
                            <h2 className='title'>Select Airport or Seaport</h2>
                            <p className='message'>Select Airport and enter the arrival and return date for searching for available Parking lot</p>
                            <AirportForm />
                        </div>
                    </div>
                    <div className="step">
                        <p className="number">2</p>
                        <div className="step-info">
                            <h2 className='title'>Select a Parking Facility</h2>
                            <p className='message'>We will display available parking facilities close to the selected airport. You will have the ability to sort by lot's name, and price. You will also have the ability to map direction from your location to your selected parking facility.</p>
                        </div>
                    </div>
                    <div className="step">
                        <p className="number">3</p>
                        <div className="step-info">
                            <h2 className='title'>Book Your Reservations</h2>
                            <p className='message'>Once you have selected a parking facility, you will be directed to our secure checkout page. Returning customers can login and have their basic profile information pre-filled on the registration form. New customers would have to fill the registration form.
                            <br />
                            Once you book your reservation make sure you print out your receipt as this will be requested at the parking facility on arrival. Your receipt includes your travel itinerary; the amount paid online, outstanding amount to be paid at lot (if applicable) and directions to the parking facility</p>
                        </div>
                    </div>
                </div>
        </div>
        }
    </div>
  )
}

export default Reservation
import React, { useContext, useState} from 'react'
import { ShareContext } from '../../context/ShareContext';
import '../../styles/User/airportform.css'
import { timeArray } from '../../data/timeArray';
import { faSearch, faCar, faHotel, faBed } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import LoadingImg from '../../assets/loading.gif';
import * as Constants from '../../constants';
import moment from "moment";

function AdminAirportForm( props) {
    const { formData, setFormData, setIsFormSubmitted,  setSearchData, setReservationId} = useContext(ShareContext)
    const [loading, setLoading]=useState(false);
    const {reservationId} =props;
    const fetchurl=reservationId==null || reservationId==0?  `${Constants.apiUrl}/reservation/search`: `${Constants.apiUrl}/reservation/${reservationId}/search`;
    const navigateTo=reservationId==null || reservationId==0?'/admin/search': `/admin/search/${reservationId}`;
    console.log('fetch search url '+fetchurl);
    const currentDate =moment().add(-1,'d').format('YYYY-MM-DD');// new Date().toISOString().split('T')[0];

    const navigate = useNavigate();
    const isWidget= props.isWidget==null?false:props.isWidget;

    const handleChange = (e) => {
        console.log(`targetname: ${e.target.name}: ${e.target.value}`)
        setFormData({ ...formData, [e.target.name]: e.target.value});
    };

    const dummySubmit = async(e) => {
        setLoading(true);
        e.preventDefault();
   

        var searchparam=JSON.stringify(formData);
        console.log(searchparam);
        localStorage.setItem('searchdata', searchparam);
        try {
            const resp = await fetch(fetchurl, {
              method: 'POST',
              body: JSON.stringify(formData),
              headers: {
                'Content-Type': 'application/json',
              },
            });
            
            const responseData = await resp.json();
            setSearchData(responseData)
             console.log(responseData)
            if (responseData.code === 0) {
                setSearchData(responseData)
                navigate(navigateTo)
                setIsFormSubmitted(true)
            }else {
              console.log(responseData.status);
            }
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
        
    }

    const onChangeTab=(d)=>{
        
        if(d=='cruise'){
            setFormData({...formData,reservationtype:d, rateOption:'both', rateType:'daily'});
        }else if(d=='hotel'){
            setFormData({...formData,reservationtype:d, rateOption:'daily', rateType:'daily'});
        }else if(d=='downtown'){
            setFormData({...formData,reservationtype:d, rateOption:'hourly',rateType:'hourly'});
        }
    }
    const onChangeRate=(d)=>{
        setFormData({...formData,rateType:d});
    }

  return (
    <div className="search-airport">
        
        {/* <p className='note'>Note: Customers can choose any time they want even if the shuttle service does not run during those times</p> */}
        <form method='post' onSubmit={dummySubmit}>
          {!isWidget && <div className='hometabs'>
                <div className={formData.reservationtype=='cruise'?'hometabactive':'hometab'}  onClick={()=>onChangeTab('cruise')}> <FontAwesomeIcon icon={faCar} /> Cruise Parking</div>
                <div className={formData.reservationtype=='hotel'?'hometabactive':'hometab'} onClick={()=>onChangeTab('hotel')}><FontAwesomeIcon icon={faBed} /> Hotel Parking</div>
                {/* <div className={formData.reservationtype=='both'?'hometabactive':'hometab'} onClick={()=>onChangeTab('both')}><FontAwesomeIcon icon={faCar} />+<FontAwesomeIcon icon={faBed} /> Cruise and Hotel Parking</div> */}
                <div className={formData.reservationtype=='downtown'?'hometabactive':'hometab'} onClick={()=>onChangeTab('downtown')}><FontAwesomeIcon icon={faCar} /> Downtown Parking</div>
            </div>} 
           {formData.rateOption=='both' && <div className='hometabs'>
            <div className={formData.rateType=='daily'?'hometabactive':'hometab'}  onClick={()=>onChangeRate('daily')}> <FontAwesomeIcon icon={faCar} /> Overnight Rate</div>
            <div className={formData.rateType=='hourly'?'hometabactive':'hometab'}  onClick={()=>onChangeRate('hourly')}> <FontAwesomeIcon icon={faCar} /> Hourly Rate</div>
                </div> } 
            <div className="inputs">
           
                <div className='each'>
                    <div className="input">
                        <div className="label">
                            <p>Arrival Date</p>
                        </div>
                        <input type="date" name="startDate" id="arrival-date" value={formData.startDate} onChange={handleChange} required/>
                    </div>
                   
                    <div className="input">
                        <div className="label">
                            <p>Arrival Time</p>
                        </div>
                        <select name="startTime" value={formData.startTime}  onChange={handleChange} className="time arrival-time" required>
                        <option disabled>Arrival Time</option>
                            {
                                timeArray.map((time, id) => {
                                    return(
                                        <option key={id} value={time.value}>{time.time}</option>
                                    )
                                })
                            }
                    </select>
                    </div>

                   
                </div> 
                <div className='each'>
                 {formData.rateType=='daily' && <div className="input">
                        <div className="label">
                            <p>Departure Date</p>
                        </div>
                        <input type="date" name="endDate" id="departure-date" value={formData.endDate} onChange={handleChange} required min={formData.startDate}/>
                    </div>}   
                    <div className="input">
                        <div className="label">
                            <p>Departure Time</p>
                        </div>
                        <select name="endTime"  value={formData.endTime} className="time departure-time" onChange={handleChange}  required>
                            <option disabled>Departure Time</option>
                            {
                                timeArray.map((time, index) => {
                                    return(
                                        <option key={index} value={time.value}>{time.time}</option>
                                    )
                                })
                            }
                    </select>
                    </div>
                    
                </div>
            </div>
          {!isWidget && <div style={{flexDirection:'row'}}>
                <button type="submit" style={{ display:'inline'}}> Search <FontAwesomeIcon icon={faSearch} /></button>
          {/*  {reservationId>0 && <button type="button" style={{ display:'inline'}} onClick={()=>setReservationId(0)}> Clear Modification {reservationId} </button>}  */}
            </div> }  
            
            {loading && <img src={LoadingImg} height={50} alt='Sponsor Logo' />}            
    </form>
    </div>
    
  )
}


export default AdminAirportForm
import React, { useState, useContext, useEffect  } from 'react';
import '../../styles/Admin/manageclient.css';
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faRotateRight, faSearch, faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter, faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons'
import { states } from '../../data/userstates';
import { Link } from 'react-router-dom';
import { ShareContext } from '../../context/ShareContext';
import Loading from '../../components/Loading';
import * as Constants from '../../constants';
import moment from "moment";
import Expired from '../../components/Expired';

function ManageClient() {
    const { adminToken, isSidebarClosed } = useContext(ShareContext);
    const [openCreate, setOpenCreate] = useState(false)
    const [expandForm, setExpandForm] = useState(false)
    const [formToShow, setFormToShow] = useState('merge')
    const [merge, setMerge] = useState({
        destEmail: '',
        source: ''
    })
    const [mergeError, setMergeError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [newEmail, setNewEmail] = useState('');
    const [search, setSearch] = useState('');
    const [overflow, setOverflow] = useState(false);
    const [page, setPage] = useState({start:0,count:30,total:0});
    const [filteredData, setFilteredData]=useState([]);
    const [clients, setClients] =useState([]);
    const [client,setClient] =useState({res:[]});
    const [loading, setLoading] = useState(false)
  const [isExpired, setIsExpired] = useState(false);


    const [cust, setCustomer] =useState({});
    const [changeEmail, setChangeEmail]=useState('');

    const searchBar = (e) => {
        setSearch(e.target.value);
        if(e.target.value.trim()!=''){
                var filteredData_ = clients.filter(item => {
                    const searchMatch = item.firstname.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase());
                    return searchMatch
                })
                //seach if its empty
                if(filteredData_.length>0){
                    setFilteredData(filteredData_);
                }else{
                    onSearchClientsOnServer(e.target.value);
                }
                
                
        }else{
            setFilteredData(clients);
        }
        

    }
    const handlePageChange = (pageNum) => {
        setPage({ ...page, start: page.count * (pageNum - 1) });
    }
    const newEmailChange = (e) => {
        setNewEmail(e.target.value)
    }

    const editDetails = {
        id: '',
        firstname: '',
        lastname: '',
        email: '',
        password: 'dsvfsd',
        address: '',
        addressTwo: '',
        city: '',
        state: '',
        zipcode: '',
        emailOpt: '',
        mailOpt: '',
        callOpt: '',
        reservations: false,
        reservationId: 31426375, 
        checkInDate: 'Dec 14, 2023',
        checkInTime: '8:15 AM',
        checkOutDate: 'Dec 24, 2023',
        checkOutTime: '8:15 AM',
        paidOnline: 103.33,
        dueAtLot: 0,
        total: 103.33
    }

    const [editData, setEditData] = useState(editDetails)

    
    const [currentPageGroup, setCurrentPageGroup] = useState(1);
    const buttonsPerPage = 10;

    const renderPageButtons = () => {
        const totalPages = Math.ceil(page.total / page.count);
        const totalPagesGroups = Math.ceil(totalPages / buttonsPerPage);
    
        const startPage = (currentPageGroup - 1) * buttonsPerPage + 1;
        const endPage = Math.min(currentPageGroup * buttonsPerPage, totalPages);
    
        return Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
          <button
            key={startPage + index}
            onClick={() => handlePageChange(startPage + index)}
            className={startPage + index === Math.ceil(page.start / page.count) + 1 ? 'active' : ''}
          >
            {startPage + index}
          </button>
        ));
    };

      const handleNextPageGroup = () => {
        if (currentPageGroup < Math.ceil(page.total / page.count / buttonsPerPage)) {
          setCurrentPageGroup((prevGroup) => prevGroup + 1);
        }
      };
    
      const handlePrevPageGroup = () => {
        if (currentPageGroup > 1) {
          setCurrentPageGroup((prevGroup) => prevGroup - 1);
        }
      };

    const onLoadClients=({start,count})=>{
        setLoading(true)
        console.log(` start ${start} total selected ${page.total} `);
        fetch(`${Constants.apiUrl}/parkingadmin/clients/${start}/${count}/${page.total}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            }
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
            // console.log('load clients')
            if(jresp.code==-10){
                setIsExpired(true)
            }
           setClients(jresp.clients);
           setFilteredData(jresp.clients);
        //    console.log(jresp.clients);
           setPage({...page,total:jresp.total})
           setLoading(false)
          })
          .catch((error) => {
            console.log(error);
            setLoading(false)
          })
    }
   
    const onLoadClient=(id)=>{
        setLoading(true)
        fetch(`${Constants.apiUrl}/parkingadmin/clients/${id}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            }
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
            console.log('return for customer')
            console.log(jresp);
           setClient(jresp);
           setCustomer({...jresp.c,...jresp.optout});
           setLoading(false)
           if(jresp.code==-10){
            setIsExpired(true)
           }
          })
          .catch((error) => {
            console.log(error);
           setLoading(false)
          })
    }
    //coded search with text
    const onSearchClientsOnServer=(value)=>{
        fetch(`${Constants.apiUrl}/parkingadmin/clients/search/${value}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            }
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
           //setClients(jresp.clients);
           setFilteredData(jresp.clients);
           console.log(jresp.clients);
          })
          .catch((error) => {
            console.log(error);
          })
    }
    const onResetCustomer=()=>{
        fetch(`${Constants.apiUrl}/parkingadmin/customer/reset/${cust.email}`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            }
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
           //setClients(jresp.clients);
           if(jresp.code==0){
            alert("Pasword reset Successfully");
            setOpenCreate(false);
           }else if(jresp.code==-10){
            setIsExpired(true)
           } else{
            alert(jresp.status);
           }
          })
          .catch((error) => {
            console.log(error);
          })
    }

    const onCustomerChangeEmail=()=>{
       //  console.log(cust);
        var rdata={oldEmail:cust.email, newemail: newEmail};
       // console.log(JSON.stringify(rdata));
        fetch(`${Constants.apiUrl}/parkingadmin/changeEmail`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
            body: JSON.stringify(rdata)
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
           //setClients(jresp.clients);
           if(jresp.code==0){
            alert("email changed Successfully");
            setOpenCreate(false);
            setNewEmail('');
           }else if(jresp.code==-10){
            setIsExpired(true)
           }else{
            alert(jresp.status);
           }
          })
          .catch((error) => {
            console.log(error);
          })
    }
    

    useEffect(() => {
        onLoadClients(page);
    }, [adminToken])

    const onNextPage=()=>{
        var page_={...page, start:page.start+30};
        setPage(page_);
        onLoadClients(page_);
    }
      
    //   console.log(page);

      const onPrevPage=()=>{
        var page_={...page, start:page.start-30};
        console.log(page_);
        if(page_.start<0){
            return;
        }
        setPage({...page, page_});
        console.log(page);
        onLoadClients(page_)
      }

    const togglePassword = () => {
        setShowPassword(!showPassword)
    }

    const getPassWord = () => {
        if (showPassword) {
            return editDetails.password
        } else {
            return editDetails.password.replace(/./g, '*')
        }
    }

    const openMerge = () => {
        setOpenCreate(true)
        setFormToShow('merge')
    }

    const mergeChange = e => {
        setMerge({...merge, [e.target.name]: e.target.value})
    }

    const editChange = e => {
        setEditData({...editData, [e.target.name]: e.target.value})
    }

    const reserve = (id) => {
        const idData = filteredData.find(item => item.email === id)
    
        return idData
    }

    const resetEmail = () => {
        const updatedData = {...editData}

        if (newEmail !== '') {
            updatedData.email = newEmail

            setOpenCreate(false)

            setNewEmail('')
        } 

        setEditData(updatedData)
    }

    const editTheRow = (id) => {
        setOpenCreate(true)
        setFormToShow('editDetails')
        const data = reserve(id);
    
        setOverflow(true)
        console.log('selected customer');
      console.log(data);
        setCustomer(data);
        onLoadClient(id);
    }

    const resetPassword = (id) => {
        setOpenCreate(true)
        setFormToShow('resetPassword')
        const data = reserve(id);
    
        setCustomer(data);
    }

    const emailChange = (id) => {
        setOpenCreate(true)
        setFormToShow('changeEmail')
        const data = reserve(id);
        console.log(data);
        setCustomer(data);
    }

    const mergeSubmit = e => {
        e.preventDefault();
        var rdata={destemail:merge.destEmail, sourceemail : merge.source }
        if (merge.destEmail === '' || merge.source === '') {
            setMergeError(true)
            return;
        } 
       
        console.log(JSON.stringify(rdata));
        fetch(`${Constants.apiUrl}/parkingadmin/customer/merge`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
            body: JSON.stringify(rdata)
          })
          .then((resp) => resp.json())
          .then((jresp) =>  {
           //setClients(jresp.clients);
           if(jresp.code==0){
            alert("email changed Successfully");
            setOpenCreate(false);
            
           }else{
            alert(jresp.status);
           }
          })
          .catch((error) => {
            console.log(error);
          })

    }

    const closeMerge = () => {
        setOpenCreate(false);

        setMergeError(false)

        setMerge({
            destEmail: '',
            source: ''
        })

        setNewEmail('');
        setOverflow(false)
    }


  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
          { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}
            <div className="manage-client">
                <div className="head">
                    <h1>Manage Client</h1>
                    <p>See clients and search particular client</p>
                </div>
                <div className="body-wrapper">
                    <div className="head-block">
                        <h3>Client Side</h3>
                        <div className="head-icons">
                            <FontAwesomeIcon icon={faRotateRight} className='action-icon refresh'/>
                        </div>
                    </div>
                    <div className="adding-client">
                        <button onClick={openMerge}>Merge Account</button>
                        <div className="search-input">
                        <FontAwesomeIcon icon={faSearch} />
                        <input type="text" placeholder='Search Client (name, email)' value={search} onChange={searchBar} />
                        </div>
                    </div>
                    <div className={ overflow ? "table-wrapper hide" :"table-wrapper"}>
                        <table>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>E-mail</th>
                                    <th>Phone Number</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                  filteredData.length>0 &&  filteredData.map((user, idx) => {return(
                                        <tr key={idx}>
                                            <td>{user.firstname}</td>
                                            <td>{user.lastname}</td>
                                            <td>{user.email}</td>
                                            <td>{user.phone}</td>
                                            <td>{user.city}</td>
                                            <td>{user.state}</td>
                                            <td>
                                                <ul>
                                                    <li onClick={() => editTheRow(user.email)}>Details</li>
                                                    <li onClick={() => resetPassword(user.email)}>Password Reset</li>
                                                    <li onClick={() => emailChange(user.email)}>Change E-mail</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button type='button' onClick={onPrevPage} className='reset-btn'>Previous</button>
                            <div className="btns-for-page">
                                <button onClick={handlePrevPageGroup} className='arrow-btn'><FontAwesomeIcon icon={faAngleLeft} /></button>
                                {renderPageButtons()}
                                <button onClick={handleNextPageGroup} className='arrow-btn'><FontAwesomeIcon icon={faAngleRight} /></button>
                            </div> 
                            <button type='button' onClick={onNextPage} className='reset-btn'>Next</button>
                        </div>
                        
                        
                    {loading && <div className={  "congrats show" }>
                            <Loading message={'Loading Clients'}/>
                        </div>}    
                            
                        
                    </div>
                    <div className={ openCreate ? "client-wrapper active" : "client-wrapper"}>
                        <div className={ formToShow === "editDetails" ? "close-it hide" : "close-it"} onClick={closeMerge}>
                            <FontAwesomeIcon icon={faClose} />  
                        </div>
                        { formToShow === 'merge' && <div className={expandForm ? "merge-clientbox expanded": "merge-clientbox"}>
                            <div className="head">
                                <h3>Merge Account</h3>
                                <FontAwesomeIcon icon={expandForm ?faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} className='close-create' onClick={() => setExpandForm(!expandForm)} />
                            </div>
                            <form method="post" onSubmit={mergeSubmit}>
                                <div className="input">
                                    <label htmlFor="dest-email">Destination E-mail</label>
                                    <input type="email" name="destEmail" id="dest-email" value={merge.destEmail} onChange={mergeChange} />
                                </div>
                                <div className="input">
                                    <label htmlFor="merge-source">Source(s)</label>
                                    <input type="email" name="source" id="merge-source" value={merge.source} onChange={mergeChange}/>
                                    <p className="noted">Note: Source E-mails, separated by comma</p>
                                </div>
                                <div className="end-btn">
                                    { mergeError && <p>Please enter both inputs</p> }
                                    <div className="btns">
                                        <button type='submit'>Merge</button>
                                        <p onClick={closeMerge} className='goBack'>Back to Clients</p>
                                    </div>
                                    <p className="small">This process will be permanent</p>
                                </div>
                            </form>
                        </div>}
                        {
                            formToShow === 'editDetails' && 
                                <div className="merge-clientbox expanded">
                            <div className="head">
                                <h3>Profile</h3>
                                <div className="close-details"  onClick={closeMerge}>
                                    <FontAwesomeIcon icon={faClose} />  
                                </div>
                            </div>
                            <div className="details-body">
                                <form method="post" onSubmit={mergeSubmit}>
                                    <div className="inputs">
                                        <div className="input">
                                            <label htmlFor="firstname">First Name</label>
                                            <input type="text" name="firstname" id="firstname" value={cust.firstname} onChange={editChange} />
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lastname">Last Name</label>
                                            <input type="text" name="lastname" id="lastname" value={cust.lastname} onChange={editChange} />
                                        </div>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="email">Your E-mail</label>
                                        <input type="email" name="email" id="email" value={cust.email} disabled/>
                                    </div>
                                    <div className="password">
                                        <p>Password</p>
                                        <div className="show-hide">
                                            <p>{getPassWord()}</p>
                                            <button onClick={togglePassword}>{ showPassword ? 'Hide' : 'Show'}</button>
                                        </div>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="address">Address</label>
                                        <input type="text" name="address" id="address" value={cust.address} onChange={editChange}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="addressTwo">Address Two</label>
                                        <input type="text" name="addressTwo" id="addressTwo" value={cust.addressTwo} onChange={editChange}/>
                                    </div>
                                    <div className="inputs">
                                        <div className="input">
                                            <label htmlFor="city">City</label>
                                            <input type="text" name="city" id="city" value={cust.city} onChange={editChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="state">State</label>
                                            <select name="state" id="state" value={cust.state} onChange={editChange}>
                                                <option value="" disabled>Select State / Province</option>
                                                {states.map((state, id) => {
                                                    return( <option key={id} value={state.code}>{state.name}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="zipcode">ZIP Code</label>
                                            <input type="text" name="zipcode" id="zipcode" value={cust.zipcode} onChange={editChange} />
                                        </div>
                                    </div>
                                    <div className="inputs">
                                        <div className="input">
                                            <label htmlFor="donotemail">E-mail Optout</label>
                                            <select name="donotemail" id="donotemail" value={cust.donotemail} onChange={editChange}>
                                                <option value="" disabled selected></option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="mailOpt">Mail Optout</label>
                                            <select name="donotmail" id="donotmail" value={cust.donotmail} onChange={editChange}>
                                                <option value="" disabled selected></option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="callOpt">Call Optout</label>
                                            <select name="donotcall" id="donotcall" value={cust.donotcall} onChange={editChange}>
                                                <option value="" disabled selected></option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="end-btn">
                                        <div className="btns">
                                            <button type='submit'>Update Profile</button>
                                            <p onClick={closeMerge} className='goBack'>Back to Clients</p>
                                        </div>
                                    </div>
                                </form>
                                <div className="next-side">
                                    <h2>Reservations</h2>
                                    <div className="body">
                                        { client.res.length==0 ? <p>No current Reservations {client.res.length}</p> :
                                        <div className="all">
                                            <h3>Upcoming Reservations </h3>
                                            <div className="reservations">
                                             {client.res.length>0 && client.res.map((res, idx) =>{return (
                                             <div className="reservation" key={idx}>
                                                <h3>{res.parkinglotname} — {res.numofdays} days of parking with {res.carCount} car(s) at ${Constants.formatCurrency(res.dailyrate)} /day/car</h3>
                                                <div className="row">
                                                    <div className="rows">
                                                        <p className="title">Reservation ID</p>
                                                        <p className="answer">{res.transactionid}</p>
                                                    </div>
                                                    <div className="rows">
                                                        <p className="title">Check In</p>
                                                        <p className="answer">{Constants.formatDateTime( res.arrivaldate) }</p>
                                                    </div>
                                                    <div className="rows">
                                                        <p className="title">Check Out</p>
                                                        <p className="answer">{Constants.formatDateTime(res.returndate)  }</p>
                                                    </div>
                                                    <div className="rows">
                                                        <p className="title">Paid Online</p>
                                                        <p className="answer">${Constants.formatCurrency (res.totalpaid)}</p>
                                                    </div>
                                                    <div className="rows">
                                                        <p className="title">Due at Lot</p>
                                                        <p className="answer">${Constants.formatCurrency(res.payatlot)}</p>
                                                    </div>
                                                    <div className="rows">
                                                        <p className="title">Total</p>
                                                        <p className="answer">${Constants.formatCurrency(res.totalamount)}</p>
                                                    </div>
                                                    <div className="btns">
                                                      {res.reservationstatus=='Open' && <Link to={`/admin/modify/${res.reservationid}`} target='_blank' >
                                                            <button>Modify</button>
                                                        </Link>}  
                                                        <Link to={`/admin/full-receipt/${res.reservationid}`} target='_blank'>
                                                            <button>Print Receipt</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                                </div>)})}   

                                            </div>   

                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {
                            formToShow === 'resetPassword' &&
                            <div className={expandForm ? "merge-clientbox expanded": "merge-clientbox"}>
                            <div className="head">
                                <h3>Password Reset</h3>
                                <FontAwesomeIcon icon={expandForm ?faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} className='close-create' onClick={() => setExpandForm(!expandForm)} />
                            </div>
                            <div className="info">
                                <div className="each-info">
                                    <p className='title'>Name</p>
                                    <p className="answer">{cust.firstname + ' ' + cust.lastname}</p>
                                </div>
                                <div className="each-info">
                                    <p className='title'>Email</p>
                                    <p className="answer">{cust.email}</p>
                                </div>
                            </div>
                            <p className="note">This action will reset the password on system and will require the user to reset password on next login</p>
                            <div className="btn">
                                <button className='reset-btn' onClick={onResetCustomer}>Reset Password</button>
                                <p onClick={closeMerge} className='goBack'>Back to Clients</p>
                            </div>
                        </div>
                        }
                        {
                            formToShow === 'changeEmail' &&
                            <div className={expandForm ? "merge-clientbox expanded": "merge-clientbox"}>
                            <div className="head">
                                <h3>Change E-mail</h3>
                                <FontAwesomeIcon icon={expandForm ?faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} className='close-create' onClick={() => setExpandForm(!expandForm)} />
                            </div>
                            <div className="newEmail">
                                <label htmlFor="newEmail">New E-mail</label>
                                <input type="email" placeholder='Enter New Email' id='newEmail' value={newEmail} name='newEmail' onChange={newEmailChange}/>
                            </div>
                            <div className="info">
                                <div className="each-info">
                                    <p className='title'>Name</p>
                                    <p className="answer">{cust.firstname + ' ' + cust.lastname}</p>
                                </div>
                                <div className="each-info">
                                    <p className='title'>Current E-mail</p>
                                    <p className="answer">{cust.email}</p>
                                </div>
                                <div className="each-info">
                                    <p className='title'>New E-mail</p>
                                    <p className="answer">{newEmail}</p>
                                </div>
                            </div>
                            <p className="note">This action will change the old email reservation to this new email.</p>
                            <div className="btn">
                                <button className='reset-btn' onClick={() =>onCustomerChangeEmail() }>Change Email</button>
                                <p onClick={closeMerge} className='goBack'>Back to Clients</p>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ManageClient
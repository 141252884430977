import React from 'react'
import LoadingImg from '../assets/loading.gif';
import '../styles/loading.css'

function Loading({message}) {
  return (
    <div className='load-container'>
        <h2>{message}</h2>
        <h2 className='small-head'>Please wait...</h2>
        <img src={LoadingImg} width='200px' alt="Loading" />
    </div>
  )
}

export default Loading
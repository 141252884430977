import React, { useState, useContext } from 'react';
import '../../styles/User/header.css'
import Logo from '../../assets/logo.png';
import { ShareContext } from '../../context/ShareContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faBars, faLocationDot, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Header() {
    const [airportMenuClicked, setAirportMenuClicked] = useState(false);
    const [openMenu, setOpenMenu] = useState(false)

    const { isUserLoggedIn } = useContext(ShareContext)

    const path = useLocation();

        // Check if the current route contains "/admin"
    const isAdminRoute = path.pathname.includes('/admin');

  return isAdminRoute ? null : (
    <header className='no-print'>
        <Link to='/' style={{}}>
            <img src={Logo} alt='New Orleans Parking Logo' /> 
        </Link>
        <h2 style={{color:'white'}}>New Orleans Parking</h2>
        <nav className={openMenu ? 'user-nav open' : 'user-nav'}>
            <ul>
                <li onClick={() =>  setOpenMenu(false)}>
                    <Link to='/' className={ path.pathname === '/' ? 'nav-link show' : 'nav-link'}>
                        Home
                        <div className='line'></div>
                    </Link>
                </li>
                <li onClick={() =>  setOpenMenu(false)}>
                    <Link to='/reservation/type/hotel' className={ path.pathname === '/reservation/type/hotel' ? 'nav-link show' : 'nav-link'}>
                        Hotel Parking
                        <div className='line'></div>
                    </Link>
                </li>
                <li onClick={() =>  setOpenMenu(false)}>
                    <Link to='/reservation/type/cruise' className={ path.pathname === '/reservation/type/cruise' ? 'nav-link show' : 'nav-link'}>
                        Cruise Parking
                        <div className='line'></div>
                    </Link>
                </li>
                <li onClick={() =>  setOpenMenu(false)}>
                    <Link to='/reservation/type/downtown' className={ path.pathname === '/reservation/type/downtown' ? 'nav-link show' : 'nav-link'}>
                        Downtown Parking
                        <div className='line'></div>
                    </Link>
                </li>
                
                <li onClick={() =>  setOpenMenu(false)}>
                    <Link to='/reservation' className={ path.pathname === '/reservation' ? 'nav-link show' : 'nav-link'}>
                        Reservations
                        <div className='line'></div>
                    </Link>
                </li>
                <li onClick={() =>  setOpenMenu(false)}>
                    <Link to='/reservation/directions' className={ path.pathname === '/reservation/directions' ? 'nav-link show' : 'nav-link'}>
                        Directions
                        <div className='line'></div>
                    </Link>
                </li>
              {! isUserLoggedIn && <li onClick={() =>  setOpenMenu(false)}>
                    <Link to='/reservation/login' className={ (path.pathname === '/reservation/login') || (path.pathname === '/home/register') ? 'nav-link show' : 'nav-link'}>
                        <button>My Accounts</button>
                    </Link>
                </li>}  
            </ul>
        </nav>
        <FontAwesomeIcon icon={openMenu ? faXmark : faBars} className='hamburger' onClick={() => { setOpenMenu(!openMenu)}} />
    </header>
  )
}

export default Header
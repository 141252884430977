import React, { useState, useContext } from 'react'
import '../../styles/Admin/head.css'
import Logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { ShareContext } from '../../context/ShareContext';
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp, faCircleDot, faGear, faGlobe, faRefresh, faTags } from '@fortawesome/free-solid-svg-icons'
import * as Constants from '../../constants';

function PerfWidget(props) {
    const arrowdirect = (v1, v2,type) => {
        if (v1 > v2)
            if(type=='arrow')
                return faAngleUp;
            else
                return 'success';
        else
            if (type == 'arrow')
                return faAngleDown;
            else 
                return 'success';
    };
    const getperc =  (v1, v2) => {
        if (v2 == 0)
            return 0;
        else
            return  ((v1 / v2) * 100).toFixed(0);
    };
   /*  console.log('prop values *************************');
    console.log(props) */
  return (
    <div className="item">
    <div className="up-side">
        <p>{props.mode!='day'?`${props.month} ${props.year} `:''} {props.title} </p>
        <div className="amount-percent">
            <h2>{Constants.formatCurrency(props.amount)}</h2>
            <div className="percent-icon">
                <FontAwesomeIcon icon={faGlobe} className='float-icon'/>
                <div className="percent">
                    <h3>  {getperc(props.amount,props.prevAmount) }%</h3>
                    <FontAwesomeIcon icon={arrowdirect(props.amount,props.prevAmount,'arrow')} />
                </div>
            </div>
        </div>
    </div>
    <div>
        <div className="progress-bar" style={{width:`${getperc(props.amount,props.prevAmount) }%`}}></div>
    </div>
    
    {props.mode!='day' && <div className="down-place">
        <div className="versus">
            <p className='vs'>vs</p>
            <p className="month"> {props.prevMonth} {props.year}: {Constants.formatCurrency(props.prevAmount)}</p>
        </div>
       <div className="month-icon">
            <p>{props.mode=='day'?`${props.prevDay} `:''} {props.month} {props.prevYear}: ${Constants.formatCurrency(props.preYearAmount)}</p>
            <FontAwesomeIcon icon={arrowdirect(props.prevAmount, props.amount,'arrow')} />
        </div> 
    </div>}

    {props.mode=='day' && <div className="down-place">
        <div className="versus">
            <p className='vs'>vs</p>
            <p className="month">{props.mode=='day'?`${props.prevDay} `:''} {props.month } {props.year}: {Constants.formatCurrency(props.prevAmount)}</p>
        </div>
        
    </div>}
    
</div>
  )
}

export default PerfWidget